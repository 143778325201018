
<template>
  <div class="main" :style="mainbg" >
    <MySphere v-if="data" :datas="ready_datas" :logo="logo" />
    <div class="bless-box">
      <div class="item" v-for="(v,i) in blessings" :key="v.id" :class="{in:v.in}" >
        <img :src="v.avatar" alt="" class="icon header"/>
        <div class="box">
          <div class="name">{{v.name}}</div>
          <div class="ctx">{{v.content}}</div>
        </div>
      </div>
    </div>
    <div class="actions-wrap" v-if="data">

      <div v-if="count">
        <div>签到统计：</div>
        <div v-for="(v,i) in count" :key="i">
          <div>{{v.name}}({{v.ready_count}}/{{v.count}})</div>
        </div>
      </div>

      <div class="qr-wrap" v-if="data.state==2">
        <el-image
              class="qr"
                  :src="data.qr_code"
                  fit="contain"></el-image>
        <div class="tip">扫一扫参与签到墙</div>
      </div>


      <div class="btn" v-if="data.state==2" @click="clickPause()" >
        暂停
      </div>
      <div class="btn" v-else @click="clickSatrt()">
        开始
      </div>

    </div>
  </div>

</template>




<script>
import MySphere from "@/views/running/components/MySphere.vue";
import api from "@/utils/api";
import {base64_encode} from "@/utils/helper";
// const Mock = require('mockjs')
export default {
  name: "RunningCheckinWall",
  components:{MySphere},
  props:["aid","fid"],
  data(){
    return {
      datas:[],
      count:undefined,
      data:undefined,
      i:0,
      blessings:[],
    }
  },
  computed:{
    mainbg(){
      if (this.data){
        return `background-image: url(${this.data.picture})`
      }
      return "";
    },
    ready_datas(){
      return this.datas.filter(v=>{return v.ready_status==1})
    },
    logo(){
      if(this.data !== undefined){
        const logo =  this.data.logo
        console.error("logo",logo)
        return logo
      }
      const logo =  `${this.VUE_APP_IMG_URL}/logo.png`
      console.error("logo",logo)
      return logo
    },
  },watch:{
    $route(to,from){
      this.init()
      // console.error(6699,from.params.fid)
      this.unsbscribe(from.params.fid)
    }
  },
  destroyed() {
    this.unsbscribe(this.fid)
  },
  mounted() {
    this.init()
  },methods:{

    unsbscribe(fid){
      const topic1 = `checkin/${fid}`
      const callbackId1 = base64_encode(topic1)
      const topic2 = `bless/${fid}`
      const callbackId2 = base64_encode(topic2)
      api.logined_mqtt.unsubscribe(callbackId1)
      api.logined_mqtt.unsubscribe(callbackId2)
    },
    init(){
      this.loadData().catch(err=>{this.notice_warning(err)})
      api.logined_mqtt.subscribe(`checkin/${this.fid}`,(data)=>{
        // console.log(111,msg)
        this.datas = data.persons
        this.count = data.count
      })
      api.logined_mqtt.subscribe(`bless/${this.fid}`,(data)=>{
        this.blessings.push(data)
        data.in = true
        setTimeout(()=>{
          data.in = false
          setTimeout(()=>{
            this.blessings.shift()
          },1100)
        },2000)
      })
    },
    async loadData(){
      console.error(99999999999999)
      this.data = await api.get_checkinwall(this.fid)
      const data = await api.get_checkin_statistics(this.fid)
      this.datas = data.persons
      this.count = data.count
    },
    clickSatrt(){
      api.do_running_checkin_start(this.fid).then(data=>{this.data = data})
          .catch(err=>{this.notice_warning(err)})
    },
    clickPause(){
      api.do_running_checkin_stop(this.fid).then(data=>{this.data = data})
          .catch(err=>{this.notice_warning(err)})
    },
    // clickAdd(){
    //   const imgs = ["https://p26.toutiaoimg.com/origin/tos-cn-i-qvj2lq49k0/22f10850c5234b5285350743cfa16357",
    //     "https://ts1.cn.mm.bing.net/th/id/R-C.3a1b98d8aa749503cc2ff9c224bc8b40?rik=xxNkH0iChSUYqg&riu=http%3a%2f%2fd.ifengimg.com%2fq100%2fimg1.ugc.ifeng.com%2fnewugc%2f20190119%2f10%2fwemedia%2fabbab6554fa54232bec645b46e6e7bb3f0e4cc5b_size2326_w3000_h2000.JPG&ehk=UzIcp%2fHqCMHntTpDKBDEvAT%2bhhu8xR805ZL0enQCZ%2fY%3d&risl=1&pid=ImgRaw&r=0",
    //     "https://ts1.cn.mm.bing.net/th/id/R-C.28f54e47f3629e1a47f7f48654c4368e?rik=QIpyLG%2bJX4VOhg&riu=http%3a%2f%2fn.sinaimg.cn%2fsinacn20107%2f685%2fw594h891%2f20190427%2f2453-hvvuiyp2168424.jpg&ehk=A2UZrGdhZqAFOTTUXHf%2btVAC8R%2buGlL9XnNf2LhK%2fs8%3d&risl=&pid=ImgRaw&r=0",
    //     "https://ts1.cn.mm.bing.net/th/id/R-C.1e1e0a27b344a84a7216be00845cd850?rik=2enlz40g6iwiNw&riu=http%3a%2f%2fn1.itc.cn%2fimg8%2fwb%2frecom%2f2016%2f08%2f15%2f147127287815611948.JPEG&ehk=qqfSA5z3%2fGdDjAzjHVjeD%2b0EEsp153UNdrP8POcla4Y%3d&risl=&pid=ImgRaw&r=0",
    //     "https://ts1.cn.mm.bing.net/th/id/R-C.ccd320596cb9b0499c2d9e89079c7990?rik=bo30tkANeNk4Aw&riu=http%3a%2f%2fwww.finebornchina.cn%2fuploads%2fallimg%2f140430%2f1-140430150445413.jpg&ehk=Hjpp13uPkWtPTUVLZH%2f7V3MKAnXYJJNjmjRq1TE136k%3d&risl=&pid=ImgRaw&r=0",
    //     "https://tse2-mm.cn.bing.net/th/id/OIP-C.DxNyJiv-oGgvds7B1-2D5gHaDz?rs=1&pid=ImgDetMain",
    //     "https://tse2-mm.cn.bing.net/th/id/OIP-C.vLnd-BENRHhK6y1FiC-gIwHaEH?rs=1&pid=ImgDetMain",
    //     "https://tse1-mm.cn.bing.net/th/id/OIP-C.dzeaBPa7s2qY3mZT9OC8FwHaEo?rs=1&pid=ImgDetMain",
    //     "https://ts1.cn.mm.bing.net/th/id/R-C.05f1bddb981b628bd21d9d45bf2146c0?rik=kDBwkP1rYzDGkg&riu=http%3a%2f%2fimage.yjcf360.com%2fu%2fcms%2fwww%2f201912%2f2010555026tk.jpg&ehk=NzLtVb6yEME3y%2bgVkHk7e7bcA8xkO6qS1lWJpuThrWo%3d&risl=&pid=ImgRaw&r=0",
    //     "https://ts1.cn.mm.bing.net/th/id/R-C.8f5c04645014b6b9c29222c5d3ff5885?rik=MFLQh2Jcf%2fD0aw&riu=http%3a%2f%2fimg.netbian.com%2ffile%2f20150311%2f018c085855710a01c71d88a48a3270fe.jpg&ehk=jQL3AVgxW3JQPnKwLCayO8W%2bs5dDcoQ55uO9kpiZlIQ%3d&risl=&pid=ImgRaw&r=0",
    //     "https://ts1.cn.mm.bing.net/th/id/R-C.e532b2b58fa95ce6ad2fc04ed6773414?rik=khNY9vuw2sDVrg&riu=http%3a%2f%2fpic.ntimg.cn%2ffile%2f20160213%2f10743985_150436851412_2.jpg&ehk=lnHBhfl%2bJj5dJPT0CykpH0Mgr%2fyDhqS2vOjT2z1ltaI%3d&risl=&pid=ImgRaw&r=0",
    //     ]
    //   let data = this.datas[0];
    //   data.ready_status = 1
    //   data.ready_time = Math.floor(Date.now() / 1000);
    //   data.avatar = imgs[this.i]
    //   this.i += 1
    //   console.log(this.datas)
    //   this.datas = this.datas.sort((a,b)=>{
    //     return a.ready_time - b.ready_time
    //   })
    //   console.log(this.datas)
    // }
  }
}
</script>



<style scoped lang="scss">
.main {
  position: relative;
  background-repeat: no-repeat;
  //background-size: 100% 100%;
  background-size: cover;
  background-position: center;
  background-color: black;
  color: white;
}


.bless-box {
  position:fixed;
  left: 10px;top: 20px;bottom: 80px;
  display: flex;flex-direction: column;align-items: flex-start;
  &>.item {
    display: flex;align-items: center;
    position: relative;

    transition: 1s all ;
    left: -350px;
    &.in{ left: 0}
    background-color: #409EFF;
    border-radius: 40px;
    padding: 10px;
    &+.item {margin-top: 20px}
    &>.icon.header {
      width: 70px;height: 70px;
      border-radius: 50%;
    }
    &>.box {
      margin:0 10px;
      text-align: left;
      font-size: 16px;
      &>.ctx {
        max-width: 300px;
      }
    }
  }
}
.actions-wrap {
  position:fixed;
  right: 10px;top: 0;bottom: 0;
  display: flex;flex-direction: column;justify-content: center;align-items: center;
  .qr-wrap {
    background-color: white;
    border-radius: 10px;
    padding: 10px 0 ;
    .qr {
      width: 250px;height: 250px;
    }
    .tip {
      color: black;font-size: 16px;
    }
    &+.btn { margin-top: 20px}
  }
  &>.btn {
    background-color: white;
    color: black;
    padding:  5px 10px;border-radius: 5px;
    font-size: 14px;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>