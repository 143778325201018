

<template>
  <el-form :model="form" ref="form" label-position="left" label-width="80px" class="form" >


    <el-form-item label="发票抬头" >
      <el-select v-model="form.hid" placeholder="请选择" style="width:100%">
        <el-option
            v-for="item in datas"
            :key="item.id"
            :label="item.name"
            :value="item.id"
            >
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="消费明细" >

      <el-table
          border
          :data="consumptions"
          style="width: 100%">

        <el-table-column
            prop="name"
            label="消费出处">
        </el-table-column>
        <el-table-column
            label="消费金额"
            width="140">
          <template v-slot="scope">
            ￥{{scope.row.amount | balanceShow}}
          </template>
        </el-table-column>
      </el-table>

    </el-form-item>


    <el-form-item label="金额合计" >
      <span>￥{{total|balanceShow}}</span>
    </el-form-item>



    <el-form-item style="text-align: center">
      <el-button type="primary" @click="submitForm()">立即开票</el-button>
      <el-button @click="resetForm()">重置</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import api from "@/utils/api";

export default {
  name: "InvoiceAddForm",
  props:["consumptions"],
  data(){
    return {
      datas:[],
      form:{hid:''},
    }
  },
  mounted() {
    this.loadData()
  },
  computed:{
    total(){
      let t = 0;
      for (const data of this.consumptions) {
        t += data.amount
      }
      return t
    }
  },
  methods:{
    loadData(){
      api.get_invoice_header_list(1,1000).then(data=>{
        this.datas = data.items
      }).catch(err=>{this.notice_warning(err)})
    },
    submitForm() {
      let consumption_ids = this.consumptions.map(item=>{return item.id})
      consumption_ids = consumption_ids.join(",")
      // console.error(consumption_ids)
      api.do_invoice_add(this.form.hid,consumption_ids)
          .then(()=>{
            this.$emit('onSuccess')
            // this.resetForm(); 没必要了，
          })
          .catch(err=>{this.notice_warning(err)})
    },
    resetForm() {
      this.$refs['form'].resetFields();
      this.form.gid = ''
      this.form.desc = ''
      this.form.answer = ''
    },
  }
}
</script>

<style lang="scss" scoped>

</style>

