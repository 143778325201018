

<template>

  <div class="ly-main">

<!--    <div class="ly-section">-->
<!--      <div class="header">选择签到样式</div>-->
<!--      <div class="styles-wrap">-->
<!--        <div class="item" v-for="(v,i) in styles" :key="i" :class="{active: v.id === form.style_id}" @click="form.style_id=v.id" >-->
<!--          <div class="top">-->
<!--            <el-image fit="fill" class="image"  src="https://youimg1.c-ctrip.com/target/01051120001w81bh1A49D.jpg"></el-image>-->
<!--            <div class="check">-->
<!--              <i class="el-icon-check"></i>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="text">{{v.name}}</div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <div class="ly-section">
      <div class="header">背景设置</div>
      <div style="display: flex">
        <OnePicture v-model="form.picture" :aid="aid" style="width: 360px;" title="电脑端" />
        <OnePicture v-model="form.mobile_picture" :aid="aid" :tip="false" title="手机端" style="margin-left: 40px" fit="cover" width="92px" height="200px" warning="请使用深色背景" />
      </div>
    </div>

    <div class="ly-section">
      <div class="header">签到设置</div>
      <el-form  ref="form" label-position="top" label-width="100px" class="form" >


        <el-form-item label="参与者" >
          <el-checkbox-group v-model="form.checkin_groups">
            <el-checkbox v-for="(v,i) in person_groups" :key="i"  :label="v.id" :disabled="v.join_type.value !== 1">{{v.name}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>


        <el-form-item label="统计方式">
          <el-radio-group v-model="form.count_style_id">
            <el-radio :label="v.id" v-for="(v,i) in count_types" :key="i" >{{v.name}}</el-radio>
          </el-radio-group>
        </el-form-item>




        <el-form-item style="text-align: left">
          <el-button type="primary" @click="save">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>

</template>
<script>
import api from "@/utils/api";
import OnePicture from "@/views/subviews/OnePicture.vue";
export default {
  name: "CheckinwallPage",
  components: {OnePicture},
  props:["aid","fid"],
  data(){
    return{
      styles:[],
      count_types:[],
      person_groups:[],
      gids:[],
      form:{style_id:"",count_style_id:"",checkin_groups:[],picture:"",mobile_picture:""},
    }
  },
  watch:{
    test(value){
      console.error(11111,value)
    }
  },
  mounted() {
    this.loadData()
  },

  methods:{
    save(){

      // console.error(999,this.form)
      let checkin_groups = this.form.checkin_groups.join(',')
      const picture = this.form.picture
      const mobile_picture = this.form.mobile_picture
      api.do_checkinwall_update(this.fid,this.form.style_id,this.form.count_style_id,checkin_groups,picture,mobile_picture).then(()=>{
        this.notice_success("更新成功")
      }).catch(err=>{this.notice_warning(err)})

    },
    loadData(){
      this.loadStyleData()
      this.loadPersonGroupData()
      this.loadCountData()
      this.loadMainData()
    },
    loadStyleData(){
      api.get_dict__checkin_style().then(data=>{
          this.styles = data
      }).catch(err=>{this.notice_warning(err)})
    },
    loadPersonGroupData(){
      api.get_persongroup_list(this.aid).then(data=>{
        this.person_groups = data
      }).catch(err=>{this.notice_warning(err)})
    },
    loadCountData(){
      api.get_dict__checkin_count_style().then(data=>{
        this.count_types = data
      }).catch(err=>{this.notice_warning(err)})
    },
    loadMainData(){
      api.get_checkinwall(this.fid).then(data=>{
        this.form.style_id = data.style.id
        this.form.count_style_id = data.count_style.id
        this.form.checkin_groups = data.checkin_groups
        this.form.picture = data.picture
        this.form.mobile_picture = data.mobile_picture
      }).catch(err=>{this.notice_warning(err)})
    }
  }
}
</script>

<style lang="scss">
.styles-wrap>.item>.top img {
  border-radius: 4px;
}
</style>
<style lang="scss" scoped>
.styles-wrap {
  display: flex;flex-wrap: wrap;
  margin-top: 10px;
  &>.item {
    margin: 10px 0 0 10px;
    border-radius: 5px;

    &.active {
      .check {
        display: flex !important;
        align-items: center;justify-content: center;
      }
    }
    &>.top {
      width: 180px;height: 100px;
      &>.image {
        width: 180px;height: 100px;
      }
      position: relative;
      &>.check {

        position: absolute;
        right: 0;bottom: 0;
        width: 20px;height: 20px;
        background-color: #409EFF;
        display: none;
        color: white;font-size: 13px;
        border-radius: 5px 0 5px 0 ;
      }
    }
    &>.text {
      margin-top: 5px;
      font-size: 13px;
      text-align: center;
    }
  }
}




$avatarW: 100px;
$avatarH: 100px;

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: $avatarW;
  height: $avatarH;
  line-height: $avatarH;
  text-align: center;
}
.avatar {
  width: $avatarW;
  height: $avatarH;
  display: block;
}

.form {
  margin-top: 30px;
  display: inline-block;
  .tip {
    font-size: 12px;
    line-height: 18px;
    color: #888;
  }

  .item-line {
    display: flex;
    &+.item-line {
      margin-top: 10px;
    }
    .label {
      width: 60px;
      font-size: 12px;
      color: #666;
    }
    &>*+*{
      margin-left: 10px;
    }
    .el-input {
      width: 300px;
    }
  }
}

</style>