

<template>
  <div class="ly-main">
    <PersonManage class="ly-section" :aid="aid" />
    <PrizeManage class="ly-section" :aid="aid"  />
<!--    <QuestionManage class="ly-section" :aid="aid" />-->

  </div>

</template>
<script>

import PersonManage from "@/views/subviews/PersonManage.vue";
import PrizeManage from "@/views/subviews/PrizeManage.vue";
// import QuestionManage from "@/views/subviews/QuestionManage.vue";
export default {
  name: "DatasourcePage",
  components:{PrizeManage, PersonManage},
  // components:{PrizeManage, PersonManage,QuestionManage},
  props:["aid"],
  data(){
    return {

    }
  },
}
</script>
<style scoped>


</style>