

<template>

  <div class="ly-main">
    <div class="ly-section">
      <div >
        <h3>开票处理</h3>
        <div>
          <el-form ref="form" :inline="true" :model="form" label-width="80px">

            <el-form-item label="开票状态">
              <el-select v-model="form.state" placeholder="请选择" style="width: 100px">
                <el-option label="全部" :value="undefined"></el-option>
                <el-option label="未开票" :value="0"></el-option>
                <el-option label="已开票" :value="1"></el-option>
                <el-option label="驳回" :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="消费时间">
              <el-date-picker
                  style="width: 130px"
                  v-model="form.date"
                  type="month"
                  placeholder="选择月">
              </el-date-picker>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="loadData()">查询</el-button>
            </el-form-item>


          </el-form>
        </div>
        <div>


          <el-table
              border
              :data="pageData.items"
              style="width: 100%">

            <el-table-column
                label="抬头"
                width="230">
              <template v-slot="scope">
                <div>{{scope.row.header.name}}</div>
                <div>{{scope.row.header.suihao}}</div>
              </template>
            </el-table-column>
            <el-table-column
                label="金额"
                width="140">
              <template v-slot="scope">
                ￥{{scope.row.total_amount | balanceShow}}
              </template>
            </el-table-column>
            <el-table-column
                label="明细"
            >
              <template v-slot="scope">
                <div v-for="(v,i) in scope.row.consumptions" :key="i" class="detail-line">
                  <span>{{v.name}}</span>
                  <span>￥{{v.amount | balanceShow}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
                prop="create_time_str"
                label="申请时间"
                width="180">
            </el-table-column>

            <el-table-column
                label="发票状态"
                width="100"
            >
              <template v-slot="scope">
                <span>{{scope.row.state_str}}</span>
                <el-popover
                    placement="top-start"
                    width="300"
                    trigger="hover"
                    v-if="scope.row.state===3">
                  <span>{{scope.row.reason}}</span>
                  <span slot="reference" class="reason"> (原因)</span>
                </el-popover>
              </template>
            </el-table-column>

            <el-table-column
                label="操作"
                width="300"
            >
              <template v-slot="scope">
<!--                <el-button type="primary" size="mini" plain >开票</el-button>-->
                <div class="actions-wrap">
                  <el-upload
                      class="upload-demo"
                      :multiple="false"
                      :data="{id:scope.row.id}"
                      :action="`${VUE_APP_BASE_URL}/invoice/done`"
                      :headers="tokenHeader"
                      :show-file-list="false"
                      :fileList="[]"
                      :on-success="uploadSueecee"
                      :on-error="uploadError">
                    <el-button size="small" type="primary">开票</el-button>
                  </el-upload>
                  <el-button type="danger" size="mini" plain v-if="scope.row.state===1">驳回</el-button>
                  <el-button type="success" size="mini" plain v-if="scope.row.state===2" @click="clickView(scope.row.url)" >查看</el-button>
                  <el-button type="info" size="mini" plain v-if="scope.row.state===3">原因</el-button>
                </div>
              </template>
            </el-table-column>




          </el-table>


          <el-pagination
              class="new-line"
              background
              layout="sizes, prev, pager, next"
              :page-sizes="[5,8,10,13,15,18,20]"
              :page-size="pageData.per_page"
              :total="pageData.total"
              :current-page="pageData.page"
              @current-change="clickPageChange"
              @size-change="clickPageSizeChange">
          </el-pagination>



        </div>
      </div>

    </div>

    <el-dialog title="发票预览" :visible.sync="dialogVisible" left width="600px" :close-on-click-modal="false">
      123
    </el-dialog>

  </div>

</template>

<script>
import api from "@/utils/api";

export default {
  name: "ConsumptionDeal",
  data() {
    return {
      dialogVisible:false,
      pageData:{items:[],page:1,per_page:5,total:0},
      form:{state:undefined,date:undefined,cid:undefined},
      multipleSelection:[],
      pdf:undefined
    };
  },
  mounted() {
      this.form.date = new Date();
  },
  computed:{
    tokenHeader(){
      return api.tokenHeader
    }
  },
  methods: {

    loadData(){
      const date =  Math.floor(this.form.date.getTime()/1000)
      api.get_invoice_manager_list(this.pageData.page,this.pageData.per_page,date,this.form.cid,this.form.state).then(data=>{
        this.pageData = data
      }).catch(err=>{this.notice_warning(err)})
    },
    clickPageSizeChange(per_page){
      this.pageData.per_page = per_page
      this.loadData()
    },
    clickPageChange(page) {
      this.pageData.page = page
      this.loadData()
    },
    uploadSueecee(){
      this.notice_success("开票成功")
      this.loadData()
    },
    uploadError(err){
      this.notice_error(JSON.parse(err.message).msg);
    },
    clickView(url) {
      this.dialogVisible = true

    }



  }
}
</script>


<style lang="scss" scoped>

.line-wrap {
  display: flex;justify-content: space-between;align-items: center;
}

.actions-wrap {
  display: flex;
  &>* {
    &+*{ margin-left: 10px}
  }
}




</style>