import _, {runInContext as str} from 'lodash';

function split(value,sep=',') {
    if (typeof value != 'string')
        return [value]
    return value.split(sep)
}

function last(str,len=2) {
    const l = Math.min(len,str.length)
    return str.substr(str.length-l,str.length)
}

function imageStyle(url) {
    const style = {"background-image":`url(${url})`}
    return style
}

function dateFormat(timestamp,format='yyyy/MM/dd hh:mm:ss'){
    const date = new Date(timestamp*1000)
    const formatMap = {
        yyyy:date.getFullYear(),
        MM:date.getMonth()+1,
        dd:date.getDate(),
        hh:date.getHours(),
        mm:date.getMinutes(),
        ss:date.getSeconds()}
    let result = format
    for (const key in formatMap){
        result = result.replaceAll(key,formatMap[key])
    }
    return result
}

function dateSimple(timestamp){
    const now = new Date()
    const date = new Date(timestamp*1000)
    if(now.getFullYear() != date.getFullYear()){
        return dateFormat(timestamp,'yyyy/MM/dd hh:mm:ss')
    }
    if(now.getMonth() != date.getMonth()){
        return dateFormat(timestamp,'MM/dd hh:mm:ss')
    }
    if(now.getDate() != date.getDate()){
        return dateFormat(timestamp,'dd hh:mm:ss')
    }
    // if(now.getHours() != date.getHours()){
    //     return dateFormat(timestamp,'hh:mm:ss')
    // }
    return dateFormat(timestamp,'hh:mm:ss')
}

function toPrecent(value,fixed=0){
    const res = (value*100).toFixed(fixed)
    return `${res}%`
}

function timeFormat(milliseconds){
    const ms = milliseconds %1000
    const s = Math.floor(milliseconds/1000) % 60
    const m = Math.floor(milliseconds/(60*1000))%60
    const h = Math.floor(milliseconds/(60*60*1000))
    return `${h}:${m}:${s}'${ms}`
}

function isEmpty(s){
    return _.isEmpty(_.trim(s))
}


function randint(min,max){
    return _.random(min,max,false)
}

function balanceShow(balance){
    return (balance/100.0).toFixed(2)
}

function phoneHide(phone){
    if (phone.length != 11) {
        return phone
    }

    const star = phone.slice(0, 3);
    const end = phone.slice(-4)
    return `${star}****${end}`


}




const filters = {last,split,imageStyle,dateFormat,toPrecent,timeFormat,isEmpty,randint,dateSimple,balanceShow,phoneHide}

export function init(vueclass) {
    for (const k in filters){
        vueclass.filter(k,filters[k])
    }
    vueclass.prototype.util = filters
}