
<template>
  <div class="main" >
    dasdaldsa
  </div>
</template>




<script>

import api from "@/utils/api";

export default {
  name: "RunningVideo",
  props:["aid","fid"],
  data(){
    return {
      data:undefined,
    }
  },
  computed:{
    bgStyle(){
      if (this.data == undefined){
        return {}
      }else {
        return {backgroundImage:`url(${this.data.picture})`};
      }
    }
  },
  mounted() {
    this.loadData()
  },
  methods:{
    loadData(){
      api.get_picture(this.fid).then(data=>{
        this.data = data
      }).catch(err=>{this.notice_warning(err)})
    }
  }
}
</script>



<style scoped lang="scss">
.main {
  position: fixed;
  left: 0;top: 0;right: 0;bottom: 0;
  color: white;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
</style>