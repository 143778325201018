

<template>


  <div>
    <div class="projects-wrap" ref="projects-wrap">
      <div class="actions" ref="actions" style="display: flex;justify-content: space-between;align-items: center;padding: 0 20px 10px 20px;">
        <span style="font-weight: bold"  >环节流程</span>

        <div>
          <el-button   icon="el-icon-sort" circle v-if="!draged"  @click="clickSortStart()" ></el-button>
          <el-button   icon="el-icon-finished" circle v-else @click="clickSortEnd()" ></el-button>
          <el-button :type="type"  icon="el-icon-setting" circle @click="$router.push({name:'ProjectBase',params:{aid}})" ></el-button>
        </div>

      </div>
      <Container class="projects" @drag-start="onDragStart" @drop="onDrop" :style="{height:projectHeight}" drag-handle-selector=".column-drag-handle" >
        <template v-if="!draged">
          <AddIndicator v-if="datas.length===0"  :selected="selected==='none'" @onSelect="onSelect('none')" />
          <AddIndicator v-else  :selected="selected===datas[0].id+'_pre'" :id="datas[0].id"  @onSelect="onSelect(datas[0].id+'_pre',datas[0].id,true)" />
        </template>

        <template v-for="(v,i) in datas" >
          <Draggable class="item" :class="{active:selected===v.id}"   :key="v.id">
            <div class="title-wrap">
              <input class="input" type="text"  v-if="edit===v.id"  v-model="input" ref="input" placeholder="请输入内容" @blur="doEdit(v)" @keyup.enter="doEdit(v)" />
              <div v-else  class="title"><span v-if="!draged">{{i+1}}.</span> {{v.name}}</div>
              <div class="actions" v-if="!draged"><i class="el-icon-edit" @click="clickEdit(v)" ></i><i v-if="v.system===0" class="el-icon-delete" @click="clickDelete(v)" ></i></div>
            </div>
            <div  class="content" :class="{disable:v.system>0}"  @click="clickFlow(v)" >
              <span v-if="draged" class="column-drag-handle" style="float:left; padding:0 10px;">&#x2630;</span>
              <el-image class="image" :src="v.sketch.picture" draggable="false"></el-image>
              <i  class="arrow el-icon-caret-right"></i>
            </div>
          </Draggable>
          <AddIndicator v-if="!draged" :selected="selected===v.id+'_post'" :id="v.id"  @onSelect="onSelect(v.id+'_post',v.id)" />

        </template>



      </Container>

    </div>

    <router-view />

    <div class="ly-footer">
      <el-button v-if="activity.state===0" type="primary" @click="clickEnable()">启用</el-button>
      <el-button v-else type="primary" @click="clickEnable(false)">停用</el-button>
      <el-button type="success" @click="clickReset()">重置</el-button>
    </div>

  </div>


</template>
<script>
import api from "@/utils/api";
import AddIndicator from "@/views/activity/Screen/AddIndicator.vue";
import debounce from 'lodash/throttle';
import PubSub from 'pubsub-js';
import { Container, Draggable } from 'vue-smooth-dnd';
// const _ = require('lodash');
export default {
  name: "BasePage",
  props:["aid"],
  components:{AddIndicator,Container,Draggable},
  data(){
    return {
      draged:false,
      edit:"",
      input:"",
      type:"",
      selected:"",
      projectHeight:'100px',
      activity:{state:0},
      datas:[],
      old_datas:[],
      old_selected:"",
      route_dic:{picture:"Picture",video:"Video",checkin_wall:"Checkinwall",lottery:"Lottery",vote:"Vote"},
      tokens:[],
      screen_base:{resolution:{name:""}},

    }
  },

  watch:{
    $route(to){
      this.init()
    }
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    this.loadActivity()
    this.loadScreenBase()
    this.init()
    let token1 = PubSub.subscribe("flows_updated",()=>{
      this.init()
    })

    this.tokens = [token1]
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    for (const token of this.tokens) {
      PubSub.unsubscribe(token)
    }
  },
  methods: {
    clickSortStart(){
        this.old_selected = this.selected
        this.selected = ""
        this.old_datas = [...this.datas]
        this.draged = true
    },
    clickSortEnd(){
      // JSON.stringify(arr1) === JSON.stringify(arr2);
      const old_ids = this.old_datas.map(v=>{return v.id})
      const now_ids = this.datas.map(v=>{return v.id})

      if(JSON.stringify(old_ids) === JSON.stringify(now_ids)){
        this.selected = this.old_selected
        this.draged = false
        return
      }

      this.$confirm('是否保存修改后的排序', '提示', {
        confirmButtonText: '保存',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.do_flow_resort(this.aid,now_ids.join(',')).then(res=>{
          this.datas = res
          this.selected = this.old_selected
          this.draged = false
        }).catch(err=>{this.notice_warning(err)})

      }).catch(() => {
        //还原以前的的顺序
        this.datas = [...this.old_datas]
        this.selected = this.old_selected
        this.draged = false
      });
    },
    onDragStart(){
      // this.draged = true
    },
    onDrop(dropResult) {

      const { removedIndex, addedIndex, payload } = dropResult;
      if (removedIndex === null && addedIndex === null) return;

      const newItems = [...this.datas];
      const [movedItem] = newItems.splice(removedIndex, 1);
      newItems.splice(addedIndex, 0, movedItem);

      this.datas = newItems;

      // this.draged = false
    },
    init(){
      this.loadData()
      // console.error(9999,this.$route.name,this.$route.path)
      let name = this.$route.name;

      this.type = ""
      if (name === 'ProjectBase') {
        this.selected = ""
        this.type = "primary"
      }else if (name === 'FlowAdd'){
        if(this.$route.query.id){
          let sub = JSON.parse(this.$route.query.is_pre) ? "pre" : "post"
          this.selected = `${this.$route.query.id}_${sub}`
        }else{
          this.selected = "none"
        }
      }else {
        this.selected = this.$route.path.split('/').pop()
      }


      console.error(this.selected)

    },
    handleResize() {
      // 在这里根据窗口大小进行操作，例如自动调整内容的大小
      // 你可以设置数据属性来记录窗口的当前状态
      this.projectHeight = (this.$refs['projects-wrap'].clientHeight - this.$refs['actions'].clientHeight - 40) + "px"
      console.log(this.$refs['projects-wrap'].clientHeight , this.$refs['actions'].clientHeight)
    },
    loadData(){
      api.get_flow_list(this.aid).then(data=>{
        this.datas = data
      }).catch(err=>{this.notice_warning(err)})
    },
    loadActivity(){
      api.get_activity(this.aid)
          .then(data=>{
            this.activity = data
          })
          .catch(err=>{this.notice_warning(err)})
    },
    loadScreenBase(){
      api.get_screen_base(this.aid)
          .then(data=>{
            this.screen_base = data
          })
          .catch(err=>{this.notice_warning(err)})
    },
    onSelect(i,id=null,is_pre=false){
      debugger
      // this.selected = i
      this.$router.push({name:'FlowAdd',query:{id,is_pre}})
    },
    clickFlow(f){
      if(f.system>0){
        return
      }
      if(f.id === this.selected){
        return
      }
      let name = this.route_dic[f.sketch.project_key]
      let aid = this.aid
      let fid = f.id
      this.$router.push({name,params:{aid,fid}})
    },
    doEdit:debounce(function (f){
      console.error("999999999999")
      let newValue = this.input.trim()
      if(newValue === f.name.trim()){
        this.edit = ""
      }else {
        api.do_flow_edit(f.id,newValue).then(()=>{
          this.notice_success("修改成功")
          f.name = newValue
          this.edit = ""
        }).catch(err=>{this.notice_warning(err)})
      }
    },50,{leading:true,trailing:false}),
    clickEdit(f){
      this.input = f.name
      this.edit = f.id
      this.$nextTick(()=>{
        // this.$refs.saveTagInput.$refs.input.focus();
        // this.$refs['input'].focus()
        console.error(789,this.$refs.input[0].focus())
      })
    },

    doDelete(f){

      api.do_flow_delete(f.id).then(()=>{
        this.notice_success("删除成功")
        if(this.selected.startsWith(f.id)){
          let aid = this.aid
          this.$router.push({name:'Screen',params:{aid}})
        }else {
          this.init()
        }
      }).catch(err=>{this.notice_warning(err)})

    },
    clickDelete(f){
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.doDelete(f)
      }).catch(() => {
        this.notice_info("已取消删除")
      });
    },
    clickEnable(enable=true){
      if(enable){
        api.do_activity_enable(this.aid).then(()=>{
          this.activity.state = 1
          this.notice_success("启动成功")
        }).catch(err=>{this.notice_warning(err)})
      }else {
        api.do_activity_disable(this.aid).then(()=>{
          this.activity.state = 0
          this.notice_success("停用成功")
        }).catch(err=>{this.notice_warning(err)})
      }

    },
    clickReset() {
      api.do_activity_reset(this.aid).then(()=>{
        this.notice_success("重置成功")
      }).catch(err=>{this.notice_warning(err)})
    }

  }

}
</script>
<style scoped>

.ly-footer {
  display: flex;align-items: center;justify-content: center;
}
.projects {
  /*
  &::-webkit-scrollbar {
    display: none;
  }
   */

  box-sizing: border-box;
  padding: 20px 20px;
  overflow-y: scroll;

  &>.item {
    text-align: left;
    //width: 180px;

    &>.title-wrap{
      padding-right: 20px;
      display: flex;justify-content: space-between;align-items: center;
      &>.title {
        font-size: 13px;
        padding: 5px 0;
      }
      &>.input {
        width: 135px;
        height: 25px;
        margin-bottom: 5px;
        border: 1px solid #ccc;
        border-radius: 3px;
        font-size: 11px;
        padding: 0 5px;box-sizing: border-box;
        &:focus {
          border: 1px solid #409EFF;
        }
      }
      &>.actions {
        max-width: 36px !important;
        &>i:hover {
          cursor: pointer;
          &.el-icon-delete {
            color: #cc2126;
          }
          &.el-icon-edit {
            color: #409EFF;
          }
        }

      }
    }
    &>.add {
      display: none;
      background-color: #409EFF;
      &:hover {
        cursor: pointer;
      }
     i {
       font-weight: bold;
       color: #409EFF;
     }
    }
    &>.content {
      display: flex;align-items: center;
      &:hover {
        cursor: pointer;
      }
      &.disable:hover {
        cursor: not-allowed;
      }
      &>.arrow {
        margin-left: 5px;color: #409EFF;
        display: none;
      }
    }
    .image {
      width: 180px;height: 100px;border-radius: 4px;
      img {
        border-radius: 4px;
      }
    }
    &.active {
      &>.add {
        display: block;
      }
      .image {
        padding: 5px;
        border:2px solid #409EFF;
      }
      &>.content {
        &>.arrow {
          display: block;
        }
      }

    }
  }
}

</style>

<style lang="scss">
.projects {
  &>.item .image>img {
    border-radius: 4px;
  }
}
.el-divider--horizontal {
  margin: 15px 0 !important;
}
</style>