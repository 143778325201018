

<template>
  <div class="ly-main">
    <div class="ly-section">
      <div class="header">
        <span>流程添加</span>
      </div>

      <div class="sketch-wrap">
        <div class="sketch" v-for="(v,i) in datas" :key="i" >
          <div class="img-wrap" @mouseenter="cover=i" @mouseleave="cover=-1" >
            <el-image
                class="sketch-img"
                :src="v.picture"
                fit="contain"></el-image>
            <div class="cover" v-if="cover === i" >
              <div class="btn" @click="clickAdd(v)" >添加</div>
            </div>
          </div>
          <div class="title">{{v.name}}</div>
        </div>

      </div>

    </div>
  </div>

</template>
<script>
import api from "@/utils/api";

export default {
  name: "FlowAdd",
  props:['aid'],
  data(){
    return{
      cover:-1,
      id:null,
      is_pre:false,
      datas:[],
      route_dic:{picture:"Picture",checkin_wall:"Checkinwall",lottery:"Lottery",vote:"Vote"}

    }
  },
  mounted() {
    this.id = this.$route.query.id;
    this.is_pre = JSON.parse(this.$route.query.is_pre)
    this.loadData()
  },
  methods:{
    loadData(){
      api.get_project_sketch_list().then(data=>{
        this.datas = data
      }).catch(err=>{this.notice_warning(err)})
    },
    clickAdd(data){
      api.do_flow_add(this.aid,data.id,this.id,this.is_pre).then(data=>{
        this.notice_success("添加成功")
        let name = this.route_dic[data.sketch.project_key]
        let aid = this.aid
        let fid = data.id
        this.$router.push({name,params:{aid,fid}})

      }).catch(err=>{this.notice_warning(err)})
    }

  }
}
</script>
<style lang="scss" scoped>

.sketch-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-top: 20px;
  margin-bottom: 40px;
  .sketch {
    display: flex;flex-direction: column;
    align-items: center;justify-content: center;
    margin-left: 20px;margin-top: 20px;

    .img-wrap {
      display: flex;
      position: relative;
      .sketch-img {
        width: 162px;
        height: 90px;
      }
      .cover {
        position: absolute;
        left: 0;top: 0;bottom: 0;right: 0;
        display: flex;align-items: center;justify-content: center;
        background-color: rgba(0,0,0,0.3);
        .btn {
          height: 30px;line-height: 30px;border-radius: 15px;
          padding: 0 20px;
          background-color: #208ffc;color: white;
          font-size: 14px;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .title {
      margin-top: 5px;font-size: 13px;
    }
  }
}

</style>