

<template>

  <div class="ly-section">

    <div >
      <h3>开票记录</h3>
      <h4>若你遇到什么开票问题，请添加工作人员
        <el-popover
            placement="top-start"
            width="300"
            trigger="hover">
          <el-image
              style="width: 300px; height: 300px"
              :src="fkImg"
              fit="fill"></el-image>
          <span slot="reference">微信</span>
        </el-popover>

        告知情况。</h4>
      <div>

        <el-table
            border
            :data="pageData.items"
            style="width: 100%">

          <el-table-column
              label="抬头"
              width="230">
            <template v-slot="scope">
              <div>{{scope.row.header.name}}</div>
              <div>{{scope.row.header.suihao}}</div>
            </template>
          </el-table-column>
          <el-table-column
              label="金额"
              width="140">
            <template v-slot="scope">
              ￥{{scope.row.total_amount | balanceShow}}
            </template>
          </el-table-column>
          <el-table-column
              label="明细"
              >
            <template v-slot="scope">
              <div v-for="(v,i) in scope.row.consumptions" :key="i" class="detail-line">
                <span>{{v.name}}</span>
                <span>￥{{v.amount | balanceShow}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              prop="create_time_str"
              label="申请时间"
              width="180">
          </el-table-column>

          <el-table-column
              label="发票状态"
              width="100"
          >
            <template v-slot="scope">
              <span>{{scope.row.state_str}}</span>
              <el-popover
                  placement="top-start"
                  width="300"
                  trigger="hover"
                  v-if="scope.row.state===3">
                <span>{{scope.row.reason}}</span>
                <span slot="reference" class="reason"> (原因)</span>
              </el-popover>
            </template>
          </el-table-column>

          <el-table-column
              label="操作"
              width="100"
          >
            <template v-slot="scope">
              <el-button type="danger" size="mini" plain v-if="scope.row.state===1"  @click="clickDelete(scope.row.id)" >删除</el-button>
              <el-button type="success" size="mini" plain v-if="scope.row.state===2" @click="clickDownload(scope.row.url)" >下载</el-button>

            </template>
          </el-table-column>




        </el-table>

        <el-pagination
            class="new-line"
            background
            layout="sizes, prev, pager, next"
            :page-sizes="[5,8,10,13,15,18,20]"
            :page-size="pageData.per_page"
            :total="pageData.total"
            :current-page="pageData.page"
            @current-change="clickPageChange"
            @size-change="clickPageSizeChange">
        </el-pagination>



      </div>
    </div>

  </div>
</template>

<script>
import api from "@/utils/api";

export default {
  name: "ConsumptionManager",
  data() {
    return {
      addDialogVisible:false,
      pageData:{items:[],page:1,per_page:5,total:0},
      fkImg:require('@/assets/img/kfwx.jpg'),
    };
  },
  mounted() {
      this.loadData()
  },
  computed: {
  },
  methods: {

    loadData(){
      api.get_invoice_list(this.pageData.page,this.pageData.per_page).then(data=>{
        this.pageData = data
      }).catch(err=>{this.notice_warning(err)})
    },
    clickPageSizeChange(per_page){
      this.pageData.per_page = per_page
      this.loadData()
    },
    clickPageChange(page) {
      this.pageData.page = page
      this.loadData()
    },
    clickDownload(url) {
      window.open(url);
    },
    clickDelete(id){
      api.do_invoice_delete(id).then(()=>{
        this.notice_success("删除成功")
        this.loadData()
      }).catch(err=>{
        this.notice_warning(err)
      })
    }
  }
}
</script>


<style lang="scss" scoped>

.line-wrap {
  display: flex;justify-content: space-between;align-items: center;
}

.detail-line {
  display: flex;justify-content: space-between;
}

.reason {
  color: #409EFF;
  text-decoration: underline;
}

h4 {
  color: rgb(245,108,108);
  span {
    font-size: 1.2rem;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }
}

</style>