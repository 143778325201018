<template>
<div>
  <div class="info-wrap" v-if="vote">

    <div class="title">
      <span v-if="vote_type">{{vote_type.name}}</span>
    </div>
    <div>
      <div class="section-title"><span>投票对象 ({{player_groups.length}})</span> <div class="ready" :class="{active:ready(player_groups)===player_groups.length}" ><span>就绪：</span>  <span class="value">{{ready(player_groups)}}</span> </div></div>
      <div class="groups">
        <Person class="player"  v-for="(v,i) in player_groups" :key="i" :person="v" />
      </div>
    </div>
    <div>
      <div class="section-title" >投票评委</div>
      <div  v-for="(v,i) in judges" :key="i">
        <div class="section-title-2" ><span>{{v.name}} ({{v.persons.length}})</span> <div class="ready" :class="{active:ready(v.persons)===v.persons.length}"><span>就绪：</span><span class="value">{{ready(v.persons)}}</span></div> </div>
        <div class="groups">
          <Person  class="player" v-for="(vv) in v.persons" :key="vv.id" :person="vv" />
        </div>

      </div>
    </div>
    <div class="actions">
      <div class="btn" @click="clickStart()" >开始投票</div>
    </div>
  </div>
</div>
</template>
<script>
import api from "@/utils/api";
import Person from "./components/Person.vue";
import PubSub from "pubsub-js";
export default {
  name: "VoteInfo",
  props:["fid","vote"],
  components:{Person},
  data(){
    return {
      vote_type:undefined,
      player_groups:[],
      judges:[]
    }
  },
  mounted() {
    this.do_loadData()
  },
  watch:{
    vote(){
      this.do_loadData()
    }
  },
  methods:{
    do_loadData(){
      if (this.vote && this.vote_type===undefined){
        this.loadData().catch(err=>{this.notice_warning(err)})
      }
    },
    async loadData() {
      let promises = [
        api.get_dict(this.vote.vote_type),
        api.get_persongroup(this.vote.palyer_gid),
      ]
      for (const judge of this.vote.judges) {

        promises.push(api.get_persongroup(judge.person_gid))
      }
      let ret = await Promise.all(promises)



      this.vote_type = ret[0]
      this.player_groups = ret[1].persons
      let judges = []
      for (let i = 2; i < ret.length; i++) {
        judges.push(ret[i])
      }
      this.judges = judges

    },
    ready(persons){
      let cnt = 0
      for (const person of persons) {
        if(person.ready_status ===1) {
          cnt++
        }
      }
      return cnt
    },
    clickStart(){
      api.do_vote_start(this.fid).then(()=>{
        PubSub.publish("vote_update")
      }).catch(err=>{this.notice_warning(err)})
    }

  }
}
</script>

<style scoped lang="scss">

.info-wrap {
  display: block;text-align: left;
  padding: 20px;box-sizing: border-box;
  font-size: 12px;
  .title {
    font-size: 30px;font-weight: bolder;
    display: flex;justify-content: center;
  }
  .section-title {
    margin-top: 40px;
    font-size: 20px;font-weight: bolder;
    display: flex;justify-content: space-between;align-items: flex-end;
  }
  .section-title-2 {
    margin-top: 20px;
    font-size: 14px;font-weight: bolder;
    display: flex;justify-content: space-between;align-items: flex-end;
  }
  .ready {
    font-size: 14px;font-weight: bolder;

    &.active {
      color: green;
      //.value {
      //  color: green;
      //}
    }

  }

  .groups {
    margin-top: 10px;
    display: flex;flex-wrap: wrap;
    .player {
      margin: 10px 0 0 10px;
    }
  }


  .actions {
    margin-top: 40px;
    display: flex;justify-content: center;
    .btn {
      background-color: #409EFF;
      color: white;
      padding: 10px 15px;
      border-radius: 5px;
      font-size: 18px;
      &:hover {cursor: pointer;}
    }
  }
}


</style>