

<template>
<div>
  <div id="container"></div>
<!--  <div id="menu">-->
<!--    <button id="table" @click="clickTable()" >TABLE</button>-->
<!--    <button id="sphere" @click="clickSphere()" >SPHERE</button>-->
<!--    <button id="helix" @click="clickHelix()" >HELIX</button>-->
<!--    <button id="grid" @click="clickGrid()" >GRID</button>-->
<!--    <button id="grid" @click="clickRotate()" >旋转</button>-->
<!--    <button id="grid" @click="clickAdd()" >加人</button>-->
<!--  </div>-->
</div>
</template>


<script>
import * as THREE from 'three';

import TWEEN from 'three/examples/jsm/libs/tween.module.js';
// import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls.js';
import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';
const _ = require('lodash');

import {table} from "@/const/sphere";
export default {
  name: "MySphere",
  props:["datas","logo"],
  data(){
    return {
      table:table,
      camera:undefined,
      scene:undefined,
      renderer:undefined,
      controls:undefined,
      objects:[],
      targets:{ table: [], sphere: [], helix: [], grid: [] },
      target:0,
      group:undefined,
      clock:undefined,

    }
  },
  watch:{
    datas(old){
      this.fill_datas(old.length)
    },
    logo(old,now){
      console.log("1",old,now)
    }
  },
  mounted() {
    this.init();
    this.animate();
    this.fill_datas(0)
  },
  methods:{
    fill_datas(old_len){
      console.log(1111,old_len)
      for(let i=0; i<this.datas.length; i++){
        const object = this.objects[i];
        object.element.src = this.datas[i].avatar
      }
      if(old_len > this.datas.length){
        for(let i=this.datas.length; i<old_len; i++){
          const object = this.objects[i];
          // object.element.src = `${this.VUE_APP_IMG_URL}/logo.png`
          object.element.src = this.logo
        }
      }
    },
    init() {

      this.camera = new THREE.PerspectiveCamera( 40, window.innerWidth / window.innerHeight, 1, 30000000 );
      this.camera.position.set(850,0,3000)

      this.scene = new THREE.Scene();

      // const axesHelper = new THREE.AxesHelper( 500 );
      // this.scene.add( axesHelper );


      //group

      this.group = new THREE.Group()

      this.clock = new THREE.Clock()

      // table

      for ( let i = 0; i < 150; i ++ ) {

        const element = document.createElement( 'img' );
        element.className = 'element';
        // element.style.backgroundColor = 'rgba(0,127,127,' + ( Math.random() * 0.5 + 0.25 ) + ')';
        // element.src = `${this.VUE_APP_IMG_URL}/logo.png`
        element.src = this.logo





        const objectCSS = new CSS3DObject( element );
        objectCSS.position.x = Math.random() * 4000 - 2000;
        objectCSS.position.y = Math.random() * 4000 - 2000;
        objectCSS.position.z = Math.random() * 4000 - 2000;

        this.group.add( objectCSS );
        // this.scene.add( objectCSS );

        this.objects.push( objectCSS );

        //

        const object = new THREE.Object3D();
        // object.position.x = ( table[ i + 3 ] * 140 ) - 1330;
        // object.position.y = - ( table[ i + 4 ] * 180 ) + 990;
        object.position.x = ( (i%15) * 170 ) - 1330;
        object.position.y = - ( Math.floor(i/15) * 170 ) + 990;

        // console.error(i%15,Math.floor(i/15))

        // object.position.x = 0;
        // object.position.y = 0;
        this.targets.table.push( object );

      }

      this.scene.add(this.group)

      // sphere

      const vector = new THREE.Vector3();

      for ( let i = 0, l = this.objects.length; i < l; i ++ ) {

        const phi = Math.acos( - 1 + ( 2 * i ) / l );
        const theta = Math.sqrt( l * Math.PI ) * phi;

        const object = new THREE.Object3D();

        object.position.setFromSphericalCoords( 800, phi, theta );

        vector.copy( object.position ).multiplyScalar( 2 );

        object.lookAt( vector );

        this.targets.sphere.push( object );

      }

      // helix

      for ( let i = 0, l = this.objects.length; i < l; i ++ ) {

        const theta = i * 0.175 + Math.PI;
        const y = - ( i * 8 ) + 450;

        const object = new THREE.Object3D();

        object.position.setFromCylindricalCoords( 900, theta, y );

        vector.x = object.position.x * 2;
        vector.y = object.position.y;
        vector.z = object.position.z * 2;

        object.lookAt( vector );

        this.targets.helix.push( object );

      }

      // grid

      for ( let i = 0; i < this.objects.length; i ++ ) {

        const object = new THREE.Object3D();

        object.position.x = ( ( i % 5 ) * 400 ) - 800;
        object.position.y = ( - ( Math.floor( i / 5 ) % 5 ) * 400 ) + 800;
        object.position.z = ( Math.floor( i / 25 ) ) * 1000 - 2000;

        this.targets.grid.push( object );

      }

      //

      this.renderer = new CSS3DRenderer();
      this.renderer.setSize( window.innerWidth, window.innerHeight );
      document.getElementById( 'container' ).appendChild( this.renderer.domElement );

      //

      // this.controls = new TrackballControls( this.camera, this.renderer.domElement );
      // this.controls.minDistance = 500;
      // this.controls.maxDistance = 6000;
      // this.controls.addEventListener( 'change', this.render );



      // this.transform( this.targets.table, 2000 );




      this.clickSphere()
      // console.log(123,this.objects)
      this.objects = _.shuffle(this.objects)
      // console.log(456,this.objects)

      window.addEventListener( 'resize', this.onWindowResize );

    },
    clickTable(){

      this.transform( this.targets.table, 2000 );
    },
    clickSphere(){
      this.transform( this.targets.sphere, 2000 );
    },
    clickHelix(){
      this.transform( this.targets.helix, 2000 );
    },
    clickGrid(){
      this.transform( this.targets.grid, 2000 );
    },
    clickRotate(){
      alert("旋转")
    },
    // clickAdd(){
    //   const object = this.objects[ 0 ];
    //   object.element.src = 'http://localhost/fecd23e9.png'
    // },
    transform( targets, duration ) {

      TWEEN.removeAll();

      for ( let i = 0; i < this.objects.length; i ++ ) {

        const object = this.objects[ i ];
        const target = targets[ i ];

        new TWEEN.Tween( object.position )
            .to( { x: target.position.x, y: target.position.y, z: target.position.z }, Math.random() * duration + duration )
            .easing( TWEEN.Easing.Exponential.InOut )
            .start();

        new TWEEN.Tween( object.rotation )
            .to( { x: target.rotation.x, y: target.rotation.y, z: target.rotation.z }, Math.random() * duration + duration )
            .easing( TWEEN.Easing.Exponential.InOut )
            .start();

      }

      new TWEEN.Tween( this )
          .to( {}, duration * 2 )
          .onUpdate( this.render )
          .start();

    },
    onWindowResize() {

      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize( window.innerWidth, window.innerHeight );

      this.render();

    },
    animate() {

      requestAnimationFrame( this.animate );

      TWEEN.update();

      // this.controls.update();

      // sphere.rotation.x += 0.01;
      // sphere.rotation.y += 0.01;

      const t = this.clock.getElapsedTime()
      this.group.rotation.x = t*0.2
      this.group.rotation.y = t*0.35
      // console.log(Math.floor(t)%30==0)
      if (Math.floor(t)%10==0){
        this.changeTarget()
      }
      this.render()

    },
    render() {
      this.renderer.render( this.scene, this.camera );
    },
    changeTarget(){
      this.target +=1
      this.target = this.target % 4
      const target = [this.targets.table,this.targets.sphere,this.targets.helix,this.targets.grid][this.target];
      this.transform( target, 2000 );
    }


  //   this.transform( this.targets.table, 2000 );
  // },
  // clickSphere(){
  //   this.transform( this.targets.sphere, 2000 );
  // },
  // clickHelix(){
  //   this.transform( this.targets.helix, 2000 );
  // },
  // clickGrid(){
  //   this.transform( this.targets.grid, 2000 );



  },
}
</script>


<style >
/*@import '@/assets/styles/main.css';*/
@import '../../../assets/styles/main.css';

a {
  color: #8ff;
}

#menu {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
}

.element {
  width: 160px;
  height: 160px;
  border-radius: 80px;
  box-shadow: 0px 0px 12px rgba(0,255,255,0.5);
  border: 1px solid rgba(127,255,255,0.25);
  font-family: Helvetica, sans-serif;
  text-align: center;
  line-height: normal;
  cursor: default;
}

.element:hover {
  box-shadow: 0px 0px 12px rgba(0,255,255,0.75);
  border: 1px solid rgba(127,255,255,0.75);
}


.element .symbol {
  position: absolute;
  top: 40px;
  left: 0px;
  right: 0px;
  font-size: 60px;
  font-weight: bold;
  color: rgba(255,255,255,0.75);
  text-shadow: 0 0 10px rgba(0,255,255,0.95);
}

.element .details {
  position: absolute;
  bottom: 15px;
  left: 0px;
  right: 0px;
  font-size: 12px;
  color: rgba(127,255,255,0.75);
}

/*
button {
  color: rgba(127,255,255,0.75);
  background: transparent;
  outline: 1px solid rgba(127,255,255,0.75);
  border: 0px;
  padding: 5px 10px;
  cursor: pointer;
}

button:hover {
  background-color: rgba(0,255,255,0.5);
}

button:active {
  color: #000000;
  background-color: rgba(0,255,255,0.75);
}
*/

</style>