

<template>
  <div class="vote-content-wrap" v-if="statistics">
    <div class="tip" >共{{total}}分</div>
    <div class="top-title" v-if="done">
      <span v-if="!run.turn_last">本轮已完成</span>
      <template v-else>
        <span>决赛已完成</span>
        <div v-if="!showTotal" class="btn" @click="clickLastRanks()" >查看最终结果</div>
        <div v-else class="close-btn-wrap">
          <i class="el-icon-circle-close close-btn" @click="showTotal=false"></i>
        </div>
      </template>


    </div>

   <div class="vote-content">
     <div class="players-wrap" v-if="run">
       <div class="player" v-for="(v,i) in run.players" :key="v.id">
         <Bar class="bar"  :person="v" :value="value_for(v.id)" :score="score_for(v.id)" :idx="i+1"/>
       </div>
     </div>
     <div class="actions">
       <div>
         <i class="el-icon-video-play" v-if="run.state===1"  @click="clickStart()" />
         <i class="el-icon-video-pause" v-else  @click="clickPause()" />
         <i class="el-icon-s-data" @click="showRank = !showRank"/>

         <i class="icon qrcode" v-if="run.state===2" @click="showQrcode = !showQrcode"/>
       </div>

     </div>
   </div>

    <div class="right">


      <div class="qr-wrap" v-if="run.state===2" :class="{show:showQrcode}" >
        <el-image
            class="qr"
            :src="run.qr_code"
            fit="contain"></el-image>
        <div class="tip">扫一扫参与投票</div>
      </div>


      <div class="rank-box" :class="{show:showRank}" >
        <div class="item" v-for="(v,i) in ranks" :key="i">
          <div class="rank">
            <img src="" alt="" class="icon" v-if="i<3" />
            <span v-else>{{i+1}}</span>
          </div>
          <img :src="v.avatar" alt="" class="icon avatar"/>
          <div class="long">
            <div class="name">{{v.name}}</div>
            <div class="progress-box">
              <div class="value" :style="value_style_for(v.id)" ></div>
            </div>
          </div>
          <div class="score-box">
            <div>
              <span >{{v.p}}</span>
              <span class="unit">票</span>
            </div>
            <div>
              <span >{{v.score}}</span>
              <span class="unit">分</span>
            </div>
          </div>
        </div>
      </div>


    </div>

    <div class="total-result-wrap" v-if="total_ranks" >
      <div class="total-result-content" :class="{show:showTotal}" >

        <div v-for="(v,i) in total_ranks" :key="i" class="person-entry-wrap">
          <img v-if="i<3" src="" alt="" class="icon"/>
          <div v-else class="rank">{{i+1}}</div>
          <div class="person-entry">
            <img v-if="v.avatar" class="avatar" :src="v.avatar" alt="">
            <div v-else class="avatar-name">{{v.name|last}}</div>
          </div>
          <div class="total">
            <div class="name">{{v.name}}</div>
            <div class="score-total">
              <span class="score">{{total_calc(v.results).score}}分</span>/<span class="p">{{total_calc(v.results).p}}票</span>
            </div>
          </div>
          <div class="turns">
            <div class="turn" v-for="(vv,ii) in v.results" :key="ii">
              <div v-if="vv">
                <div>
                  <span class="score">{{vv.score}}分</span>/<span class="p">{{vv.p}}票</span>
                </div>
                <div>第{{ii+1}}轮</div>
              </div>
              <div v-else>--</div>
            </div>

          </div>

        </div>
      </div>
    </div>

  </div>

</template>

<script>
import api from "@/utils/api";
import Bar from "./components/Bar.vue";
import {base64_encode} from "@/utils/helper";
export default {
  name: "BasicVote",
  components:{Bar},
  props:["run","fid"],
  data(){
    return {
      statistics:undefined,
      p_total:0,
      done:false,
      showRank:false,
      showQrcode:false,
      showTotal:false,
      total_ranks:undefined,
    }
  },
  computed:{
    total(){
      let score = 0
      Object.keys(this.statistics).forEach(key => {
       score += this.statistics[key]['score']
      });
      return score
    },
    total_p(){
      let p = 0
      Object.keys(this.statistics).forEach(key => {
        p += this.statistics[key]['p']
      });
      return p
    },
    ranks(){
      if (!this.run || !this.statistics){
        return []
      }

      let l = []
      Object.keys(this.statistics).forEach(key => {
        const id = key
        const p = this.statistics[key]['p']
        const score = this.statistics[key]['score']
        const person_obj = this.person_for_id(id)
        const person = {id,p,score,avatar:person_obj.avatar,name:person_obj.name}
        l.push(person)
      });
      l.sort((a,b) => {
        return b.score - a.score
      })
      return l
    },
    total_turns(){
      if(this.total_ranks === undefined){
        return 0
      }
      return this.total_ranks.length -1
    }

  }, watch:{
    $route(to,from){
      this.init()
      this.unsbscribe(from.params.run.id)
    }
  },
  destroyed() {
    this.unsbscribe(this.run.id)
  },
  mounted() {
    this.init()
  },methods:{
    total_calc(results){
      let ret = {p:0,score:0}
      for(const result of results){
        if(result != null){
          ret.p += result.p
          ret.score += result.score
        }
      }
      return ret
    },
    person_for_id(id){
      for (const player of this.run.players) {
        if(player.id === id){
          return player
        }
      }
      return undefined
    },
    unsbscribe(fid){
      const topic = `vote/${fid}`
      const callbackId = base64_encode(topic)
      api.logined_mqtt.unsubscribe(callbackId)
    },
    updateStatistics(data){

      if (this.statistics === undefined){
        this.statistics = data.detail
      }else{
        Object.keys(data.detail).forEach(key => {
          if (this.statistics[key]){
            this.statistics[key]["p"] = data.detail[key]["p"]
            this.statistics[key]["score"] = data.detail[key]["score"]
          }
        });
      }
      this.p_total = data.total
      this.done = (this.p_total>0&&this.total_p===this.p_total)


      // console.error(999888,this.statistics);
    },
    init(){
      this.loadData()
      api.logined_mqtt.subscribe(`vote/${this.run.id}`,(data)=>{
        this.updateStatistics(data)
        // this.count = data.count
      })
    },
    loadData(){
      api.get_running_vote_run_statistics(this.run.id).then(data => {
        this.updateStatistics(data)
      }).catch(error => {this.notice_warning(error)});
    },
    score_for(id){
      if(this.statistics === undefined){
        return 0
      }
      const data = this.statistics[id]
      if(data){
        return data.score
      }
      return 0
    },
    value_for(id){

      if(this.total >0){
        const score = this.score_for(id)
        return score/this.total
      }
      return 0
    },
    value_style_for(id){
      if(this.total >0){
        const score = this.score_for(id)
        const r = (score/this.total)*100
        return  `width:${r}%`
      }
      return 'width:0'
    },
    clickStart(){
      api.do_vote_run_start(this.run.id).then((data) => {
        console.error(data,data.qr_code)
        this.run.qr_code = data.qr_code
        this.run.state = 2
        this.showQrcode = true
      }).catch(err=>{this.notice_warning(err)})
    },
    clickPause(){
      api.do_vote_run_pause(this.run.id).then(() => {
        this.run.state = 1
      }).catch(err=>{this.notice_warning(err)})
    },
    clickLastRanks(){
      console.error(9999999,this.total_ranks)
      if (this.total_ranks === undefined){
        api.get_running_total_ranks(this.run.vid).then(data => {
          console.error(data)
          this.total_ranks = data
          setTimeout(()=>{
            this.showTotal = true
          },100)
        }).catch(err=>{this.notice_warning(err)})
      }else {
        console.error(123)
        this.showTotal = true
      }

    }
  }
}
</script>


<style scoped lang="scss">

.qr-wrap {
  position: relative;
  background-color: white;
  border-radius: 10px;
  padding: 10px 0 ;
  transition: .5s all;
  transform: translateX(400px);
  &.show {
    transform: translateX(0);
  }
  .qr {
    width: 250px;height: 250px;
  }
  .tip {
    color: black;font-size: 16px;
  }
}


.vote-content-wrap {
  position: relative;
  height: 100%;
  display: flex;align-items: center;justify-content: center;
  &>.tip {
    position: absolute;
    right: 20px;top: 40px;
    font-size: 20px;
  }
  .top-title {
    position: absolute;
    top: 42px;
    font-size: 25px;
    color:  rgb(252, 123, 0);
    &>.btn {
      background-color: #409EFF;
      color: white;
      margin-top: 20px;
      font-size: 18px;
      padding: 10px;border-radius: 5px;
    }
    &>.close-btn-wrap {
      margin-top: 20px;
      color: white;
      &>.close-btn {
        font-size: 40px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .vote-content {
      width: 80%;
  }
}


.players-wrap {
  display: flex;justify-content: space-around;



}

.actions {
  margin-top: 120px;
  font-size: 40px;
  display: flex;justify-content: center;
  &>div {
    display: flex;align-items: center;
  }
  i {
    &+i{ margin-left: 30px}
    &:hover {
      cursor: pointer;
      color: #409EFF;
    }

    &.icon.qrcode {
      display: inline-block;
      width: 40px;height: 40px;
      background-image: url("~@/assets/img/ico_qr_code.png");
    }
  }

}

.right {
  position: fixed;
  top: 0;right: 10px;bottom: 80px;
  display: flex;flex-direction: column;justify-content: center;align-items: center;


  .rank-box {
    width: 300px;
    background-color: #409EFF;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    transition: .5s all;
    transform: translateX(350px);
    &.show { transform: translateX(0); }

    .item {
      width: 100%;
      display: flex;
      //background-color: red;
      &+.item { margin-top: 15px}
      &>*+*{margin-left: 10px;}
      @for $i from 1 through 3 {
        &:nth-child(#{$i}){
          &>.rank>img.icon {
            content: url("~@/assets/img/r#{$i}.png");
          }
        }
      }

      &>.rank {
        display: flex;align-items: center;justify-content: center;
        font-size: 16px;
        width: 28px;
        &>img.icon {
          height: 30px;
          object-fit: cover;
        }
      }

      img.avatar {
        //content: url("@/assets/images/header.jpg");
        object-fit: cover;
        width: 44px;height: 44px;
        border-radius: 50%;
        border: 2px solid white;
        //box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
      }

      &>.long {
        flex: 1;
        display: flex;flex-direction: column;justify-content: center;align-items: flex-start;
        &>.progress-box {
          margin-top: 5px;
          height: 8px;
          width: 100%;
          //background-color: #3a76d5;
          position: relative;
          &>.value {
            position: absolute;
            //z-index: -1;
            left: 0;top: 0;bottom: 0;
            border-radius: 4px;
            //background: linear-gradient(to right, #4999f7 0%, #aae4fc 100%);
            background: linear-gradient(to right, rgb(252, 123, 0) 0%, rgb(174, 60, 18) 100%);

            width: 0;
          }
        }
      }

      .score-box {
        display: flex;flex-direction: column;
        align-items: center;justify-content: center;
      }
      .unit {
        margin-left: 5px;
        color: #ccc;
      }

    }

  }

}


.total-result-wrap {
  position: fixed;top: 0;left: 0;right: 0;
  display: flex;justify-content: center;
  pointer-events: none;




  &>.total-result-content {

    width: 800px;
    background-color: #409EFF;border-radius: 10px;
    padding: 50px 20px;box-sizing: border-box;
    position:relative;
    transition: .5s all;
    top: -600px;
    &.show {
      top: 200px;
    }

    display: flex;flex-direction: column;
    .person-entry-wrap {
      &+.person-entry-wrap { margin-top: 30px}

      display: flex;align-items: center;


      @for $i from 1 through 3 {
        &:nth-child(#{$i}){
          &>img.icon {
            content: url("~@/assets/img/r#{$i}.png");
          }
        }
      }

      .score {
        //font-weight: bold;
        text-shadow: 0 0 3px rgba(0,0,0,0.8);
      }
      .p {
        font-size: 0.8em;
        //color: rgb(200,200,200);
      }


      &>.rank {
        width: 50px;
        font-size: 38px;
        font-weight: bold;
      }
      &>img.icon {
        width: 50px;
        object-fit: cover;
      }
      &>.total {
        margin-left: 10px;
        display: flex;flex-direction: column;align-items: center;
        &>.name {
          font-size: 20px;
        }
        &>.score-total {
          font-size: 18px;
        }
      }

      &>.turns {
        margin-left: 20px;
        flex: 1;
        display: flex;
        border:1px solid white;
        &>.turn {
          flex: 1;
          &+.turn { border-left: 1px solid white }
        }
      }


      .person-entry {
        margin-left: 10px;
        &>.avatar {
          width: 80px;height: 80px;
          border-radius: 50%;
          border: 3px solid white;
        }
        &>.avatar-name {
          width: 80px;height: 80px;
          border-radius: 50%;
          background-color: white;
          color: black;line-height: 80px;
          font-size: 16px;
        }








      }



    }

  }
}









</style>