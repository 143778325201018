

<template>

  <div class="ly-main">

<!--    <div class="ly-section">-->
<!--      <div class="header">选择投票样式</div>-->
<!--      <div class="styles-wrap">-->
<!--        <div class="item" v-for="(v,i) in styles" :key="i" :class="{active: v.id === form.style}" @click="form.style=v.id">-->
<!--          <div class="top">-->
<!--            <el-image fit="fill" class="image"  src="https://youimg1.c-ctrip.com/target/01051120001w81bh1A49D.jpg"></el-image>-->
<!--            <div class="check">-->
<!--              <i class="el-icon-check"></i>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="text">{{v.name}}</div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->


    <div class="ly-section">
      <div class="header">背景设置</div>
      <div style="display: flex">
        <OnePicture v-model="form.picture" :aid="aid" style="width: 360px;" title="电脑端" />
        <OnePicture v-model="form.mobile_picture" :aid="aid" :tip="false" title="手机端" style="margin-left: 40px" fit="cover" width="92px" warning="请使用深色背景" height="200px" />
      </div>
    </div>

    <div class="ly-section">
      <div class="header">基本设置</div>
      <el-form :model="form"  ref="form" label-position="top" label-width="100px" class="form" >

        <div>
          <!--投票类型-->
          <el-form-item label="投票类型">
            <el-radio-group v-model="form.vote_type">
              <el-radio v-for="(v,i) in types"  :label="v.id" :key="i" >{{v.name}}</el-radio>
            </el-radio-group>
          </el-form-item>
<!--          <el-form-item label="获胜名额">-->
<!--            <el-input-number v-model="form.win_num" :step="1"></el-input-number>-->
<!--          </el-form-item>-->


          <!--投票对象-->
          <el-form-item label="投票对象" >
            <el-select v-model="form.palyer_gid" placeholder="请选择" @change="onPlayerGroupChanged()" >
              <el-option
                  v-for="item in person_groups"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
            <span v-if="palyer_num>=0 && !isEmpty(this.form.palyer_gid)" class="palyer_num">{{palyer_num}} 人</span>
          </el-form-item>


          <el-form-item label="比赛轮次">
            <el-input
                v-model="turns"
                :disabled="true">
            </el-input>
            <div v-if="isEmpty(this.form.palyer_gid)" class="tip">选择投票对象后进行计算</div>
            <div v-if="!isEmpty(this.form.palyer_gid) && turns<0" class="tip">数据有误，获胜名额大于投票对象人数</div>
          </el-form-item>


          <el-form-item style="text-align: left">
            <el-button type="primary" @click="save">保存</el-button>
          </el-form-item>

        </div>

      </el-form>
    </div>

    <div class="ly-section">
      <div class="header">数据源</div>
      <el-form :model="form"  ref="form" label-position="top" label-width="100px" class="form" >


        <!-- 选票-->
        <div class="section-header">

          <span>选票</span>
          <el-button @click="ballotAddDialogVisible = true" >新增</el-button>
          <el-dialog title="新增选票" :visible.sync="ballotAddDialogVisible" left width="500px" :close-on-click-modal="false">
            <BallotAddForm v-if="ballotAddDialogVisible" :fid="fid" @onSuccess="onBallotSuceess()" />
          </el-dialog>
        </div>
        <el-form-item >

          <el-table
              border
              ref="filterTable"
              :data="data.ballots"
              style="width: 100%">

            <el-table-column
                prop="name"
                label="名称"
                width="150">
            </el-table-column>
            <el-table-column
                prop="score"
                label="分值"
                width="100">
            </el-table-column>


            <el-table-column
                label="操作">

              <template v-slot="scope">
<!--                <el-button-->
<!--                    size="mini"-->
<!--                    @click="editPerson(scope.$index, scope.row)">编辑</el-button>-->
                <el-button
                    size="mini"
                    @click="clickBallotDelete(scope.row.id)">删除</el-button>
              </template>

            </el-table-column>

          </el-table>
        </el-form-item>


        <!--评委-->
        <div class="section-header">

          <span>投票评委</span>
          <el-button @click="judgeAddDialogVisible = true" >新增</el-button>
          <el-dialog title="新增评委" :visible.sync="judgeAddDialogVisible" left width="500px" :close-on-click-modal="false">
            <JudgeAddForm v-if="judgeAddDialogVisible" :fid="fid"  :ballot_types="ballot_types" :ballot_window_positions="ballot_window_positions" :person_groups="person_groups" :ballots="data.ballots"  @onSuccess="onJudgeSuceess()" />
          </el-dialog>
        </div>
        <el-form-item >

          <el-table
              border
              ref="filterTable"
              :data="data.judges"
              style="width: 100%">

            <el-table-column
                label="人员"
                width="150">
              <template v-slot="scope">
                {{person_group_dic[scope.row.person_gid].name}}
              </template>
            </el-table-column>
            <el-table-column
                label="选票池"
                width="300">

              <template v-slot="scope">
                <el-tag class="ballot-tag" v-for="(v,i) in scope.row.ballot_pool" :key="i" >{{ballot_dic[v] ? ballot_dic[v].name :""}}</el-tag>
              </template>

            </el-table-column>

            <el-table-column
                label="选票类型"
                width="300">

              <template v-slot="scope">

                <span>{{ballot_type_dic[scope.row.ballot_type].name}}</span>
                <span v-if="ballot_type_dic[scope.row.ballot_type].key==='window'"> - {{ballot_position_dic[scope.row.ballot_window_position].name}}</span>
              </template>

            </el-table-column>


            <el-table-column
                label="操作"
                width="300">

              <template v-slot="scope">

<!--                <el-button-->
<!--                    size="mini"-->
<!--                    @click="editPerson(scope.$index, scope.row)">编辑</el-button>-->
                <el-button
                    size="mini"
                    @click="clickJudgeDelete(scope.row.id)">删除</el-button>
              </template>

            </el-table-column>

          </el-table>
        </el-form-item>




      </el-form>
    </div>
  </div>

</template>
<script>


import api from "@/utils/api";
import BallotAddForm from "@/views/subviews/BallotAddForm.vue";
import JudgeAddForm from "@/views/subviews/JudgeAddForm.vue"
import _ from "lodash";
import PubSub from 'pubsub-js';
import OnePicture from "@/views/subviews/OnePicture.vue";
export default {
  name: "VotePage",
  components: {OnePicture, BallotAddForm,JudgeAddForm},
  props:["aid","fid"],
  data(){
    return{
      tableData:[],
      styles:[],
      types:[],
      person_groups:[],
      ballot_types:[],
      ballot_window_positions:[],
      form:{style:"",vote_type:"",win_num:1,palyer_gid:"",picture:"",mobile_picture:""},
      data:{judges:[],ballots:[]},
      palyer_num:-1,
      ballotAddDialogVisible:false,
      judgeAddDialogVisible:false,
    }
  },
  computed:{
    turns(){

      if(this.isEmpty(this.form.palyer_gid)){
        return ""
      }
      if(this.palyer_num < 0){// 给请求数量一个缓存器
        return ""
      }
      let type = this.type_dic[this.form.vote_type]
      if(type.key === "jjs"){
        return 1
      }
      return  this.palyer_num - this.form.win_num
    },
    type_dic(){
      let dic = {}
      for(let p of this.types){
        dic[p.id] = p
      }
      return dic
    },
    ballot_type_dic(){
      let dic = {}
      for(let p of this.ballot_types){
        dic[p.id] = p
      }
      return dic
    },
    ballot_position_dic(){
      let dic = {}
      for(let p of this.ballot_window_positions){
        dic[p.id] = p
      }
      return dic
    },
    person_group_dic(){
     let dic = {}
     for(let p of this.person_groups){
       dic[p.id] = p
     }
     return dic
    },
    ballot_dic(){
      let dic = {}
      for(let b of this.data.ballots){
        dic[b.id] = b
      }
      return dic
    }
  },
  mounted() {
    this.loadData().catch(err=>{this.notice_warning(err)})
  },
  methods:{
    isEmpty(s){
      return _.isEmpty(_.trim(s))
    },
    save() {

      if(this.isEmpty(this.form.palyer_gid)){
        this.notice_error("投票对象不能为空")
        return
      }

      // if(this.turns<=0){
      //   this.notice_error("投票对象人数必须大于获胜名额")
      //   return
      // }

      api.do_vote_update(this.fid,this.form.vote_type,this.form.palyer_gid,this.form.win_num,this.form.style,this.form.picture,this.form.mobile_picture).then(()=>{
        this.notice_success("更新成功")
        PubSub.publish("flows_updated")
      }).catch(err=>{this.notice_warning(err)})

    },
    async loadData() {

      let [styles, types, person_groups, ballot_types, ballot_window_positions] = await Promise.all([
        api.get_dict__vote_style(),
        api.get_dict__vote_type(),
        api.get_persongroup_list(this.aid),
        api.get_dict__ballot_type(),
        api.get_dict__ballot_window_position()
      ])

      this.styles = styles
      this.types = types
      this.person_groups = person_groups
      this.ballot_types = ballot_types
      this.ballot_window_positions = ballot_window_positions

      let data = await api.get_vote(this.fid)
      this.form.style = data.style
      this.form.picture = data.picture
      this.form.mobile_picture = data.mobile_picture
      this.form.vote_type = data.vote_type
      this.form.win_num = data.win_num
      this.palyer_num = data.palyer_num
      this.form.palyer_gid = data.palyer_gid //"6735d9e1cdcd1186e379becaf7964862"
      // this.form.palyer_gid = "6735d9e1cdcd1186e379becaf7964862"

      this.data = data


    },
    // loadStyleData(){
    //   api.get_dict__vote_style().then(data=>{
    //     this.styles = data
    //   }).catch(err=>{this.notice_warning(err)})
    // },
    // loadTypeData(){
    //   api.get_dict__vote_type().then(data=>{
    //     this.types = data
    //   }).catch(err=>{this.notice_warning(err)})
    // },
    // loadPersonGroupData(){
    //   api.get_persongroup_list(this.aid).then(data=>{
    //     this.person_groups = data
    //   }).catch(err=>{this.notice_warning(err)})
    // },
    // loadBallotTypeData(){
    //   api.get_dict__ballot_type().then(data=>{
    //     this.ballot_types = data
    //   }).catch(err=>{this.notice_warning(err)})
    // },
    // loadBallotWindowPositionData(){
    //   api.get_dict__ballot_window_position().then(data=>{
    //     this.ballot_window_positions = data
    //   }).catch(err=>{this.notice_warning(err)})
    // },

    loadMainData(){
      api.get_vote(this.fid).then(data=>{
        this.form.style = data.style
        this.form.vote_type = data.vote_type
        this.form.win_num = data.win_num
        this.form.palyer_gid = data.palyer_gid //"6735d9e1cdcd1186e379becaf7964862"
        this.data = data
      }).catch(err=>{this.notice_warning(err)})
    },
    onBallotSuceess(){
      this.ballotAddDialogVisible = false
      this.loadMainData()
    },
    onJudgeSuceess(){
      this.judgeAddDialogVisible = false
      this.loadMainData()
    },
    onPlayerGroupChanged(){
      this.palyer_num = 0 //给获取的缓冲器
      api.get_persongroup_count(this.form.palyer_gid).then(data=>{
        this.palyer_num = data.count
      })
      // console.error("999",this.form.palyer_gid)
    },
    clickBallotDelete(id){
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.doBallotDelete(id)
      }).catch(() => {
        this.notice_info("已取消删除")
      });
    },
    clickJudgeDelete(id){
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.doJudgeDelete(id)
      }).catch(() => {
        this.notice_info("已取消删除")
      });

    },
    doBallotDelete(id){
      api.do_vote_ballot_delete(id).then(()=>{
        this.notice_success("删除成功")
        this.loadMainData()
      }).catch(err=>{this.notice_warning(err)})
    },
    doJudgeDelete(id){
      api.do_vote_judge_delete(id).then(()=>{
        this.notice_success("删除成功")
        this.loadMainData()
      }).catch(err=>{this.notice_warning(err)})
    }

    
    
  }
}
</script>

<style lang="scss">
.styles-wrap>.item>.top img {
  border-radius: 4px;
}
</style>
<style lang="scss" scoped>
.styles-wrap {
  display: flex;flex-wrap: wrap;
  margin-top: 10px;
  &>.item {
    margin: 10px 0 0 10px;
    border-radius: 5px;

    &.active {
      .check {
        display: flex !important;
        align-items: center;justify-content: center;
      }
    }
    &>.top {
      width: 180px;height: 100px;
      &>.image {
        width: 180px;height: 100px;
      }
      position: relative;
      &>.check {

        position: absolute;
        right: 0;bottom: 0;
        width: 20px;height: 20px;
        background-color: #409EFF;
        display: none;
        color: white;font-size: 13px;
        border-radius: 5px 0 5px 0 ;
      }
    }
    &>.text {
      margin-top: 5px;
      font-size: 13px;
      text-align: center;
    }
  }
}




$avatarW: 100px;
$avatarH: 100px;

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: $avatarW;
  height: $avatarH;
  line-height: $avatarH;
  text-align: center;
}
.avatar {
  width: $avatarW;
  height: $avatarH;
  display: block;
}

.form {
  margin-top: 30px;
  display: inline-block;
  .tip {
    font-size: 12px;
    line-height: 28px;
    color: #da7f82;
  }

  .item-line {
    display: flex;
    &+.item-line {
      margin-top: 10px;
    }
    .label {
      width: 60px;
      font-size: 12px;
      color: #666;
    }
    &>*+*{
      margin-left: 10px;
    }
    .el-input {
      width: 300px;
    }
  }
}

.section-header {
  font-size: 14px;
  color: #606266;
  margin-top: 20px;
  padding: 10px 0;
  display: flex;align-items: center;justify-content: space-between;
}

.ballot-tag {
  &+.ballot-tag {
    margin-left: 10px;
  }
}

.palyer_num {
  margin-left: 10px;
}

</style>