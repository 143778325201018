<template>
  <div>
    <el-form :model="form" ref="form" label-position="top" label-width="100px" class="demo-ruleForm">


      <el-form-item label="旧密码" prop="name">
        <el-input type="password" v-model="form.oldpwd" placeholder="请输入内容" ></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="name">
        <el-input type="password" v-model="form.pwd" placeholder="请输入内容" ></el-input>
      </el-form-item>
      <el-form-item label="重复新密码" prop="name">
        <el-input type="password" v-model="form.pwd1" placeholder="请输入内容" ></el-input>
      </el-form-item>

      <el-form-item  style="text-align: center">
        <el-button type="primary" @click="clickSure()">确认修改</el-button>
      </el-form-item>
    </el-form>
  </div>

</template>


<script>
import api from "@/utils/api";

export default {
  name: "PasswordResetForm",
  data(){
    return {
      form:{oldpwd:'',pwd:'',pwd1:''},
    }
  },
  methods:{
    clickSure(){
      if (this.form.pwd1 != this.form.pwd){
        this.notice_warning("两次新密码不一致")
        return
      }

      api.do_resetpwd(this.form.oldpwd,this.form.pwd).then(() => {
        this.$emit('onSuccess')
      }).catch(err=>{this.notice_warning(err)})
    }
  }
}
</script>

<style scoped lang="scss">

.line-wrap {
  display: flex;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
}
.qr_wrap {
  width: 200px;
  height: 200px;
  background-color: lightgray;
  margin-bottom: 40px;
}
.seconds {
  font-size: 16px;
  color: red;
}

</style>