
<template>
  <div class="main" :style="mainbg">
    <div class="content" >
      <template v-if="data">
        <div class="record" @click="clickRecord()">抽奖记录</div>
        <div class="playground">
          <div class="item-box">
            <div class="item" v-for="v in data.ready_persons" :key="v.id" :class="{active:isActive(v.id),win:isWin(v.id)}" :ref="v.id" :style="getDeltaStyle(v.id)" >
              <img :src="v.avatar" alt="" class="icon header" />
              <div class="name">{{v.name}}</div>
            </div>
          </div>
          <div class="win-position-wrap">
            <div class="item-box" ref="winbox" >
              <div class="item" v-for="(v,i) in wins" :key="i"  ></div>
            </div>
          </div>
        </div>


        <div class="actions">
          <div class="number" v-if="data.has_prize">
            <div class="title">单次抽取数量</div>
            <el-input-number v-model="stock" :step="1" step-strictly></el-input-number>
          </div>
          <div class="btn" @click="clickRun()" :class="{running:running}" >开始</div>

          <div class="prize-box" @click="clickShowPrize()" v-if="prize">
            <div class="change">切换<i class="el-icon-sort"></i></div>
            <div class="prize">
              <img :src="prize.picture" alt="" class="icon picture" >
              <div class="text">
                <div>{{prize.name}}</div>
                <div class="desc">{{prize.desc}}</div>
              </div>
            </div>

          </div>
        </div>
      </template>
    </div>

    <div class="right">

      <div class="prize-wrap" :class="{show:showPrize}">
        <div class="prize-title" v-if="data && data.has_prize" >请选择奖品</div>
        <div class="prize-box" v-if="data"   >
          <div class="prize" v-for="v in data.prizes" :key="v.id"  @click="clickSelectedPrize(v)">
            <img :src="v.picture" alt="" class="icon picture" >
            <div class="text">
              <div>{{v.name}}</div>
              <div class="desc">{{v.desc}}</div>
            </div>
          </div>
        </div>
      </div>


      <div class="record-wrap" :class="{show:showRecord}">
        <div class="record-title"  >抽奖记录</div>
        <div class="record-box"   >
          <div class="record" v-for="v in records" :key="v.id">
            <div class="header-line">
              <div class="prize" v-if="v.prize" >
                <img :src="v.prize.picture" alt="" class="icon picture" >
                <div class="text">
                  <div class="title">{{v.prize.name}}</div>
                  <div class="desc">{{v.prize.desc}}</div>
                </div>
              </div>
              <div class="time">{{v.create_time|dateSimple}}</div>
            </div>

            <div class="person-box">
              <div class="item" v-for="(vv,j) in v.group_persons" :key="j" >
                <img :src="vv.avatar" alt="" class="icon header" />
                <div class="name">{{vv.name}}</div>
              </div>

            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</template>




<script>
import api from "@/utils/api";
export default {
  name: "RunningLottery",
  props:["aid","fid"],
  data(){
    return {
      data:undefined,
      actives:[],
      wins:[],
      winDelta:{},
      prize:undefined,
      showPrize:false,
      showRecord:false,
      stock:1,
      running:false,
      records:[],
      recordRequestNeed:true,
    }
  },
  computed:{
    mainbg(){
      if (this.data){
        return `background-image: url(${this.data.picture})`
      }
      return "";
    },
  },
  watch:{
    $route(to, from){
      this.init()
    }
  },
  mounted() {
    this.init()
  },
  methods:{

    init(){
      this.loadData()
    },
    loadData(){
      api.get_running_lottery(this.fid).then(data=>{
        this.data = data
        this.prize = undefined
        if(data.prizes.length > 0){
          this.prize = data.prizes[0]
        }
      }).catch(err=>{this.notice_warning(err)})
    },
    isActive(id){
      return this.actives.includes(id)
    },
    isWin(id){
      return this.wins.includes(id)
    },
    getDeltaStyle(id){
      const winDelta = this.winDelta[id]
      if(winDelta){
        return `left:${winDelta.deltaLeft}px;top:${winDelta.deltaTop}px;`
        // const style = `transform: scale(3) translateX(${winDelta.deltaLeft}) translateY(${winDelta.deltaTop})`
        // console.log(style)
        // return style
      }
      return ""
    },
    setWinDelta(){
      let dic = {}
      for (let i=0;i<this.wins.length;i++) {
        // const w = this.$refs[id][0].clientWidth;
        // const h = this.$refs[id][0].clientHeight
        // const x = this.$refs[id][0].clientLeft
        // const y = this.$refs[id][0].clientTop
        // l.push({x,y,w,h})
        const id = this.wins[i]
        const original = this.$refs[id][0].getBoundingClientRect();
        // console.error(this.$refs["winbox"].childNodes[i])
        let target = this.$refs["winbox"].children[i].getBoundingClientRect()
        // target = {left:target.left+61,top:target.top+94}
        const deltaLeft = target.left - original.left
        const deltaTop = target.top - original.top
        dic[id] = {deltaLeft,deltaTop}
      }

      this.winDelta = dic
    },
    clickRun(){
      if(this.running){
        return
      }
      this.showRecord = false
      this.showPrize = false


      let stock = 1
      let prize_id = undefined
      if(this.data.has_prize){
        prize_id = this.prize.id
        stock = this.stock
      }


      const interval = setInterval(()=>{
        let actives = []
        for (let i = 0; i < stock; i++) {
          const ri = this.util.randint(0,this.data.ready_persons.length - 1)
          actives.push(this.data.ready_persons[ri].id)
        }
        this.actives.splice(0)
        this.actives.push(...actives)
      },200)

      const t_start = new Date().getTime();
      this.wins.splice(0)
      this.winDelta = {}
      this.running = true
      api.do_running_lottery_run(this.fid,stock,prize_id).then(data=>{
        let actives = []
        for (const lr of data) {
          actives.push(lr.person_id)
        }
        actives = Array.from(new Set(actives))

        const t_end = new Date().getTime();
        const t = t_end - t_start
        let t_left = 7000 - t //最少4秒
        t_left = Math.max(t_left, 0)
        // console.error(t,t_left)
        setTimeout(()=>{
          clearInterval(interval)
          // this.actives.splice(0)
          // this.actives.push(...actives)
          this.actives.splice(0)
          this.wins.splice(0)
          this.wins.push(...actives)

          // this.$nextTick(() => {
          //   this.setWinDelta()
          // });
          setTimeout(()=>{
            this.setWinDelta()
            this.running = false
            this.recordRequestNeed = true
          },400)

        },t_left)

      }).catch(err=>{
        clearInterval(interval)
        this.actives.splice(0)
        this.running = false
        this.notice_warning(err)
      })


    },
    clickShowPrize(){
      this.showPrize = !this.showPrize
      if (this.showPrize) {
        this.showRecord = false
      }
    },
    clickSelectedPrize(prize){
      this.prize = prize
      this.clickShowPrize()
    },
    clickRecord(){
      console.error(this.recordRequestNeed)
      if (this.recordRequestNeed){
        api.get_running_lottery_run_records(this.fid).then(data=>{
          this.records = data
          this.recordRequestNeed = false
          this.showRecord = true
          this.showPrize = false
        }).catch(err=>{this.notice_warning(err)})
      }else {
        console.error("不需要")
        this.showRecord = !this.showRecord
        if (this.showRecord) {
          this.showPrize = false
        }
      }

    }


  }
}
</script>



<style scoped lang="scss">
.main {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  color: white;
  background-repeat: no-repeat;
  //background-size: 100% 100%;
  background-size: cover;
  background-position: center;
  //background-image: url(https://img.cdyihuitong.com/lotterybg.jpg);
  background-color: black;
  display: flex;
  justify-content: center;

  &>.content {
    width: 85%;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 20px 20px 90px 20px;
    display: flex;flex-direction: column;
    position: relative;
    &>.record {
      font-size: 17px;
      background-color: gray;
      padding: 6px 16px;
      border-radius: 7px 0 0 7px;
      position: absolute;
      right: 0;top: 20px;
      z-index: 100;
      &:hover {
        cursor: pointer;
      }
    }
    &>.playground {
      flex: 1;
      display: flex;align-items: center;justify-content: center;
      position: relative;

      &>.win-position-wrap {
        position: absolute;
        left: 0;top: 0;right: 0;bottom: 0;
        display: flex;align-items: center;justify-content: center;
        &>.item-box {
          margin: -30px 0 0 -30px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;

          & > .item {
            margin: 30px 0 0 30px;

            //width: 61px;height: 94px;
            width: 183px;height: 282px;
            //background-color: rgba(255,0,0,0.2);
          }
        }
      }


      &>.item-box {
        margin: -30px 0 0 -30px;
        display: flex;align-items: center;justify-content: center;flex-wrap: wrap;
        &>.item {
          position: relative;
          left: 0;top: 0;
          margin: 30px 0 0 30px;
          font-size: 16px;
          background-color:  rgba(255,255,255,0.4);

          //padding: 10px;
          display: flex;flex-direction: column;align-items: center;justify-content: center;
          width: 61px;height: 94px;
          border-radius: 5px;
          flex-shrink: 0;
          transition: .4s all;
          &.active {
            transform: scale(1.8);
          }
          &.win {
            transform: scale(3);
            z-index: 10;

            //position: absolute;
            background-color: white;
            color: black;
            box-shadow:0 0 10px rgba(0,255,255,0.7);
          }
          &>.header {
            width: 40px;height: 40px;
            border-radius: 50%;
          }
          &>.name {
            margin-top: 10px;
          }
        }
      }
    }
    &>.actions {
      display: flex;justify-content: space-around;align-items: center;
      &>.number {
        font-size: 18px;
        &>.title {
          margin-bottom: 10px;
        }
      }
      &>.btn {
        font-size: 22px;
        background-color: #409EFF;
        padding: 10px 20px;
        border-radius: 7px;
        &:hover {
          cursor: pointer;
          &.running {
            cursor: not-allowed;
            background-color: #86bded;
          }
        }
        
      }

    }
  }
}




.prize {
  display: flex;
  &:hover { cursor: pointer;}
  &>.picture {
    width: 60px;height: 60px;
    border-radius: 5px;
  }
  &>.text {
    //background-color: red;
    margin-left: 10px;
    text-align: left;
    font-size: 16px;
    display: flex;flex-direction: column;justify-content: space-around;
    &>.title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
    }
    &>.desc {
      color: rgba(255,255,255,0.7);
      font-size: 14px;
      line-height: 15px;
      max-width: 70px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
    }
  }
}

.prize-box {


  .change {
    margin-bottom: 5px;
    font-size: 16px;
    i {
      font-size: 1.2em;
      transform: rotateZ(90deg);
    }
  }

}

.right {
  position: fixed;
  top: 0;right: 10px;bottom: 80px;
  display: flex;flex-direction: column;justify-content: center;
  .prize-wrap {
     transition: .5s all;
     transform: translateX(500px);
     &.show {
       transform: translateX(0);
     }

    .prize-title {
      background-color: #409EFF;
      border-radius: 5px;
      margin-bottom: 10px;
      font-size: 17px;
      padding: 10px 0;
      text-shadow: 0 0 10px rgba(0,0,0,0.5);
    }
    .prize-box {
      text-shadow: 0 0 10px rgba(0,0,0,0.5);
      background-color: #409EFF;
      border-radius: 5px;
      padding: 10px 20px;
      .prize {
        &+.prize {
          margin-top: 20px;
        }
      }
    }

  }



  .record-wrap {
    //margin-top: -20vh;
    z-index: 500;
    transition: .5s all;
    transform: translateX(500px);
    &.show {
      transform: translateX(0);
    }

    .record-title {
      background-color: #409EFF;
      border-radius: 5px;
      margin-bottom: 10px;
      font-size: 17px;
      padding: 10px 0;
      text-shadow: 0 0 10px rgba(0,0,0,0.5);
    }
    .record-box {
      max-height: 70vh;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }

      text-shadow: 0 0 10px rgba(0,0,0,0.5);
      background-color: #409EFF;
      //background-color: red;
      border-radius: 5px;
      padding: 10px 20px;

      &>.record {
        &+.record {
          margin-top: 30px;
        }
      }

      .header-line {
        display: flex;justify-content: space-between;align-items: flex-start;

        .time {
          font-size: 15px;
          font-weight: bolder;
        }
      }
      .prize {
        &+.prize {
          margin-top: 20px;
        }

        &>.picture {
          width: 45px;height: 45px;
        }
        &>.text {
          &>.title,&>.desc {
            max-width: none;
            width: 130px;
          }
        }

      }


      .person-box {
        //margin: -10px 0 0 -10px;
        margin: -5px 0 0 -10px;
        display: flex;align-items: center;justify-content: flex-start;flex-wrap: wrap;
        &>.item {
          position: relative;
          left: 0;
          top: 0;
          margin: 10px 0 0 10px;
          font-size: 16px;
          background-color: rgba(255, 255, 255, 0.4);

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 50px;
          height: 66px;
          border-radius: 5px;
          flex-shrink: 0;
          &>.header {
            width: 30px;height: 30px;
            border-radius: 50%;
          }
          &>.name {
            font-size: 13px;
            margin-top: 0;
          }
        }
      }



    }

  }


}

</style>