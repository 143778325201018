<template>
  <el-form :model="form" ref="form" label-position="top" label-width="100px" class="demo-ruleForm">
    <el-form-item label="充值金额" prop="name">
      <el-input-number v-model="form.amount" :controls="false"  :min="1" :max="10000"></el-input-number>
      <el-button type="primary"  @click="clickSure()" style="margin-left: 20px" >确定 </el-button>
    </el-form-item>



    <div class="line-wrap" v-if="data">
      <div v-if="seconds>0" class="seconds">{{seconds}}S</div>
      <div class="qr_wrap">
        <el-image
            style="width: 100%"
            :src="data.qrcode"
            fit="contain"></el-image>
      </div>

    </div>

    <el-form-item v-if="data" style="text-align: center">
      <el-button type="primary" @click="clickCheck()">我已付款</el-button>
    </el-form-item>
  </el-form>
</template>


<script>
import api from "@/utils/api";
import {wait} from "@/utils/helper"
import PubSub from "pubsub-js";
const QRCode = require('qrcode');
export default {
  name: "ChargeForm",
  data(){
    return {
      form:{amount:500},
      url:undefined,
      data:undefined,
      seconds:0,
      pay_res:undefined
    }
  },
  methods:{
    clickCheck(){

      const loading = this.$loading({
        lock: true,
        text: '订单查询中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      this.doCheck().then(()=>{
        this.$emit('onSuccess')
      }).finally(()=>{
        loading.close()

        if (this.pay_res != 1){
          this.notice_warning("未检测到充值信息，请稍后查询")
        }

      })
    },
    async doCheck(){
      this.seconds = 10
      const fi = setInterval(()=>{
         this.seconds --
      },1000)
      while (this.seconds>0){
        try {
          const res = await api.order_query(this.data.id)
          if (res.trade_state === 'SUCCESS'){
            this.pay_res = 1
            this.notice_success("充值成功")
            PubSub.publish("balance_refresh")
            clearInterval(fi);
            return
          }
        }catch (e) {
          this.pay_res = 1
          this.notice_warning(e)
          clearInterval(fi);
          return
        }finally {
          if (this.pay_res!=1){
            const seconds =  Math.min(this.seconds*1000,2500)
            await wait(seconds)
          }

        }
      }
      this.pay_res = 0
      clearInterval(fi);

    },
    clickSure(){
      // setTimeout(()=>{
      //   this.data = {qrcode:"https://img0.baidu.com/it/u=3584759695,3470619884&fm=253&fmt=auto&app=138&f=GIF?w=198&h=198"}
      // },2000)
      const amount = Math.floor(this.form.amount*100);
      api.do_order_create(amount).then(data=>{
        this.data = data
        console.error(222)
        QRCode.toDataURL(data.pay_url,(err, url) => {
          console.log(123)
          if (err) throw err;
          console.log(url)
          this.data.qrcode = url;
        });
      }).catch(err=>{this.notice_warning(err)})
    }
  }
}
</script>

<style scoped lang="scss">

.line-wrap {
  display: flex;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
}
.qr_wrap {
  width: 200px;
  height: 200px;
  background-color: lightgray;
  margin-bottom: 40px;
}
.seconds {
  font-size: 16px;
  color: red;
}

</style>