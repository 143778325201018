<template>
  <div class="ly-main">
    <div class="ly-section">

      <div>

      </div>
      <el-descriptions class="margin-top" title="公司信息" :column="1"  border>
        <el-descriptions-item labelStyle="width: 150px;" >
          <template slot="label"  >
            <i class="el-icon-s-flag"></i>
            公司
          </template>
          <span v-if="data">{{data.company.name}}</span>
        </el-descriptions-item>

        <el-descriptions-item >
          <template slot="label" >
            <i class="el-icon-s-finance"></i>
            余额
          </template>
          <span v-if="data">{{data.company.balance_str}}</span> <el-button @click="dialogChargeVisible=true"  type="primary" plain size="mini" style="margin-left: 10px">充值</el-button>

          <el-dialog title="余额充值" :visible.sync="dialogChargeVisible" center width="330px" :close-on-click-modal="false" >
            <ChargeForm @onSuccess="onChargeSuccess" />
          </el-dialog>
        </el-descriptions-item>

      </el-descriptions>


      <el-descriptions class="margin-top" title="个人信息" :column="1"  border>

        <el-descriptions-item labelStyle="width: 150px;" >
          <template slot="label">
            <i class="el-icon-tickets"></i>
            昵称
          </template>
          <span v-if="data">{{data.nickname}}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-s-custom"></i>
            账号
          </template>
          <span v-if="data">{{data.account}}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-lock"></i>
            密码
          </template>
          <el-button type="primary" plain size="mini" @click="dialogPwdResetVisible=true">修改密码</el-button>
          <el-dialog title="修改密码" :visible.sync="dialogPwdResetVisible" center width="330px" :close-on-click-modal="false" >
            <PasswordResetForm @onSuccess="onPwdResetSuccess" />
          </el-dialog>
        </el-descriptions-item>


      </el-descriptions>


      <el-descriptions class="margin-top" title="账号安全" :column="1"  border>


        <el-descriptions-item labelStyle="width: 250px;">
          <template slot="label" >
            <i class="el-icon-chat-line-round"></i>
            微信
            <div>
              绑定后可用微信登录。
            </div>
          </template>

          <div v-if="data">
            <div v-if="!data.has_wx">
              <span>未绑定</span>
              <el-button v-if="!bind_qr" type="primary" plain size="mini" style="margin-left: 10px;" @click="clickBindQr()" >绑定</el-button>
              <el-image
                  v-else
                  style="width: 180px; height: 180px"
                  :src="bind_qr"
                  fit="fill"></el-image>
            </div>
            <span v-else>已绑定</span>
          </div>



        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile-phone"></i>
            手机
            <div>
              绑定后可用于找回密码。
            </div>
          </template>

          <div v-if="data">
            <div v-if="!data.phone">
              <span>未绑定</span><el-button type="primary" plain size="mini" style="margin-left: 10px;" @click="dialogBindVisible=true" >绑定</el-button>
              <el-dialog title="手机绑定" :visible.sync="dialogBindVisible" center width="330px" :close-on-click-modal="false" >
                <PhoneBindForm  @onSuccess="onPhoneBindSuccess" />
              </el-dialog>
            </div>
            <span v-else>{{data.phone|phoneHide}}</span>
          </div>

        </el-descriptions-item>

      </el-descriptions>






    </div>
  </div>

</template>

<script>
import api from "@/utils/api";
import ChargeForm from "@/views/Mine/subviews/ChargeForm.vue";
import PhoneBindForm from "@/views/Mine/subviews/PhoneBindForm.vue";
import PasswordResetForm from "@/views/Mine/subviews/PasswordResetForm.vue";
import {base64_encode} from "@/utils/helper";
import debounce from 'lodash/throttle';

export default {
  name: "AccountInfo",
  components:{ChargeForm,PhoneBindForm,PasswordResetForm},
  data() {
    return {
      dialogPwdResetVisible:false,
      dialogChargeVisible:false,
      dialogBindVisible:false,
      data:undefined,
      bind_qr:undefined,
    }
  },
  mounted() {
    this.loadData()
  },
  destroyed() {
    const topic = `bind/${api.uid}`
    const callbackId = base64_encode(topic)
    api.logined_mqtt.unsubscribe(callbackId)
  },
  methods:{
    onChargeSuccess(){
      this.dialogChargeVisible=false;
    },
    onPwdResetSuccess(){
      this.dialogPwdResetVisible = false
      this.notice_success("修改成功")
      setTimeout(()=>{
        api.logout()
      },1500)
    },
    onPhoneBindSuccess(){
      this.dialogBindVisible = false
      this.loadData()
    },
    loadData(){
      api.get_info().then(data=>{
        this.data = data
      }).catch(err=>{this.notice_warning(err)})
    },
    clickBindQr:debounce(function (){
      if(this.bind_qr !== undefined){return}
      api.get_bind_qr().then(data=>{
        this.bind_qr = data.qrcode
        api.logined_mqtt.subscribe(`bind/${api.uid}`,()=>{
          console.error("接收到消息.......")
          this.loadData()
        })
      }).catch(err=>{this.notice_warning(err)})

    },50,{leading:true,trailing:false}) ,
  }
}
</script>

<style scoped lang="scss">

.el-descriptions +.el-descriptions {
  margin-top: 30px;
}

</style>