

<template>
  <el-form  ref="form" label-position="left" label-width="50px" class="form" >

    <el-form-item label="图片">
      <template>
        <el-image
            v-if="prize.picture"
            class="avatar"
            :src="prize.picture"
            fit="fit"></el-image>
        <div v-else class="avatar-none">
          <i class="el-icon-picture-outline"></i>
          <div>暂无图片</div>
        </div>
      </template>
    </el-form-item>


    <el-form-item label="分组" >
      <el-tag >{{prize.group.name}}</el-tag>
    </el-form-item>

    <el-form-item label="名称" prop="name">
      <el-input v-model="prize.name" disabled></el-input>
    </el-form-item>


    <el-form-item label="库存" >
      <el-input v-model="prize.stock" disabled></el-input>
    </el-form-item>
    <el-form-item label="描述" >
      <el-input
          disabled
          prop="desc"
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 5}"
          placeholder="请输入内容"
          v-model="prize.desc"
      >
      </el-input>
    </el-form-item>

  </el-form>
</template>

<script>

export default {
  name: "PrizeViewForm",
  props:["prize"],
  data(){
    return {
    }
  },

}
</script>

<style lang="scss" scoped>

.form>.content {

  .divider {
    background-color: lightgrey;
    width: 1px;
    margin: 40px 30px;
  }
}

$avatarWH: 120px;

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: $avatarWH;
  height: $avatarWH;
  line-height: $avatarWH;
  text-align: center;
}
.avatar {
  width: $avatarWH;
  height: $avatarWH;
  display: block;
}

.avatar-none {
  width: $avatarWH;
  height: $avatarWH;
  display: flex;flex-flow: column;
  align-items: center;justify-content: center;
  background-color: #f5f7fa;
  &>i {
    font-size: 30px;
  }
}

</style>
