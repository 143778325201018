export function init(){
    FileReader.prototype.readAsTextInPromise = async function (data){
        const that = this
        return new Promise((resolve,reject)=>{
            that.readAsText(data, 'utf-8'); // 设置读取的数据以及返回的数据类型为utf-8
            that.addEventListener("loadend", function () { //
                // let res = JSON.parse(reader.result); // 返回的数据
                // console.log(res,'返回结果') // { name: "小花" }
                resolve(JSON.parse(that.result))
            });
            setTimeout(()=>{
                reject()
            },10000)//10秒超时
        })
    }
}