import dragDialog from './drag-dialog'

const install = function (Vue) {
    Vue.directive('drag-dialog', dragDialog)
}

// if (window.Vue) {
//     window.dragDialog = dragDialog
//     Vue.use(install); // eslint-disable-line
// }

dragDialog.install = install
export default dragDialog