

<template>
  <div class="ly-main">
    <div class="ly-section">

      <el-form   ref="form" label-position="top" label-width="50px" class="form" >
        <el-form-item label="视频封面">
          <OnePicture v-model="picture" :aid="aid" />
        </el-form-item>


        <el-upload
            class="upload-demo"
            ref="upload"
            action=""
            :limit="1"
            :http-request="customRequest"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :auto-upload="false">
          <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
          <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>
          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
        </el-upload>

        <el-form-item style="text-align: left">
          <el-button type="primary" @click="save">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>

</template>
<script>
import api from "@/utils/api";
import OnePicture from "@/views/subviews/OnePicture.vue";
import {client} from "ali-oss/lib/setConfig";
const OSS = require('ali-oss');
export default {
  name: "VideoPage",
  props:["fid","aid"],
  components:{OnePicture},
  data(){
    return{
      picture:'',
      video:'',
      client:undefined,
      abortCheckpoint:undefined,
    }
  },
  mounted() {
    this.loadData()
  },
  methods:{
    save(){
      api.do_video_update(this.fid,this.picture,this.video).then(()=>{
        this.notice_success("修改成功")
      }).catch(err=>{this.notice_warning(err)})
    },
    loadData(){
      api.get_video(this.fid).then(data=>{
        this.picture = data.picture
      }).catch(err=>{this.notice_warning(err)})
    },
    submitUpload() {

      // this.$refs.upload.submit();
      if(this.client){
        this.$refs.upload.submit();
      }else {
        api.do_video_upload().then(data=>{
          console.error(data)
          this.client = new OSS({
            // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
            region: 'oss-cn-chengdu',
            // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
            accessKeyId: data.AccessKeyId,
            accessKeySecret: data.AccessKeySecret,
            // 从STS服务获取的安全令牌（SecurityToken）。
            stsToken: data.SecurityToken,
            // 填写Bucket名称，例如examplebucket。
            bucket: "weiact",
          });
          this.$refs.upload.submit();
        }).catch(err=>{
          this.notice_warning(err)
        })
      }

    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    customRequest(opt){
      // console.error("开始上传")
      console.error(opt)

      let options = {
        progress: (p, cpt, res) => {
          // 为中断点赋值。
          this.abortCheckpoint = cpt;
          console.log("中断",this.abortCheckpoint);
          // 获取上传进度。
          console.log(p * 100);
          console.log("res",res)
          opt.onProgress(p)
        }
      }
      if (this.abortCheckpoint) {
        options.checkpoint = this.abortCheckpoint;
      }
      this.client.multipartUpload(opt.file.name, opt.file, options)
          .then((r) => console.log(r))
          .catch(err=>{
            console.error("错误",err)
          })
    }

  }
}
</script>
<style lang="scss" scoped>
$avatarW: 360px;
$avatarH: 200px;

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: $avatarW;
  height: $avatarH;
  line-height: $avatarH;
  text-align: center;
}
.avatar {
  width: $avatarW;
  height: $avatarH;
  display: block;
}

.form {
  display: inline-block;
  .tip {
    font-size: 12px;
    line-height: 18px;
    color: #888;
  }
}

</style>