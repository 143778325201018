<template>
  <div>

    <div class="person" :class="{active:person.ready_status===1}"  v-if="person">
      <img class="avatar" v-if="person.avatar" :src="person.avatar" alt="">
      <div class="avatar-box" v-else>{{person.name|last}}</div>
      <div class="name">{{person.name}}</div>
    </div>

  </div>
</template>

<script>
export default {
  name: "Person",
  props:['person'],
}
</script>


<style scoped lang="scss">
$WH:60px;
.person {
  width: $WH;
  display: flex;flex-direction: column;
  &.active {
    .avatar {
      filter: none;
    }
    .avatar-box {
      filter: none;
    }

  }
  .avatar {
    width: $WH;height: $WH;
    border-radius: 50px;
    border: 3px solid #409EFF;
    box-sizing: border-box;
    filter: grayscale(100%);

  }

  .avatar-box {
    width: $WH;height: $WH;
    border-radius: 50px;
    box-sizing: border-box;
    background: linear-gradient(to bottom, #25a8fb, #1238b5);
    display: flex;align-items: center;justify-content: center;
    font-size: 16px;
    filter: grayscale(100%);
  }

  .name {
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
  }

}
</style>