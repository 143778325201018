

<template>
<div class="all-round">
  <i class="ico bg"/>


  <div class="main-wrap" >

    <div class="login-wrap" >
      <i v-if="step>1" class="back el-icon-back" @click="clickBack()" ></i>

      <div class="login-main">
        <div v-if="step===1" class="title">密码找回</div>
        <div v-if="step===2" class="title">验证身份</div>
        <div v-if="step===3" class="title">重置密码</div>
        <div v-if="step===4" class="title">找回登录账号</div>
        <div v-if="step===5" class="title">
          <i class="el-icon-circle-check"></i>
          找回成功</div>

        <el-form ref="form"  label-width="90px">
        <template v-if="step===1">
          <el-form-item label="登录账号" style="margin-bottom: 10px">
            <el-input v-model="form1.account" placeholder="请输入账号"></el-input>
          </el-form-item>
          <div class="forget-account">
            <span @click="clickGotoAccount()">忘记登录账号</span>
          </div>

          <el-button class="btn" type="primary" @click="clickAccount()" :disabled="form1.account.length===0"  >下一步：验证身份</el-button>

        </template>

        <template v-if="step===2">
          <el-form-item label="手机号">
            <div style="margin-left: 12px;text-align: left">{{data1.phone}}</div>
          </el-form-item>
          <el-form-item label="验证码" style="margin-bottom: 10px">
            <div style="display: flex;justify-content: space-between;">
              <el-input v-model="form2.code" placeholder="请输入验证码" style="flex: 1"></el-input>
              <el-button  type="primary" @click="clickForgetPre()" style="margin-left: 20px;" v-if="forgetpre_seconds===0" >获取</el-button>
              <el-button v-else  style="margin-left: 20px;"  plain  disabled>{{forgetpre_seconds}}S</el-button>
            </div>
          </el-form-item>
          <el-button class="btn" type="primary" @click="clickForget()" :disabled="form2.code.length===0" >立即验证</el-button>
        </template>

        <template v-if="step===3">
          <el-form-item label="新密码" style="margin-bottom: 10px">
            <el-input type="password" v-model="form3.pwd1" placeholder="6-12位数字或字母"></el-input>
          </el-form-item>
          <el-form-item label="确认新密码" style="margin-bottom: 10px">
            <el-input type="password" v-model="form3.pwd2" placeholder="6-12位数字或字母"></el-input>
          </el-form-item>

          <el-button class="login-btn" type="primary" @click="clickForgetReset()" :disabled="form3.pwd1.length===0 && form3.pwd2.length===0" >确认</el-button>
        </template>


        <template v-if="step===4">
          <el-form-item label="手机号" style="margin-bottom: 10px">
            <el-input v-model="form_account.phone" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item label="验证码" style="margin-bottom: 10px">
            <div style="display: flex;justify-content: space-between;">
              <el-input v-model="form_account.code" placeholder="请输入验证码" style="flex: 1"></el-input>
              <el-button v-if="account_seconds === 0"  type="primary" @click="dialogAccountVisible=true" style="margin-left: 20px;" :disabled="form_account.phone.length != 11"  >获取</el-button>
              <el-button v-else  style="margin-left: 20px;"  plain  disabled>{{account_seconds}}S</el-button>
            </div>
          </el-form-item>
          <el-button class="btn" type="primary" @click="clickAccountForget()" :disabled="form_account.phone.length != 11 || form_account.code.length===0" >立即找回</el-button>
        </template>

          <template v-if="step===5">
            <div style="color: #cc2126">
              请妥善保管您的登录账号
            </div>
            <div v-for="(v,i) in data_account" :key="i" style="margin-top: 20px;font-size: 18px">
              <span>{{v.account}}</span>
              <span class="copy" @click="clickCopy(v.account)">复制</span>
            </div>

            <el-button class="btn" type="primary" @click="jumpLogin()"  >前往登录</el-button>
          </template>

        </el-form>


      </div>
    </div>

  </div>

  <el-dialog title="图像验证" :visible.sync="dialogVisible" center width="330px" :modal="false" :close-on-click-modal="false" >
    <CAPTCHAForm v-if="dialogVisible" @onSuccess="onCAPTCHASuceess"  />
  </el-dialog>
  <el-dialog title="图像验证" :visible.sync="dialogAccountVisible" center width="330px" :modal="false" :close-on-click-modal="false" >
    <CAPTCHAForm v-if="dialogAccountVisible" @onSuccess="onAccountCAPTCHASuceess"  />
  </el-dialog>

  <div class="footer-wrap"  >
    <BeianFooter :dark="true" />
  </div>

</div>
</template>

<script>
import img from '@/assets/img/logo_tm.png'
import api from "@/utils/api";
import CAPTCHAForm from "@/views/Mine/subviews/CAPTCHAForm.vue";
import BeianFooter from "@/views/subviews/BeianFooter.vue";
export default {
  name: "ForgetView",
  components: {BeianFooter, CAPTCHAForm},
  data(){
    return {
      text:'',
      step:1,
      img:img,
      form1:{account:""},
      data1:undefined,
      form2:{code:""},
      data2_0:undefined,
      data2_1:undefined,
      form3:{pwd1:"",pwd2:""},
      dialogVisible:false,
      forgetpre_seconds:0,


      form_account:{phone:"",code:""},
      data_account:[],
      dialogAccountVisible:false,
      account_seconds:0,

    }
  },
  computed:{



  },
  mounted() {

  },

  methods:{
    jumpLogin(){
      api.logout()
    },
    clickBack(){
      if (this.step===4 || this.step===5){
        this.step = 1
      }else {
        this.step -= 1
      }
    },
    clickGotoAccount(){
      this.account_seconds = 0
      this.step = 4
    },
    clickAccount(){
      this.dialogVisible = true
    },
    onCAPTCHASuceess(id,code){

      api.get_forget_pre(id,code,this.form1.account).then((data) => {
        this.dialogVisible = false
        this.data1 = data
        this.forgetpre_seconds = 0
        this.step = 2
      })
      .catch(err=>{this.notice_warning(err)})
    },
    clickForgetPre(){

      api.do_forget_pre(this.data1.action_code).then((data)=>{
        this.data2_0 = data

        this.forgetpre_seconds = data.expire
        const fi = setInterval(()=>{
          this.forgetpre_seconds --
          if (this.forgetpre_seconds <= 0) {
            clearInterval(fi)
            this.forgetpre_seconds = 0
          }
        },1000)

      }).catch(err=>{this.notice_warning(err)})
    },

    clickForget(){
      api.do_forget(this.data2_0.action_code,this.form2.code).then((data) => {
        this.data2_1 = data
        this.step = 3
      }).catch(err=>{this.notice_warning(err)})
    },
    clickForgetReset(){
      if (this.form3.pwd1 != this.form3.pwd2){
        this.notice_warning("两次密码不一致")
        return
      }
      api.do_forget_reset(this.data2_1.action_code,this.data2_1.action_secure,this.form3.pwd1).then(() => {
        this.notice_success("密码重置成功，请前往登录")
        setTimeout(()=>{
          api.logout()
        },1500)
      }).catch(err=>{this.notice_warning(err)})
    },
    onAccountCAPTCHASuceess(id,code){

      api.get_account_forget(id,code,this.form_account.phone).then((data)=>{
        this.dialogAccountVisible = false
        this.account_seconds = data.expire
        const fi = setInterval(()=>{
          this.account_seconds --
          if (this.account_seconds <= 0) {
            clearInterval(fi)
            this.account_seconds = 0
          }
        },1000)

      }).catch(err=>{this.notice_warning(err)})

    },

    clickAccountForget(){
      api.do_account_forget(this.form_account.phone,this.form_account.code).then((data)=>{
        this.data_account = data
        this.step = 5
      }).catch(err=>{this.notice_warning(err)})


    },
    clickCopy(account){
      this.$copyText(account).then(() => {
        this.notice_success("拷贝成功")
      }, () => {
        this.notice_error("拷贝失败")
      });
    }



  },
}
</script>

<style lang="scss" scoped>

i.bg {
  position: fixed;
  left: 0;top: 0;right: 0;bottom: 0;
  //filter: blur(2px);
  background-position: center;
  background-size: cover;
  background-image: url("~@/assets/img/loginbg.jpg");

}

.all-round {
  position: fixed;
  top: 0;bottom: 0;right:0;left: 0;
  display: flex;align-items: center;justify-content: center;


  &>.main-wrap{
    position: relative;
    top: -10vh;


    .login-wrap {
      display: flex;
      background-color: rgba(255,255,255,0.8);
      padding: 30px;border-radius: 5px;
      //margin-right: 80px;
      position: relative;

      transition: .5s all;

      &>i.back {
        position: absolute;
        left: 20px;top: 25px;
        font-size: 30px;
        &:hover {
          cursor: pointer;
        }
      }



      .login-qr-wrap {
        border-right:0.5px solid rgba(0, 0,0,0.3);
        padding-right: 30px;
        display: flex;align-items: center;

        .login-qr {
          .title {
            font-size: 22px;

          }
          .qrcode-wrap {
            width: 200px;
            height: 200px;
            position: relative;
            margin: 20px 0;
            &>.qrcode {
              width: 100%;height: 100%;
              &.expire {
                filter: blur(3px);
              }
            }
            &>.expired-wrap {
              position: absolute;
              left: 0;top: 0;bottom: 0;right: 0;
              display: flex;flex-direction: column;align-items: center;justify-content: center;
              //padding-top: 40px;
              background-color: rgba(255,255,255,0.8);
              &>.tip {font-size: 17px;color: black}
              &>.btn {
                color: white;
                background-color: #25a8fb;
                padding: 5px 10px;
                margin-top: 15px;
                border-radius: 3px;
                &:hover { cursor: pointer; }
              }
            }
          }
          .desc { font-size: 15px;color: gray}
          .main {color: #2c3e50}
        }
      }

      .login-main{
        width: 340px;
        display: flex;flex-direction: column;
        .title {
          font-size: 20px;
          font-weight: bold;
          padding-bottom: 20px;
          &>i {
            color: #03A66D;font-size:24px;
          }
        }

        .input-wrap {
          &+.input-wrap{ margin-top: 20px}
          height: 50px;
          //background-color: #409EFF;
          border-bottom: 1px solid grey;
          position: relative;
          input {
            width: 100%;
            height: 100%;
            border: none;
            //background-color: rgba(255,0,0,0.2);
            padding-left: 50px;
            box-sizing: border-box;
            background: none;
          }

          .icon-wrap {
            position:absolute;
            display: flex;align-items: center;justify-content: center;
            width: 50px;height: 100%;
            .icon {
              font-size: 25px;
              color: gray;
            }
          }

        }

        &>.login-btn {
          margin-top: 40px;
        }
      }
    }



  }
}


.phone-wrap {
  display: flex;align-items: center;justify-content:flex-start;font-size: 16px;
  &>span {
    &+span {
      margin-left: 20px;
    }
  }
}

.forget-account {
  margin-top: 10px;
  text-align: right;
  &>span {
    &:hover {
      cursor: pointer;
    }
  }
}
.btn {
  margin-top: 30px;
}

.copy {
  font-size: 16px;margin-left: 10px;
  color: #25a8fb;
  &:hover {
    cursor: pointer;
  }
}

</style>