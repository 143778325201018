<template>

  <div>
    <div class="ly-side">
      <div class="menu">

        <div class="section">
          <div class="item" :class="{activity:menu==='activitys'}"  @click="goto('activitys')">
            <span>我的活动</span>
          </div>
        </div>


        <div class="section-header">
          <span>我的</span>
        </div>
        <div class="section">
          <div class="item" :class="{activity:menu==='accountinfo'}"  @click="goto('accountinfo')" >
            <span>账户信息</span>
          </div>
          <div class="item" :class="{activity:menu==='transations'}"  @click="goto('transations')" >
            <span>交易信息</span>
          </div>
          <div class="item" :class="{activity:menu==='consumption'}"  @click="goto('consumption')" >
            <span>消费/发票</span>
          </div>
        </div>

        <div class="section-header" v-if="isAdmin()">
          <span>管理员</span>
        </div>
        <div class="section" v-if="isAdmin()">
          <div class="item" :class="{activity:menu==='consumption_deal'}"  @click="goto('consumption_deal')" >
            <span>开票处理</span>
          </div>
        </div>


      </div>

      <div class="logout"  >
        <el-button type="danger" plain @click="clickLogout()" >退出登录</el-button>
      </div>

    </div>

    
    <router-view/>
  </div>
</template>

<script>

import api from "@/utils/api";
import {isSubset} from "@/utils/helper";

export default {
  name: "MinePage",
  data() {

    return {
      menu:"",
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.menu = to.meta.menu
    if (to.fullPath != from.fullPath){
      next()
    }
    // don't forget to call next()
  },
  mounted() {
    console.error("99999999999")
    this.menu = this.$route.meta.menu
  },
  methods:{
    isAdmin(){
      return isSubset(['admin'],api.roles)
    },
    clickLogout(){
      api.logout()
    },
    goto(uri){
      this.$router.push(`/mine/${uri}`);
    }
  }
}
</script>

<style lang="scss" scoped>

.ly-section {

  &>.action-wrap {
    display: flex;
    justify-content: space-between;
    &>.group {
      display: flex;
      &>* {
        &+* {
          margin-left: 10px;
        }
      }
    }



  }

  &>.activity-wrap {
    margin-top: 20px;
    &>.activity {
      display: flex;justify-content: space-between;
      padding: 10px;
      border: 1px solid lightgrey;
      border-radius: 5px;

      &+.activity {
        margin-top: 20px;
      }

      .btn-set:hover {
        cursor: pointer;
      }
    }
  }

}

</style>