import http from "./http";
import {logout,KEY_TOKEN} from "./http";
import mqtt from 'mqtt';
import {base64_encode,base64_decode,random} from './helper'

// import Socket from 'net';
// import net from 'net';


function login(token) {
    // console.error('token',token)
    localStorage.setItem(KEY_TOKEN,token)
}

class MqttRunner {
    mqtt_client;
    subscribeMap = {};



    subscribe(topic,onMessage){
        // login_mqtt

        const client = this.get_mqtt_client()
        const topic_base64 = base64_encode(topic)
        let callbackMap = this.subscribeMap[topic_base64]
        if (callbackMap == undefined){
            callbackMap = {}
        }
        // const callbackId = `${random()}_${topic_base64}`
        const callbackId = `${topic_base64}`
        callbackMap[callbackId] = onMessage
        this.subscribeMap[topic_base64] = callbackMap
        console.error("client",client)
        client.subscribe(topic,{qos:2},err=>{
            if(err) {
                console.error(`订阅${topic}失败,${err}`)
            }
        })
        return callbackId
    }
    unsubscribe(callbackId){
        const client = this.get_mqtt_client()
        if(callbackId == undefined)return;
        // const infos = callbackId.split('_')
        // if(infos.length !=2) return;
        // const topic_base64 = infos[1]
        const topic = base64_decode(callbackId)
        client.unsubscribe(topic,{},err=>{
            if(err) {
                console.error(`解除订阅${topic}失败,${err}`)
            }
        })
        let callbackMap = this.subscribeMap[callbackId]
        if (callbackMap == undefined){
            return
        }
        delete callbackMap[callbackId]
    }
    init_client() {
        this.mqtt_client.on('connect',(error) => {
            console.error('连接',error)
        })
        this.mqtt_client.on('reconnect',(error) => {
            console.error('重连',error)
        })
        this.mqtt_client.on('error', (error) => {
            console.error('mqtt error:', error)
            // console.error('mqtt error111111111111:',error.name)
            // console.error('mqtt error12222222222:',error.message)
            // mqtt_client.reconnect()
            console.error('mqtt error:', error)
            if(error.message.includes('Not authorized')||error.message.includes('Bad username or password')){
                // logout()
                // window.location.reload()
            }
        })
        this.mqtt_client.on('disconnect', (error) => {
            console.error('mqtt disconnect:', error)
            // mqtt_client.reconnect()
        })
        this.mqtt_client.on('close', (error) => {
            console.error('mqtt close:', error)
            // mqtt_client.reconnect()
        })
        this.mqtt_client.on('offline', (error) => {
            console.error('mqtt offline:', error)
            // mqtt_client.reconnect()
        })


        this.mqtt_client.on('message', (topic, message) => {
            // console.log('收到消息：', topic, message.toString())
            // {topic_base64:{callbackId:onMessage}}
            const topic_base64 = base64_encode(topic)
            const callbackMap = this.subscribeMap[topic_base64]
            if (callbackMap == undefined)return
            // console.error('callbackMap',callbackMap)
            for (const callbackId in callbackMap){
                try {
                    callbackMap[callbackId](JSON.parse(message.toString()))
                }catch (e) {
                    console.error(123,e)
                    console.error(`onMessage出错,${topic}`)
                }
            }
        })
    }
    get_mqtt_client(){
        return ""
    }
}

class LoginedMqtt extends MqttRunner{




    get_mqtt_client(){
        if (this.mqtt_client)
            return this.mqtt_client



        const token = localStorage.getItem(KEY_TOKEN)
        const body = JSON.parse(new Buffer(token.split('.')[1],'base64').toString('utf-8'))
        // console.log(token,body,body.uid,body.mqtt_pwd)

        const options = {
            clean:true,
            connectTimeout:4000,
            clientId:`mqttjs_${body.uid}_${random()}`,
            username:body.uid,
            password:token,
            keepalive:60,
        }



        this.mqtt_client = mqtt.connect(process.env.VUE_APP_EMQX_URL,options)

        this.init_client()
        return this.mqtt_client
    }
}

class QRMqtt extends MqttRunner{

    constructor(username,password) {
        super()
        this.username = username;
        this.password = password;
    }


    disconnect(){
        this.mqtt_client.end()
        this.mqtt_client = undefined
    }
    get_mqtt_client(){
        if (this.mqtt_client)
            return this.mqtt_client


        const options = {
            clean:true,
            connectTimeout:4000,
            clientId:`mqttjs_qr_${random()}`,
            username:this.username,
            password:this.password,
            keepalive:60,
        }

        // mqtt_client =  mqtt.MqttClient(streamBuilder, options);
        this.mqtt_client = mqtt.connect(process.env.VUE_APP_EMQX_URL,options)

        this.init_client()
        return this.mqtt_client
    }
}

// const qrMqttMap = {}
// function getQrMqtt_client(type,username,password){
//     const key = username//`${type}@@@@${username}`
//     let client = qrMqttMap[key]
//     if (client === undefined) {
//         client = new QRMqtt(key,password)
//     }
//     return client
// }








/*
* 授权接口
* */

// async function get_graph_code() {
//     const url =  '/auth/graph_code' //获取图像验证码
//     return await http.get(url)
// }
// async function do_register(account,password,verify_code,verify_id) {
//     const url =  '/auth/register' //注册
//     const data =  await http.post(url,{account,password,verify_code,verify_id})
//     login(data.token)
//     // login_mqtt()
//     return data
//
// }

async function get_login_qr(){
    const params = {}
    return await http.get(`/auth/login_qr`,{params})
}
async function get_register_qr(){
    const params = {}
    return await http.get(`/auth/register_qr`,{params})
}

async function get_captcha(){
    const params = {}
    return await http.get(`/auth/captcha`,{params})
}

async function do_register(company_name,nickname,account,password) {
    const url =  '/auth/register' //登录
    const data = await http.post(url,{company_name,nickname,account,password})

    // login_mqtt()
    return data
}
async function do_login(account,password) {
    const url =  '/auth/login' //登录
    const data = await http.post(url,{account,password})

    // login_mqtt()
    return data
}

async function do_improve(company_name,account,password) {
    const url =  '/auth/improve' //登录
    const data = await http.post(url,{company_name,account,password})

    // login_mqtt()
    return data
}


async function do_resetpwd(old_password,password) {
    const url =  '/auth/resetpwd' //登录
    const data = await http.post(url,{old_password,password})
    return data
}






const api_auth = {get_captcha,get_login_qr,get_register_qr,do_register,do_login,do_improve,do_resetpwd}


async function do_activity_reset(id){
    const data = {id}
    return await http.post(`/activity/reset`,data)
}
async function do_activity_enable(id){
    const data = {id}
    return await http.post(`/activity/enable`,data)
}
async function do_activity_disable(id){
    const data = {id}
    return await http.post(`/activity/disable`,data)
}
async function get_activity(id){
    const params = {id}
    return await http.get(`/activity`,{params})
}
async function get_activity_list(page,per_page){
    const params = {page,per_page}
    return await http.get(`/activity/list`,{params})
}
async function do_activity_add(name,start_date,end_date,person_limit){
    const data = {name,start_date,end_date,person_limit}
    return await http.post(`/activity/add`,data)
}
async function do_activity_update(id,name,start_date,end_date,person_limit,place,organizer,participant){
    const data = {id,name,start_date,end_date,person_limit,place,organizer,participant}
    return await http.post(`/activity/update`,data)
}

const api_activity = {do_activity_reset,do_activity_enable,do_activity_disable,get_activity_list,do_activity_add,do_activity_update,get_activity}






async function get_persongroup(id){
    const params = {id}
    return await http.get(`/person_group/`,{params})
}

async function get_persongroup_count(id){
    const params = {id}
    return await http.get(`/person_group/count`,{params})
}

async function get_persongroup_list(aid){
    const params = {aid}
    return await http.get(`/person_group/list`,{params})
}
async function do_persongroup_delete(id){
    const data = {id}
    return await http.post(`/person_group/delete`,data)
}
async function do_persongroup_add(aid,name,join_type,person_limit){
    const data = {aid,name,join_type,person_limit}
    return await http.post(`/person_group/add`,data)
}


async function get_person_list(aid,page,per_page,gids){
    const params = {aid,page,per_page,gids}
    return await http.get(`/person/list`,{params})
}
async function do_person_add(aid,gid,name,phone,has_intro,intros,avatar){
    const data = {aid,gid,name,phone,intros,avatar}
    if (has_intro){
        data.has_intro = 1
    }
    return await http.post(`/person/add`,data)
}

async function do_person_update(id,gid,name,phone,has_intro,intros,avatar){
    const data = {id,gid,name,phone,intros,avatar}
    if (has_intro){
        data.has_intro = 1
    }
    return await http.post(`/person/update`,data)
}

async function do_person_delete(id){
    const data = {id}
    return await http.post(`/person/delete`,data)
}

async function do_person_checkin_cancel(id){
    const data = {id}
    return await http.post(`/person/checkin/cancel`,data)
}



const api_person = {get_persongroup,get_persongroup_count,get_persongroup_list,do_persongroup_delete,do_persongroup_add,
    get_person_list,do_person_add,do_person_update,do_person_delete,do_person_checkin_cancel}










async function get_prizegroup_list(aid){
    const params = {aid}
    return await http.get(`/prize_group/list`,{params})
}
async function do_prizegroup_delete(id){
    const data = {id}
    return await http.post(`/prize_group/delete`,data)
}
async function do_prizegroup_add(aid,name){
    const data = {aid,name}
    return await http.post(`/prize_group/add`,data)
}

async function get_prize_list(aid,page,per_page,gids){
    const params = {aid,page,per_page,gids}
    return await http.get(`/prize/list`,{params})
}
async function do_prize_add(aid,gid,name,picture,stock,desc){
    const data = {aid,gid,name,picture,stock,desc}
    return await http.post(`/prize/add`,data)
}

async function do_prize_update(id,gid,name,picture,stock,desc){
    const data = {id,gid,name,picture,stock,desc}
    return await http.post(`/prize/update`,data)
}

async function do_prize_delete(id){
    const data = {id}
    return await http.post(`/prize/delete`,data)
}

const api_prize = {get_prizegroup_list,do_prizegroup_delete,do_prizegroup_add,
    get_prize_list,do_prize_add,do_prize_update,do_prize_delete}








async function get_questiongroup_list(aid){
    const params = {aid}
    return await http.get(`/question_group/list`,{params})
}
async function do_questiongroup_delete(id){
    const data = {id}
    return await http.post(`/question_group/delete`,data)
}
async function do_questiongroup_add(aid,name){
    const data = {aid,name}
    return await http.post(`/question_group/add`,data)
}

async function get_question_list(aid,page,per_page,gids){
    const params = {aid,page,per_page,gids}
    return await http.get(`/question/list`,{params})
}
async function do_question_add(aid,gid,desc,answer){
    const data = {aid,gid,desc,answer}
    return await http.post(`/question/add`,data)
}

async function do_question_update(id,gid,desc,answer){
    const data = {id,gid,desc,answer}
    return await http.post(`/question/update`,data)
}

async function do_question_delete(id){
    const data = {id}
    return await http.post(`/question/delete`,data)
}

const api_question = {get_questiongroup_list,do_questiongroup_delete,do_questiongroup_add,
    get_question_list,do_question_add,do_question_update,do_question_delete}



async function get_screen_base(aid){
    const params = {aid}
    return await http.get(`/screen/base`,{params})
}
async function do_screen_base(aid,logo,resolution,mobile_picture){
    const data = {aid,logo,resolution,mobile_picture}
    return await http.post(`/screen/base`,data)
}


async function get_flow(id){
    const params = {id}
    return await http.get(`/screen/flow`,{params})
}
async function get_flow_list(aid){
    const params = {aid}
    return await http.get(`/screen/flow/list`,{params})
}

async function do_flow_add(aid,sid,id=undefined,is_pre=false){
    const data = {aid,sid}
    if(id !== undefined){
        data.id = id
        if (is_pre){ data.is_pre = is_pre }
    }
    return await http.post(`/screen/flow/add`,data)
}
async function do_flow_edit(id,name){
    const data = {id,name}
    return await http.post(`/screen/flow/edit`,data)
}
async function do_flow_resort(aid,ids){
    const data = {aid,ids}
    return await http.post(`/screen/flow/resort`,data)
}

async function do_flow_delete(id){
    const data = {id}
    return await http.post(`/screen/flow/delete`,data)
}

const api_flow = {get_screen_base,do_screen_base,get_flow,get_flow_list,do_flow_add,do_flow_edit,do_flow_resort,do_flow_delete}












async function get_project_sketch_list(){
    return await http.get(`/project_sketch/list`)
}

async function get_dict(id){
    const params = {id}
    return await http.get(`/dict/`,{params})
}

async function get_dict_list(type){
    const params = {type}
    return await http.get(`/dict/list`,{params})
}

async function get_dict__screen_resolution(){
    return await get_dict_list('SCREEN_RESOLUTION')
}
async function get_dict__activity_person_limit(){
    return await get_dict_list('ACTIVITY_PERSON_LIMIT')
}
async function get_dict__person_group_join_type(){
    return await get_dict_list('PERSON_GROUP_JOINT_TYPE')
}

async function get_dict__checkin_style(){
    return await get_dict_list('CHECKIN_STYLE')
}
async function get_dict__checkin_count_style(){
    return await get_dict_list('CHECKIN_COUNT_STYLE')
}
async function get_dict__lottery_style(){
    return await get_dict_list('LOTTERY_STYLE')
}
async function get_dict__vote_style(){
    return await get_dict_list('VOTE_STYLE')
}
async function get_dict__vote_type(){
    return await get_dict_list('VOTE_TYPE')
}
async function get_dict__ballot_type(){
    return await get_dict_list('BALLOT_TYPE')
}
async function get_dict__ballot_window_position(){
    return await get_dict_list('BALLOT_WINDOW_POSITION')
}

async function get_info(){
    const params = {}
    return await http.get(`/admin/info`,{params})
}
async function do_bind_phone_pre(captcha_id,captcha,phone){
    const data = {captcha_id,captcha,phone}
    return await http.post(`/admin/bind_phone_pre`,data)
}

async function do_bind_phone(phone,code){
    const data = {phone,code}
    return await http.post(`/admin/bind_phone`,data)
}

async function get_bind_qr(){
    const params = {}
    return await http.get(`/admin/bind_qr`,{params})
}

async function get_forget_pre(captcha_id,captcha,account){
    const params = {captcha_id,captcha,account}
    return await http.get(`/admin/forget_pre`,{params})
}

async function do_forget_pre(action_code){
    const data = {action_code}
    return await http.post(`/admin/forget_pre`,data)
}

async function do_forget(action_code,code){
    const data = {action_code,code}
    return await http.post(`/admin/forget`,data)
}

async function do_forget_reset(action_code,action_secure,password){
    const data = {action_code,action_secure,password}
    return await http.post(`/admin/forget_reset`,data)
}



async function get_account_forget(captcha_id,captcha,phone){
    const params = {captcha_id,captcha,phone}
    return await http.get(`/admin/account_forget`,{params})
}

async function do_account_forget(phone,code){
    const data = {phone,code}
    return await http.post(`/admin/account_forget`,data)
}



const api_comm = {get_project_sketch_list,get_dict,
    get_dict__screen_resolution,get_dict__activity_person_limit,get_dict__person_group_join_type,
    get_dict__checkin_style,get_dict__checkin_count_style,get_dict__lottery_style,
    get_dict__vote_style,get_dict__vote_type,get_dict__ballot_type,get_dict__ballot_window_position,
    get_info,get_bind_qr,
    do_bind_phone_pre,do_bind_phone,
    get_forget_pre,do_forget_pre,do_forget,do_forget_reset,
    get_account_forget,do_account_forget
}





async function get_picture(fid){
    const params = {fid}
    return await http.get(`/project/picture`,{params})
}

async function do_picture_update(fid,picture){
    const data = {fid,picture}
    return await http.post(`/project/picture/update`,data)
}

const api_picture = {get_picture,do_picture_update}


async function get_video(fid){
    const params = {fid}
    return await http.get(`/project/video`,{params})
}

async function do_video_update(fid,picture,video){
    const data = {fid,picture,video}
    return await http.post(`/project/video/update`,data)
}

async function do_video_upload(){
    const data = {}
    return await http.post(`/temp/upload_video`,data)
}

const api_video = {get_video,do_video_update,do_video_upload}




async function get_checkinwall(fid){
    const params = {fid}
    return await http.get(`/project/checkin_wall`,{params})
}



async function do_checkinwall_update(fid,style,count_style,checkin_groups,picture,mobile_picture){
    const data = {fid,style,count_style,checkin_groups,picture,mobile_picture}
    return await http.post(`/project/checkin_wall/update`,data)
}

const api_checkinwall = {get_checkinwall,do_checkinwall_update}




async function get_lottery(fid){
    const params = {fid}
    return await http.get(`/project/lottery`,{params})
}

async function do_lottery_update(fid,style,person_group,repeat_win,picture,has_prize,prize_group=undefined){
    const data = {fid,style,person_group,prize_group,picture}
    if (has_prize){ data.has_prize = has_prize }
    if (repeat_win){ data.repeat_win = repeat_win }
    return await http.post(`/project/lottery/update`,data)
}

const api_lottery = {get_lottery,do_lottery_update}








async function get_vote_turn(fid){
    const params = {fid}
    return await http.get(`/project/vote/turn`,{params})
}

async function get_vote(fid){
    const params = {fid}
    return await http.get(`/project/vote`,{params})
}
async function get_vote_run(fid){
    const params = {fid}
    return await http.get(`/project/vote/run`,{params})
}

async function do_vote_run_start(rid){
    const data = {rid}
    return await http.post(`/running/vote/start`,data)
}
async function do_vote_run_pause(rid){
    const data = {rid}
    return await http.post(`/running/vote/pause`,data)
}

async function get_running_total_ranks(vid){
    const params = {vid}
    return await http.get(`/running/vote/total_ranks`,{params})
}


async function do_vote_start(fid){
    const data = {fid}
    return await http.post(`/project/vote/start`,data)
}
async function do_vote_update(fid,vote_type,palyer_gid,win_num,style,picture,mobile_picture){
    const data = {fid,vote_type,palyer_gid,win_num,style,picture,mobile_picture}
    return await http.post(`/project/vote/update`,data)
}

async function do_vote_ballot_add(fid,name,score){
    const data = {fid,name,score}
    return await http.post(`/project/vote/ballot/add`,data)
}
async function do_vote_ballot_update(id,name,score){
    const data = {id,name,score}
    return await http.post(`/project/vote/ballot/update`,data)
}
async function do_vote_ballot_delete(id){
    const data = {id}
    return await http.post(`/project/vote/ballot/delete`,data)
}

async function do_vote_judge_add(fid,person_gid,ballot_type,ballot_window_position,ballot_pool){
    const data = {fid,person_gid,ballot_type,ballot_window_position,ballot_pool}
    return await http.post(`/project/vote/judge/add`,data)
}
async function do_vote_judge_update(id,person_gid,ballot_type,ballot_window_position,ballot_pool){
    const data = {id,person_gid,ballot_type,ballot_window_position,ballot_pool}
    return await http.post(`/project/vote/judge/update`,data)
}
async function do_vote_judge_delete(id){
    const data = {id}
    return await http.post(`/project/vote/judge/delete`,data)
}



const api_vote = {get_running_total_ranks,get_vote_turn,get_vote_run,do_vote_run_start,do_vote_run_pause,get_vote,do_vote_start,do_vote_update,do_vote_ballot_add,do_vote_ballot_update,do_vote_ballot_delete,do_vote_judge_add,do_vote_judge_update,do_vote_judge_delete}





async function do_running_checkin_start(fid){
    const data = {fid}
    return await http.post(`/running/checkin/start`,data)
}

async function do_running_checkin_stop(fid){
    const data = {fid}
    return await http.post(`/running/checkin/stop`,data)
}

async function get_checkin_statistics(fid){
    const params = {fid}
    return await http.get(`/running/checkin/statistics`,{params})
}

async function get_running_lottery(fid){
    const params = {fid}
    return await http.get(`/running/lottery`,{params})
}

async function do_running_lottery_run(fid,stock,prize_id){
    const data = {fid,stock,prize_id}
    return await http.post(`/running/lottery/run`,data)
}
async function get_running_lottery_run_records(fid){
    const params = {fid}
    return await http.get(`/running/lottery/run/records`,{params})
}


async function get_running_vote_run_statistics(rid){
    const params = {rid}
    return await http.get(`/running/vote/statistics`,{params})
}
async function get_running_vote_statistic_ranks(fid){
    const params = {fid}
    return await http.get(`/running/vote/statistic_ranks`,{params})
}




const api_running = {do_running_checkin_start,do_running_checkin_stop,get_checkin_statistics,get_running_lottery,do_running_lottery_run,get_running_lottery_run_records,get_running_vote_run_statistics,get_running_vote_statistic_ranks}







async function do_order_create(amount){
    const data = {amount}
    return await http.post(`/order/create`,data)
}
async function get_order_list(page,per_page){
    const params = {page,per_page}
    return await http.get(`/order/list`,{params})
}
async function order_query(id){
    const params = {id}
    return await http.get(`/order/transaction`,{params})
}

const api_order = {
    get_order_list,
    do_order_create,
    order_query
}






async function do_invoice_header_delete(id){
    const data = {id}
    return await http.post(`/invoice_header/delete`,data)
}
async function do_invoice_header_add(name,suihao){
    const data = {name,suihao}
    return await http.post(`/invoice_header/add`,data)
}
async function get_invoice_header_list(page,per_page){
    const params = {page,per_page}
    return await http.get(`/invoice_header/list`,{params})
}

async function get_consumption_list(state,date,page,per_page){
    const params = {state,date,page,per_page}
    return await http.get(`/consumption/list`,{params})
}

async function do_invoice_delete(id){
    const data = {id}
    return await http.post(`/invoice/delete`,data)
}
async function do_invoice_add(hid,consumption_ids){
    const data = {hid,consumption_ids}
    return await http.post(`/invoice/add`,data)
}
async function get_invoice_list(page,per_page){
    const params = {page,per_page}
    return await http.get(`/invoice/list`,{params})
}

async function get_invoice_manager_list(page,per_page,date,cid,state){
    const params = {page,per_page,date,cid,state}
    return await http.get(`/invoice/manager_list`,{params})
}
async function do_invoice_done(id,url){
    const data = {d,url}
    return await http.post(`/invoice/donw`,data)
}
async function do_invoice_reject(id,reason){
    const data = {id,reason}
    return await http.post(`/invoice/reject`,data)
}



const api_invoice = {
    do_invoice_header_delete,
    do_invoice_header_add,
    get_invoice_header_list,
    get_consumption_list,
    do_invoice_delete,
    do_invoice_add,
    get_invoice_list,
    get_invoice_manager_list,
    do_invoice_done,
    do_invoice_reject
}



const api = {
    login,
    logout,
    logined_mqtt:new LoginedMqtt(),
    qr_mqtt:new QRMqtt("browser","2412d2b49"),
    // subscribe,
    // unsubscribe,
    ...api_activity,
    ...api_comm,
    ...api_person,
    ...api_prize,
    ...api_question,
    ...api_flow,
    ...api_picture,
    ...api_video,
    ...api_checkinwall,
    ...api_lottery,
    ...api_vote,
    ...api_running,
    ...api_order,
    ...api_invoice,

    ...api_auth,
}

// Object.defineProperty(api,'mqtt_client',{
//     get(){
//         return mqtt_client
//     }
// })

Object.defineProperty(api,'logined',{
    get(){
        let token = localStorage.getItem(KEY_TOKEN)
        if (token != undefined){
            return true
        }
        return false
    }
})

Object.defineProperty(api,'uid',{
    get(){
        let token = localStorage.getItem(KEY_TOKEN)
        if (token != undefined){
            let info = token.split('.')[1]
            info = JSON.parse(atob(info))
            const ret = info.uid
            return ret
        }
        return undefined
    }
})

Object.defineProperty(api,'roles',{
    get(){
        let token = localStorage.getItem(KEY_TOKEN)
        if (token != undefined){
            let info = token.split('.')[1]
            info = JSON.parse(atob(info))
            const ret = info.scope.split(',')
            return ret
        }
        return []
    }
})

Object.defineProperty(api,'account',{
    get(){
        let token = localStorage.getItem(KEY_TOKEN)
        if (token != undefined){
            let info = token.split('.')[1]
            info = JSON.parse(atob(info))
            const ret = info.account
            return ret
        }
        return false
    }
})




Object.defineProperty(api,'tokenHeader',{
    get(){
        let token = localStorage.getItem(KEY_TOKEN)
        if (token != undefined){
            return {Authorization:`Bearer ${token}`}
        }
        return {}
    }
})







export default api