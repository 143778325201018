
<template>
  <div class="main" :style="mainbg">
    <BasicContent :fid="fid" @onSuccess="loadPicture" />
  </div>
</template>




<script>
import BasicContent from "@/views/running/components/Vote/Basic/Content.vue";
export default {
  name: "RunningVote",
  props:["aid","fid"],
  components:{BasicContent},
  data(){
    return {
      picture:undefined
    }
  },
  computed:{
    mainbg(){
      if (this.picture){
        return `background-image: url(${this.picture})`
      }
      return "";
    },
  },
  methods:{
    loadPicture(picture){
      console.error(picture)
      this.picture = picture
    }
  }
}
</script>



<style scoped lang="scss">


.main {
  position: fixed;
  left: 0;top: 0;right: 0;bottom: 0;
  color: white;
  background-repeat: no-repeat;
  //background-size: 100% 100%;
  background-size: cover;
  background-position: center;
  //background-image: url("https://img.cdyihuitong.com/votebg.jpg");
  background-color: black;
  display: flex;justify-content: center;

}
</style>