<template>
  <div class="ly-main">
    <div class="ly-section">

      <div class="action-wrap">

        <el-button type="primary" @click="clickCreate()">创建活动</el-button>

        <div class="group">
          <el-input v-model="name" placeholder="输入活动标题"></el-input>
          <el-button icon="el-icon-search" circle  @click="clickSearch()"></el-button>
        </div>

      </div>
      <div class="activity-wrap">
        <div class="activity" v-for="(item,i) in pageData.items" :key="i">
          <div>
            <div>{{item.name}}</div>
            <div><span>活动时间：</span> {{item.start_date_str}} - {{item.end_date_str}}</div>
          </div>
          <div class="right">
            <div>参与上限:<span style="text-decoration: underline">{{item.person_limit.value}}</span>人</div>
            <div v-if="item.person_limit.key!=='LEVEL1'" ><span>付费时间：</span>{{item.create_time_str}}</div>
            <div v-else ><span>创建时间：</span>{{item.create_time_str}}</div>
            <div>{{activity_states[item.state]}} / <span @click="clickSet(item.id)"  class="btn-set">前往配置</span> </div>
          </div>
        </div>

        <el-pagination
            class="new-line"
            background
            layout="sizes, prev, pager, next"
            :page-sizes="[5,8,10,13,15,18,20]"
            :page-size="pageData.per_page"
            :total="pageData.total"
            :current-page="pageData.page"
            @current-change="clickPageChange"
            @size-change="clickPageSizeChange">
        </el-pagination>
      </div>


    </div>

    <!-- Form -->
    <el-dialog title="创建活动" :visible.sync="dialogFormVisible" center>
      <el-form :model="form" ref="form" label-position="top" label-width="100px" class="demo-ruleForm">
        <el-form-item label="活动名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>

        <el-form-item label="活动起止时间" prop="times">
          <el-date-picker
              v-model="form.times"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>

        </el-form-item>

        <el-form-item label="预估人数" prop="peopleLevel">
          <el-select v-model="form.person_limit" placeholder="请选择预估人数">
            <el-option :label="item.name" :value="item.id" v-for="(item,i) in person_limits" :key="i" ></el-option>
          </el-select>
          <span class="cost">￥{{cost|balanceShow}}</span>
        </el-form-item>

        <el-form-item style="text-align: center">
          <el-button type="primary" @click="submitForm()">立即创建</el-button>
          <el-button @click="resetForm()">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/utils/api";
import PubSub from "pubsub-js";
export default {
  name: "ActivitysPage",
  data() {

    return {
      name:'',
      activity_states:['未启用','已启用'],
      pageData:{items:[],page:1,per_page:10,total:0},
      pageData_loading:false,
      person_limits:[],
      dialogFormVisible:false,
      formLabelWidth: '120px',
      form:{
        name:'',
        times:[],
        person_limit:''
      },
    }
  },
  mounted() {
    this.loadDictData()
    this.loadPageData()
  },
  computed:{
    cost(){
      if(this.person_limits.length===0 || this.form.person_limit.length ===0){
        return undefined
      }

      for (const person_limit of this.person_limits) {
        if (this.form.person_limit===person_limit.id){
          return person_limit.cost
        }
      }
      return  undefined
    }
  },
  methods:{
    clickCreate() {
      console.log('创建活动!');
      this.dialogFormVisible = true
    },
    clickSearch() {
      console.log('查询');
    },
    clickSet(id) {
      // console.log(`设置活动${i}`);
      this.$router.push(`/activity/${id}/base`)
    },
    submitForm() {
      if(this.cost>0){
        const costShow = this.util.balanceShow(this.cost)
        this.$confirm(`此操作将从您的余额扣除<span style="color:red">￥${costShow}</span>，活动有效时间为<span style="color:red">20天</span>， 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString:true,
          type: 'warning'
        }).then(() => {
          this.doSubmitForm()
        })
      }else {
        this.doSubmitForm()
      }




    },

    doSubmitForm(){
      let start,end = null
      console.error(this.form.times.length)
      if (this.form.times.length == 2){
        start = Math.floor(this.form.times[0].getTime()/1000)
        end = Math.floor(this.form.times[1].getTime()/1000)
      }
      let name = this.form.name;
      let person_limit = this.form.person_limit;
      api.do_activity_add(name,start,end,person_limit)
          .then(()=>{
            PubSub.publish("balance_refresh")
            this.dialogFormVisible = false;
            this.resetForm();
            this.loadPageData()
          })
          .catch(err=>{this.notice_warning(err)})
    },
    resetForm() {
      this.$refs['form'].resetFields();
      this.resetPersonLimit();
    },
    resetPersonLimit(){
      if (this.person_limits.length > 0){
        this.form.person_limit = this.person_limits[0].id
      }
    },
    loadDictData(){

      api.get_dict__activity_person_limit()
          .then(data=>{
            this.person_limits = data
            this.resetPersonLimit()

          })
          .catch(err=>{this.notice_warning(err)})
    },
    loadPageData(){
      this.pageData_loading = true
      api.get_activity_list(this.pageData.page,this.pageData.per_page)
          .then(data=>{this.pageData = data})
          .catch(err=>{this.notice_warning(err)})
          .finally(()=>{this.pageData_loading = false})
    },
    clickPageSizeChange(per_page){
      this.pageData.per_page = per_page
      this.loadPageData()
    },
    clickPageChange(page) {
      this.pageData.page = page
      this.loadPageData()
    },
  }
}
</script>

<style lang="scss" scoped>

.cost {
  margin-left: 20px;
  font-size: 18px;
  color: red;
}
.ly-section {

  &>.action-wrap {
    display: flex;
    justify-content: space-between;
    &>.group {
      display: flex;
      &>* {
        &+* {
          margin-left: 10px;
        }
      }
    }



  }

  &>.activity-wrap {
    margin-top: 20px;
    &>.activity {
      display: flex;justify-content: space-between;
      padding: 10px;
      border: 1px solid lightgrey;
      border-radius: 5px;
      &>.right {
        text-align: right;
      }
      &+.activity {
        margin-top: 20px;
      }

      .btn-set:hover {
        cursor: pointer;
      }
    }
  }

}

</style>