

<template>
  <div >
    <div class="header">
      <span>物品数据</span>
    </div>
    <div class="section-header">分组</div>
    <div>
      <el-tag
          :key="group.id"
          v-for="group in groups"
          closable
          :disable-transitions="false"
          @close="clickGroupDelete(group)">
        {{group.name}}
      </el-tag>
      <el-input
          class="input-new-tag"
          v-if="inputVisible"
          v-model="inputValue"
          ref="saveTagInput"
          size="small"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm"
      >
      </el-input>
      <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 增加分组</el-button>
    </div>


    <div class="section-header">

      <span>物品</span>
      <el-button @click="addDialogVisible=true" >新增</el-button>
      <el-dialog title="新增物品" :visible.sync="addDialogVisible" left width="400px" :close-on-click-modal="false">
        <PrizeAddForm v-if="addDialogVisible" :aid="aid" :groups="groups" @onSuccess="onAddSuccess" />
      </el-dialog>
    </div>
    <div>

      <el-table
          border
          ref="filterTable"
          :data="pageData.items"
          @filter-change="onFilterChange"
          style="width: 100%">

        <el-table-column
            column-key="gids"
            label="分组"
            width="100"
            :filters="groupFilters"
            filter-placement="bottom-end">
          <template v-slot="scope">
            <el-tag
                disable-transitions>{{scope.row.group.name}}</el-tag>
          </template>
        </el-table-column>

        <el-table-column
            label="图片"
            width="125">
          <template v-slot="scope" >
            <el-image :src="scope.row.picture" style="width: 100px;height: 100px">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
            prop="name"
            label="名称"
            width="140">
        </el-table-column>

        <el-table-column
            prop="stock"
            label="库存"
            width="140">
        </el-table-column>

        <el-table-column
            prop="desc"
            label="描述">
        </el-table-column>
        <el-table-column
            width="220"
            label="操作">

          <template v-slot="scope">
            <el-button
                size="mini"
                @click="clickView(scope.row)">查看</el-button>
            <el-button
                size="mini"
                @click="clickUpdate(scope.row)">编辑</el-button>
            <el-button
                size="mini"
                @click="clickDelete(scope.row.id)">删除</el-button>
          </template>

        </el-table-column>

      </el-table>

      <el-pagination
          class="new-line"
          background
          layout="sizes, prev, pager, next"
          :page-sizes="[5,8,10,13,15,18,20]"
          :page-size="pageData.per_page"
          :total="pageData.total"
          :current-page="pageData.page"
          @current-change="clickPageChange"
          @size-change="clickPageSizeChange">
      </el-pagination>

      <el-dialog title="编辑物品" :visible.sync="updateDialogVisible" left width="400px">
        <PrizeUpdateForm v-if="updateDialogVisible" :prize="currentData" :groups="groups" @onSuccess="onUpdateSuccess" />
      </el-dialog>

      <el-dialog title="预览物品" :visible.sync="viewDialogVisible" left width="400px">
        <PrizeViewForm v-if="viewDialogVisible" :prize="currentData"  />
      </el-dialog>

    </div>
  </div>
</template>

<script>
import PrizeAddForm from "@/views/subviews/PrizeAddForm.vue";
import PrizeUpdateForm from "@/views/subviews/PrizeUpdateForm.vue";
import PrizeViewForm from "@/views/subviews/PrizeViewForm.vue";
import api from "@/utils/api";

export default {
  name: "PrizeManage",
  components: {PrizeViewForm, PrizeUpdateForm, PrizeAddForm},
  props:["aid"],
  data() {
    return {
      gids:'',
      groups: [],
      pageData:{items:[],page:1,per_page:5,total:0},
      inputVisible: false,
      inputValue: '',
      addDialogVisible: false,
      updateDialogVisible:false,
      viewDialogVisible:false,
      currentData:{},
    };
  },
  mounted() {
    this.loadData()
  },
  computed: {
    groupFilters() {
      return this.groups.map(g => {
        return {text: g.name, value: g.id}
      })
    }
  },
  methods: {
    loadData(){

      this.loadPrizeGroupData()
      this.loadPrizeData()
    },
    loadPrizeGroupData(){
      api.get_prizegroup_list(this.aid).then(data=>{
        this.groups = data
      }).catch(err=>{this.notice_warning(err)})
    },
    loadPrizeData(){
      api.get_prize_list(this.aid,this.pageData.page,this.pageData.per_page,this.gids).then(data=>{
        this.pageData = data
      }).catch(err=>{this.notice_warning(err)})
    },
    clickPageSizeChange(per_page){
      this.pageData.per_page = per_page
      this.loadPrizeData()
    },
    clickPageChange(page) {
      this.pageData.page = page
      this.loadPrizeData()
    },
    onFilterChange(data) {
      if (data.gids.length > 0) {
        this.gids = data.gids.join(',')
      }else {
        this.gids = ''
      }
      this.loadPrizeData()
    },
    clickGroupDelete(group) {

      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.do_prizegroup_delete(group.id).then(()=>{
          this.notice_success("删除成功")
          this.groups.splice(this.groups.indexOf(group), 1);
        }).catch(err=>{this.notice_warning(err)})
      }).catch(() => {
        this.notice_info("已取消删除")
      });


    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        api.do_prizegroup_add(this.aid,inputValue.trim())
            .then((data) => {
              this.groups.push(data);
            }).catch(err=>{this.notice_warning(err)})

      }
      this.inputVisible = false;
      this.inputValue = '';
    },


    onAddSuccess(){
      this.addDialogVisible=false;
      this.pageData.page = 1
      this.loadPrizeData()
    },
    onUpdateSuccess(){
      this.updateDialogVisible=false;
      this.loadPrizeData()
    },
    clickDelete(id){



      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.do_prize_delete(id).then(()=>{
          this.notice_success("删除成功")
          this.loadPrizeData()
        }).catch(err=>{this.notice_warning(err)})
      }).catch(() => {
        this.notice_info("已取消删除")
      });

    },
    clickUpdate(data){
      this.currentData = data
      this.updateDialogVisible = true
    },
    clickView(person){
      this.currentData = person
      this.viewDialogVisible = true
    },


  }
}
</script>


<style lang="scss" scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.section-header {
  font-size: 12px;font-weight: bold;
  margin-top: 20px;
  padding: 10px 0;
  display: flex;align-items: center;justify-content: space-between;
}


.image-slot {
  border-radius: 5px;
  width: 100px;height: 100px;
  background-color: #f4f6f9;
  color: #c0c4cc;
  display: flex;justify-content: center;align-items: center;
}

</style>