import {init as baseInit} from "./extensions/baseExtension";
baseInit()

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import dragDialog from "./directive/drag-dialog"
Vue.use(dragDialog)

import './assets/styles/global.scss'
import { init as filter_init } from "./filters";

Vue.config.productionTip = false

import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard)



filter_init(Vue)
// Vue.filter('split',function(value,sep=',') {
//   if (typeof value != 'string')
//     return [value]
//   return value.split(sep)
// })

Vue.prototype.notice = function (msg_obj,type,title){
  let msg = msg_obj
  let html = false
  if (typeof(msg_obj) == 'object' && msg_obj.html){
    msg = msg_obj.content
    html = true
  }

  this.$notify({
    title,
    message: `${msg}`,
    type,
    dangerouslyUseHTMLString:html
  });
}
Vue.prototype.notice_warning  = function (msg_obj){
  this.notice(msg_obj,'warning','警告')
}
Vue.prototype.notice_success  = function (msg_obj){
  this.notice(msg_obj,'success','成功')
}
Vue.prototype.notice_error  = function (msg_obj){
  this.notice(msg_obj,'error','错误')
}
Vue.prototype.notice_info  = function (msg_obj){
  this.notice(msg_obj,'info','提示')
}


Vue.prototype.VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL
Vue.prototype.VUE_APP_IMG_URL = process.env.VUE_APP_IMG_URL
Vue.prototype.VUE_APP_HOME_URL = process.env.VUE_APP_HOME_URL

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
