import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '@/views/Main.vue'
import Login from "@/views/Login.vue";
import Forget from "@/views/Forget.vue";
import AccountImprove from "@/views/AccountImprove.vue";
import Activitys from '@/views/Activitys.vue'
import Activity from '@/views/Activity.vue'
import NoAuth from '@/views/NoAuth.vue'
import Base from "@/views/activity/Base.vue";
import Datasource from "@/views/activity/Datasource.vue";
import Screen from "@/views/activity/Screen/Screen.vue";
import Picture from "@/views/project/Picture.vue";
import Video from "@/views/project/Video.vue";
import ProjectBase from "@/views/project/Base.vue";
import Checkinwall from "@/views/project/Checkinwall.vue";
import Lottery from "@/views/project/Lottery.vue";
import Vote from "@/views/project/Vote.vue";
import FlowAdd from "@/views/project/FlowAdd.vue";

import RunningMain from "@/views/running/Main.vue"
import RunningPicture from "@/views/running/Picture.vue";
import RunningVideo from "@/views/running/Video.vue";
import RunningCheckinWall from "@/views/running/CheckinWall.vue";
import RunningLottery from "@/views/running/Lottery.vue";
import RunningVote from "@/views/running/Vote.vue";
import AccountInfo from "@/views/Mine/AccountInfo.vue";
import Mine from "@/views/Mine.vue";
import Transations from "@/views/Mine/Transations.vue";
import Consumption from "@/views/Mine/Consumption.vue";
import ConsumptionDeal from "@/views/Mine/ConsumptionDeal.vue";

import Test from "@/views/Test.vue";

import api from "../utils/api";
import {isSubset} from "../utils/helper";

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/mine/activitys' },
  {   path: '/noauth',
    name: 'noauth',
    component: NoAuth,
    meta:{public:true}
  },
  {   path: '/login',
    name: 'Login',
    component: Login,
    meta:{public:true}
  },
  {   path: '/test',
    name: 'Test',
    component: Test,
    meta:{public:true}
  },
  {   path: '/forget',
    name: 'Forget',
    component: Forget,
    meta:{public:true}
  },
  { path: '/account_improve',
    name: 'AccountImprove',
    component: AccountImprove,
    meta:{roles:['user']},
  },
  {
    path: '/running/:aid',
    props: true,
    name: 'RunningMain',
    component: RunningMain,
    meta:{roles:['user']},
    children:[
      {
        path: 'picture/:fid',
        props: true,
        name: 'RunningPicture',
        component: RunningPicture,
      },
      {
        path: 'video/:fid',
        props: true,
        name: 'RunningVideo',
        component: RunningVideo,
      },
      {
        path: 'checkinwall/:fid',
        props: true,
        name: 'RunningCheckinWall',
        component: RunningCheckinWall,
      },
      {
        path: 'lottery/:fid',
        props: true,
        name: 'RunningLottery',
        component: RunningLottery,
      },
      {
        path: 'vote/:fid',
        props: true,
        name: 'RunningVote',
        component: RunningVote,
      },
    ]
  },
  {
    path: '/',
    name: 'Main',
    component: Main,
    children:[
      {
        path: 'mine',
        name: 'Mine',
        component: Mine,
        meta:{roles:['user']},
        children: [
          {
            path: 'activitys',
            name: 'Activitys',
            component: Activitys,
            meta:{menu:'activitys'}
          },
          {
            path: 'accountinfo',
            name: 'AccountInfo',
            component: AccountInfo,
            meta:{menu:'accountinfo'}
          },
          {
            path: 'transations',
            name: 'Transations',
            component: Transations,
            meta:{menu:'transations'}
          },
          {
            path: 'consumption',
            name: 'Consumption',
            component: Consumption,
            meta:{menu:'consumption'}
          },
          {
            path: 'consumption_deal',
            name: 'consumption_deal',
            component: ConsumptionDeal,
            meta:{menu:'consumption_deal',roles:['user','admin']}
          },
        ]
      },

      {
        path: 'activity',
        name: 'Activity',
        component: Activity,
        meta:{roles:['user']},
        children: [
          {
            path: ':aid/base',
            props: true,
            name: 'Base',
            component: Base,
            meta:{roles:['user'],menu:'activity_base',}
          },
          {
            path: ':aid/datasource',
            props: true,
            name: 'Datasource',
            component: Datasource,
            meta:{roles:['user'],menu:'activity_datasource',}
          },
          {
            path: ':aid/screen',
            props:true,
            name: 'Screen',
            component: Screen,
            meta:{roles:['user'],menu:'activity_screen',},
            children:[
              {
                path: 'base',
                props:true,
                name: 'ProjectBase',
                component: ProjectBase,
                meta:{roles:['user']}
              },
              {
                path: 'flowadd',
                props:true,
                name: 'FlowAdd',
                component: FlowAdd,
                meta:{roles:['user']}
              },
              {
                path: 'picture/:fid',
                props:true,
                name: 'Picture',
                component: Picture,
                meta:{roles:['user']}
              },
              {
                path: 'video/:fid',
                props:true,
                name: 'Video',
                component: Video,
                meta:{roles:['user']}
              },
              {
                path: 'checkinwall/:fid',
                props:true,
                name: 'Checkinwall',
                component: Checkinwall,
                meta:{roles:['user']}
              },
              {
                path: 'lottery/:fid',
                props:true,
                name: 'Lottery',
                component: Lottery,
                meta:{roles:['user']}
              },
              {
                path: 'vote/:fid',
                props:true,
                name: 'Vote',
                component: Vote,
                meta:{roles:['user']}
              }]
      }]
      },

    ]
  }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next) => {


  let roles = []
  to.matched.forEach(record => {
    if (record.meta.roles instanceof Array) {
      roles = [...roles,...record.meta.roles]
    }
  })

  // console.error(to,roles,api.roles,isSubset(roles,api.roles))
  if(roles.length ==0){
    return next()
  }

  if(isSubset(roles,api.roles)){
    if(api.account || to.name==='AccountImprove'){
      return next()
    }else {
      return next({path:'/account_improve'})
    }

  }

  if(api.roles.length > 0){
    return next({path:'/noauth'})
  }

  return next({path:'/login'})




  // //没角色要求，直接放
  // if (to.meta.roles == undefined){
  //
  //   return next()
  // }
  // let role = api.role()
  // if (to.meta.roles.includes(role)){
  //   return next()
  // }
  // return  next({path:'/noauth',params:{from:from.fullPath}})

})



const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}


export default router
