<template>
<div>

  <div>123</div>
  <div ref="mse"></div>
</div>
</template>

<script>
import Player from 'xgplayer';
import 'xgplayer/dist/index.min.css'
export default {
  name: "Test",
  data(){
    return {
      player:undefined
    }
  },
  mounted() {


    console.error("this.$refs.mse",this.$refs.mse)
    this.player = new Player({
      el:this.$refs.mse,
      autoplay: true,
      volume: 0.3,
      url:'https://weiact.oss-cn-chengdu.aliyuncs.com/cdxcp.mp4',
      poster: "//lf9-cdn-tos.bytecdntp.com/cdn/expire-1-M/byted-player-videos/1.0.0/poster.jpg",
      playsinline: true,
      height: 400,
      width: 600,
      whitelist: ['']
    });

  }
}
</script>


<style scoped lang="scss">

</style>