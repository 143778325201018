

<template>
  <el-form :model="form"  ref="form" label-position="left" label-width="50px" class="form" >

    <div class="content" :style="{display:detailEnable?'flex':'block'}" >
      <div>

        <el-form-item label="头像">
          <el-upload
              class="personadd-avatar-uploader"
              :action="`${VUE_APP_BASE_URL}/temp/upload`"
              :headers="tokenHeader"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :on-error="handleAvatarError"
              :before-upload="beforeAvatarUpload">

            <img v-if="form.avatar" :src="form.avatar" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>





        <el-form-item label="分组" >
          <el-select v-model="form.gid" placeholder="请选择">
            <el-option
                v-for="item in groups"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                :disabled="item.join_type.key == 'CHECKIN_JOIN'">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name" maxlength="10" show-word-limit></el-input>
        </el-form-item>


        <el-form-item label="电话" prop="name">
          <el-input v-model="form.phone" maxlength="11" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="简介" prop="name">
          <el-switch
              v-model="detailEnable"
              active-color="#13ce66"
              inactive-color="#d7dae2">
          </el-switch>
        </el-form-item>
      </div>
      <div v-if="form.has_intro" class="divider"></div>
      <div v-if="form.has_intro"  style="flex: 1">
        <div style="display: flex;justify-content: space-between;align-items: center">
          <div style="padding: 10px 0">简介列表</div>
          <el-button v-if="!showInput" class="button-new-tag" size="small" @click="onShowInput()">+ 增加简介</el-button>
        </div>

        <div>

          <el-input
              v-if="showInput"
              type="textarea"
              autosize
              placeholder="请输入内容"
              v-model="profile"
              ref="saveProfileInput"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
          >
          </el-input>

          <ul>
            <li v-for="(v,i) in form.intros" :key="i" >{{v}}  <i @click="onRemoveIntro(i)"  class="delete el-icon-delete"></i></li>
          </ul>
        </div>
      </div>
    </div>





    <el-form-item style="text-align: center">
      <el-button type="primary" @click="submitForm()">立即创建</el-button>
      <el-button @click="resetForm()">重置</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import api from "@/utils/api";

export default {
  name: "PersonAddForm",
  props:["aid","groups"],
  data(){
    return {
      showInput:false,
      profile:'',
      detailEnable:false,
      form:{gid:'',name:'',phone:'',has_intro:false,intros:[],avatar:''},
    }
  },
  watch:{
    detailEnable(value,old){
      // console.log(value,old)
      this.form.has_intro = value
      this.viewCompact(!value)
    }
  },
  computed:{
    tokenHeader(){
      return api.tokenHeader
    }
  },
  mounted() {
    this.detailEnable = this.form.has_intro
    this.viewCompact(!this.detailEnable)
  },
  methods:{
    onRemoveIntro(i){
      this.form.intros.splice(i,1);
    },
    submitForm() {

      let intros = JSON.stringify(this.form.intros)
      api.do_person_add(this.aid,this.form.gid,this.form.name,this.form.phone,this.form.has_intro,intros,this.form.avatar)
          .then(()=>{
            this.$emit('onSuccess')
            // this.resetForm(); 没必要了，
          })
          .catch(err=>{this.notice_warning(err)})
    },
    resetForm() {
      this.$refs['form'].resetFields();
      this.form.gid = ''
      this.form.avatar = ''
      this.detailEnable = false //自动触发
      this.form.intros = []
    },
    handleAvatarSuccess(res) {
      // console.log("666666666",res,file,file.raw)
      this.form.avatar = res.url
      // this.form.avatar = URL.createObjectURL(file.raw);
    },
    handleAvatarError(err){
      this.notice_error(JSON.parse(err.message).msg);
    },
    beforeAvatarUpload(file) {
      console.error(6666,file.type)
      const isJPG = file.type === 'image/png' || file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 6;

      if (!isJPG) {
        this.$message.error('上传图片只能是 「png,jpg,jpeg」 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 6MB!');
      }
      return isJPG && isLt2M;
    },
    viewCompact(is){
      this.$emit('onViewCompact',is)
    },
    onShowInput() {
      this.showInput = true;
      this.$nextTick(() => {
        this.$refs.saveProfileInput.$refs.textarea.focus();
      });
    },
    handleInputConfirm(){
      let profile = this.profile;
      if (profile) {
        this.form.intros.push(profile);
      }
      this.showInput = false;
      this.profile = '';
    }

  }
}
</script>

<style lang="scss" scoped>

.form>.content {

  .divider {
    background-color: lightgrey;
    width: 1px;
    margin: 40px 30px;
  }
}

$avatarWH: 150px;

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: $avatarWH;
  height: $avatarWH;
  line-height: $avatarWH;
  text-align: center;
}
.avatar {
  width: $avatarWH;
  height: $avatarWH;
  display: block;
}

.delete {
  cursor: pointer;
  &:hover {
    color: red;
  }
}

</style>

<style>

.personadd-avatar-uploader  {

  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:hover {
      border-color: #409EFF;
    }
  }
}
</style>