

<template>
  <el-form :model="form"  ref="form" label-position="left" label-width="50px" class="form" >

    <el-form-item label="图片">
      <el-upload
          class="personadd-avatar-uploader"
          :action="`${VUE_APP_BASE_URL}/temp/upload`"
          :headers="tokenHeader"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :on-error="handleAvatarError"
          :before-upload="beforeAvatarUpload">

        <img v-if="form.picture" :src="form.picture" class="avatar">
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </el-form-item>


    <el-form-item label="分组" >
      <el-select v-model="form.gid" placeholder="请选择">
        <el-option
            v-for="item in groups"
            :key="item.id"
            :label="item.name"
            :value="item.id"
            >
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="名称" prop="name">
      <el-input v-model="form.name" maxlength="10" show-word-limit></el-input>
    </el-form-item>


    <el-form-item label="库存" >
      <el-input-number v-model="form.stock" :step="2"></el-input-number>
    </el-form-item>
    <el-form-item label="描述" >
      <el-input
          prop="desc"
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 5}"
          placeholder="请输入内容"
          v-model="form.desc"
      >
      </el-input>
    </el-form-item>




    <el-form-item style="text-align: center">
      <el-button type="primary" @click="submitForm()">保存</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import api from "@/utils/api";

export default {
  name: "PrizeUpdateForm",
  props:["prize","groups"],
  data(){
    return {
      form:{gid:'',name:'',picture:'',stock:50,desc:''},
    }
  },
  computed:{
    tokenHeader(){
      return api.tokenHeader
    }
  },
  mounted() {
    this.form.gid = this.prize.gid;
    this.form.name = this.prize.name;
    this.form.picture = this.prize.picture;
    this.form.stock = this.prize.stock;
    this.form.desc = this.prize.desc;
  },
  methods:{
    submitForm() {
      api.do_prize_update(this.prize.id,this.form.gid,this.form.name,this.form.picture,this.form.stock,this.form.desc)
          .then(()=>{
            this.$emit('onSuccess')
            // this.resetForm(); 没必要了，
          })
          .catch(err=>{this.notice_warning(err)})
    },
    handleAvatarSuccess(res) {
      this.form.picture = res.url
    },
    handleAvatarError(err){
      this.notice_error(JSON.parse(err.message).msg);
    },
    beforeAvatarUpload(file) {

      const isJPG = file.type === 'image/png' || file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 6;

      if (!isJPG) {
        this.$message.error('上传图片只能是 「png,jpg,jpeg」 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 6MB!');
      }
      return isJPG && isLt2M;
    },

  }
}
</script>

<style lang="scss" scoped>

.form>.content {

  .divider {
    background-color: lightgrey;
    width: 1px;
    margin: 40px 30px;
  }
}

$avatarWH: 120px;

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: $avatarWH;
  height: $avatarWH;
  line-height: $avatarWH;
  text-align: center;
}
.avatar {
  width: $avatarWH;
  height: $avatarWH;
  display: block;
}

</style>

<style>

.personadd-avatar-uploader  {

  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:hover {
      border-color: #409EFF;
    }
  }
}
</style>