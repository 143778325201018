

<template>

  <div class="ly-section">

      <div >
        <div class="line-wrap">
          <h3>发票抬头</h3>
          <el-button size="small" @click="addDialogVisible=true">新增</el-button>

          <el-dialog title="新增发票抬头" :visible.sync="addDialogVisible" left width="600px" :close-on-click-modal="false">
            <InvoiceHeaderAddForm v-if="addDialogVisible"  @onSuccess="onAddSuccess"  />
          </el-dialog>

        </div>
        <div>
          <el-table
              border
              :data="pageData.items"
              style="width: 100%">

            <el-table-column
                prop="name"
                label="抬头名称">

            </el-table-column>
            <el-table-column
                prop="suihao"
                label="税号">
            </el-table-column>

            <el-table-column
                label="操作"
                width="180"
                >
              <template v-slot="scope">
                <el-button type="danger" size="mini" plain @click="clickDelete(scope.row.id)" >删除</el-button>
              </template>
            </el-table-column>




          </el-table>

          <el-pagination
              class="new-line"
              background
              layout="sizes, prev, pager, next"
              :page-sizes="[5,8,10,13,15,18,20]"
              :page-size="pageData.per_page"
              :total="pageData.total"
              :current-page="pageData.page"
              @current-change="clickPageChange"
              @size-change="clickPageSizeChange">
          </el-pagination>



        </div>
      </div>

    </div>

</template>

<script>
import api from "@/utils/api";
import InvoiceHeaderAddForm from "@/views/Mine/subviews/InvoiceHeaderAddForm.vue";


export default {
  name: "InvoiceHeaderManager",
  components: {InvoiceHeaderAddForm},
  data() {
    return {
      addDialogVisible:false,
      pageData:{items:[],page:1,per_page:5,total:0},
      form:{},
    };
  },
  mounted() {
    this.loadData()
  },
  computed: {
  },
  methods: {
    onAddSuccess(){
      this.addDialogVisible = false
      this.loadData()
    },
    loadData(){
      api.get_invoice_header_list(this.pageData.page,this.pageData.per_page).then(data=>{
        this.pageData = data
      }).catch(err=>{this.notice_warning(err)})
    },
    clickPageSizeChange(per_page){
      this.pageData.per_page = per_page
      this.loadPrizeData()
    },
    clickPageChange(page) {
      this.pageData.page = page
      this.loadPrizeData()
    },
    clickDelete(id) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.doDelete(id)
      }).catch(() => {
        this.notice_info("已取消删除")
      });
    },
    doDelete(id){
      api.do_invoice_header_delete(id).then(()=>{
        this.notice_success("删除成功")
        this.loadData()
      }).catch(err=>{this.notice_warning(err)})
    }

  }
}
</script>


<style lang="scss" scoped>

.line-wrap {
  display: flex;justify-content: space-between;align-items: center;
}




</style>