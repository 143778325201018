<template>
  <el-form :model="form" ref="form1" label-position="top" label-width="100px" class="demo-ruleForm">


    <el-form-item  style="text-align: center">
      <el-image
          style="width: 200px;height: 80px"
          :src="data.captcha"
          fit="contain"
          @click="loadData()"
      ></el-image>
      <div><i class="el-icon-refresh refresh-btn"  @click="loadData()" ><span>换一个</span></i></div>
      <el-input v-model="form.code" placeholder="输入图像验证码" style="width: 180px"></el-input>
    </el-form-item>




    <el-form-item  style="text-align: center">
      <el-button type="primary" @click="clickSure()" :disabled="form.code.length===0" >确认</el-button>
    </el-form-item>
  </el-form>
</template>


<script>
import api from "@/utils/api";
export default {
  name: "CAPTCHAForm",
  data(){
    return {
      form:{code:""},
      data:{captcha:"",captcha_id:""},
    }
  },
  mounted() {
    this.loadData()
  },
  methods:{
    loadData(){
      api.get_captcha()
          .then(data=>{this.data=data})
          .catch(err=>{this.notice_warning(err)})
    },
    clickSure(){
      this.$emit('onSuccess',this.data.captcha_id,this.form.code)
    }
  }
}
</script>

<style scoped lang="scss">

.line-wrap {
  display: flex;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
}
.qr_wrap {
  display: inline-block;
  width: 150px;
  height: 80px;
  background-color: lightgray;
}
.seconds {
  font-size: 16px;
  color: red;
}

.refresh-btn {
  &:hover { cursor: pointer; }
  font-size: 20px;
  span {font-size: 18px;}
}

</style>