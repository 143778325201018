

<template>

  <div class="ly-section">

    <div >
      <h3>消费记录</h3>
      <div>
        <el-form ref="form" :inline="true" :model="form" label-width="80px">

          <el-form-item label="开票状态">
            <el-select v-model="form.state" placeholder="请选择" style="width: 100px">
              <el-option label="全部" :value="undefined"></el-option>
              <el-option label="未开票" :value="0"></el-option>
              <el-option label="已开票" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="消费时间">
            <el-date-picker
                style="width: 130px"
                v-model="form.date"
                type="month"
                placeholder="选择月">
            </el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="loadData()">查询</el-button>
          </el-form-item>

          <el-form-item>
            <el-button type="success" @click="clickCreateInvoice()">开票</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div>

        <el-table
            border
            :data="pageData.items"
            @selection-change="handleSelectionChange"
            style="width: 100%">

          <el-table-column
              type="selection"
              width="55">
          </el-table-column>

          <el-table-column
              prop="name"
              label="消费出处">
          </el-table-column>
          <el-table-column
              label="消费金额"
              width="140">
            <template v-slot="scope">
              ￥{{scope.row.amount | balanceShow}}
            </template>
          </el-table-column>
          <el-table-column
              prop="create_time_str"
              label="消费时间"
              width="180">
          </el-table-column>

          <el-table-column
              label="发票"
              width="180"
          >
            <template v-slot="scope">
              <span v-if="scope.row.has_invoice">已开票</span>
              <span v-else>未开票</span>
            </template>

          </el-table-column>




        </el-table>

        <el-pagination
            class="new-line"
            background
            layout="sizes, prev, pager, next"
            :page-sizes="[5,8,10,13,15,18,20]"
            :page-size="pageData.per_page"
            :total="pageData.total"
            :current-page="pageData.page"
            @current-change="clickPageChange"
            @size-change="clickPageSizeChange">
        </el-pagination>



      </div>
    </div>

    <el-dialog title="开票确认" :visible.sync="addDialogVisible" left width="600px" :close-on-click-modal="false">
      <InvoiceAddForm v-if="addDialogVisible" :consumptions="multipleSelection"  @onSuccess="onAddSuccess"  />
    </el-dialog>
  </div>

</template>

<script>
import api from "@/utils/api";
import InvoiceAddForm  from "@/views/Mine/subviews/InvoiceAddForm.vue";

export default {
  name: "ConsumptionManager",
  components: {InvoiceAddForm},
  data() {
    return {
      addDialogVisible:false,
      pageData:{items:[],page:1,per_page:5,total:0},
      form:{state:undefined,date:undefined},
      multipleSelection:[],
    };
  },
  mounted() {
      this.form.date = new Date();
  },
  computed: {
  },
  methods: {
    onAddSuccess(){
        this.addDialogVisible=false;
        location.reload()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    loadData(){
      const date =  Math.floor(this.form.date.getTime()/1000)
      api.get_consumption_list(this.form.state,date,this.pageData.page,this.pageData.per_page).then(data=>{
        this.pageData = data
      }).catch(err=>{this.notice_warning(err)})
    },
    clickPageSizeChange(per_page){
      this.pageData.per_page = per_page
      this.loadPrizeData()
    },
    clickPageChange(page) {
      this.pageData.page = page
      this.loadPrizeData()
    },
    clickCreateInvoice(){
      // alert("开票")
      // console.error(this.multipleSelection)
      if (this.multipleSelection.length === 0){
        this.notice_warning("请先选择消费记录")
        return
      }


      // api.do_invoice_add(hid,consumption_ids)
      this.addDialogVisible = true


    }

  }
}
</script>


<style lang="scss" scoped>

.line-wrap {
  display: flex;justify-content: space-between;align-items: center;
}




</style>