<template>
    <div class="full-screen center-flex main-wrap">
      <div>你没有权限访问此页，{{sec}}秒后带你离开</div>
    </div>
</template>

<style lang="scss" scoped>
.main-wrap {
  margin-top: 40px;
  font-size: 20px;
}
</style>

<script>
export default {
  data(){
    return {
      sec:3
    }
  },
  mounted() {

    let it = setInterval(()=>{
      this.sec --
      if (this.sec <= 0){
        clearInterval(it)
        this.$router.push('/')
      }
    },1000)
  },
  methods:{

  }
}
</script>

<style>
</style>
