import md5 from 'crypto-js/md5';
import { v4 as uuidv4} from 'uuid';


export function random(len=12){
    const uuid = uuidv4()
    const md5_uuid = md5(uuid).toString()
    // console.error('uuid,md5_uuid',uuid,md5_uuid)
    if (len > 32) {
        len = 32
    }
    if ( len <1) {
        len =1
    }
    const res = md5_uuid.slice(0,len)
    return res
}

export function base64_encode(str){
    const base64 = btoa(encodeURI(str))
    // console.error("str=>base64",str,base64)
    return base64;
}


export function base64_decode(base64){
    const str = decodeURI(atob(base64));
    // console.error("base64=>str",base64,str)
    return str;
}


export function hms_for_show(ms){
    const offset = 24*60*60*1000
    const systemOffset = new Date().getTimezoneOffset() *60*1000
    return offset+ms+systemOffset
}
export function hms_from_show(show_ms){
    const offset = 24*60*60*1000
    const systemOffset = new Date().getTimezoneOffset() *60*1000
    return show_ms - systemOffset - offset
}

export async function sleep(ms){
    return new PointerEvent((resolve)=>{
        setTimeout(()=>{
            resolve()
        },ms)
    })
}


export async function wait(ms){
    return new Promise((resolve)=>{
        setTimeout(()=>{
            resolve()
        },ms)
    })
}


export function isSubset(subSet,superSet){
    return subSet.every(sub=>{
       return superSet.includes(sub)
    })
}








