

<template>
  <div class="footer-main" :class="{dark:dark}" >
    <div>
      <a href="https://beian.miit.gov.cn" target="_blank" >蜀ICP备2024095601号-1</a>
    </div>
<!--    <div>-->
<!--      <a href="https://www.baidu.com" class="gongan">-->
<!--        <img :src="img" alt=""><span>川公网安备 51018102000200号</span>-->
<!--      </a>-->
<!--    </div>-->
  </div>

</template>

<script>
export default {
  name: "BeianFooter",
  props:["dark"],
  data(){
    return {
      img:require("@/assets/img/gongan_beian.png"),
    }
  },
}
</script>

<style scoped lang="scss">
    .footer-main {
      font-size: 14px;
      &.dark {
        a { color: white }
      }
      a { color: rgb(20,20,20) }
      .gongan {
        display: flex;align-items: center;
        span { margin-left: 3px}
        img { position: relative;top: -2px }
      }
    }
</style>