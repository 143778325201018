

<template>

  <div class="ly-main">

<!--    <div class="ly-section">-->
<!--      <div class="header">选择抽奖样式</div>-->
<!--      <div class="styles-wrap">-->
<!--        <div class="item" v-for="(v,i) in styles" :key="i" :class="{active: v.id === form.style}" @click="form.style = v.id" >-->
<!--          <div class="top">-->
<!--            <el-image fit="fill" class="image"  src="https://youimg1.c-ctrip.com/target/01051120001w81bh1A49D.jpg"></el-image>-->
<!--            <div class="check">-->
<!--              <i class="el-icon-check"></i>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="text">{{v.name}}</div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <div class="ly-section">
      <div class="header">背景设置</div>
      <OnePicture v-model="form.picture" :aid="aid" style="width: 360px;" title="电脑端" />
    </div>

    <div class="ly-section">
      <div class="header">抽奖设置</div>
      <el-form   ref="form" label-position="top" label-width="100px" class="form" >

        <el-form-item label="奖品">
          <el-radio-group v-model="form.has_prize">
            <el-radio :label="true" key="1" >来自数据源</el-radio>
            <el-radio :label="false" key="2" >无奖品</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item  v-if="form.has_prize">
          <el-select v-model="form.prize_group" placeholder="请选择">
            <el-option
                v-for="(v,i) in prize_groups"
                :key="i"
                :label="v.name"
                :value="v.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="参与者" >
          <el-select v-model="form.person_group" placeholder="请选择">
            <el-option
                v-for="(v,i) in person_groups"
                :key="i"
                :label="v.name"
                :value="v.id">
            </el-option>
          </el-select>
        </el-form-item>


        <el-form-item label="允许重复中奖">
          <el-switch
              v-model="form.repeat_win">
          </el-switch>
          <span v-if="form.repeat_win" class="switch-desc">允许</span>
          <span v-else class="switch-desc">不允许</span>
        </el-form-item>




        <el-form-item style="text-align: left">
          <el-button type="primary" @click="save">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>

</template>
<script>
import api from "@/utils/api";
import OnePicture from "@/views/subviews/OnePicture.vue";

export default {
  name: "LotteryPage",
  components: {OnePicture},
  props:["aid","fid"],
  data(){
    return{
      styles:[],
      prize_groups:[],
      person_groups:[],
      form:{style:"",has_prize:false,prize_group:"",person_group:"",repeat_win:false,picture:""},
    }
  },
  mounted() {
    this.loadData()
  },
  methods:{
    save(){

      api.do_lottery_update(this.fid,this.form.style,this.form.person_group,this.form.repeat_win,this.form.picture,this.form.has_prize,this.form.prize_group).then(()=>{
        this.notice_success("更新成功")
      }).catch(err=>{this.notice_warning(err)})

    },
    loadData(){
      this.loadStyleData()
      this.loadPrizeGroupData()
      this.loadPersonGroupData()
      this.loadMainData()
    },
    loadStyleData(){
      api.get_dict__lottery_style().then(data=>{
        this.styles = data
      }).catch(err=>{this.notice_warning(err)})
    },
    loadPrizeGroupData(){
      api.get_prizegroup_list(this.aid).then(data=>{
        this.prize_groups = data
      }).catch(err=>{this.notice_warning(err)})
    },
    loadPersonGroupData(){
      api.get_persongroup_list(this.aid).then(data=>{
        this.person_groups = data
      }).catch(err=>{this.notice_warning(err)})
    },

    loadMainData(){
      api.get_lottery(this.fid).then(data=>{
        this.form.style = data.style.id
        this.form.picture = data.picture
        this.form.has_prize = data.has_prize

        if(data.prize_group){
          this.form.prize_group = data.prize_group.id
        }
        if(data.person_group){
          this.form.person_group = data.person_group.id
        }

        this.form.repeat_win = data.repeat_win
      }).catch(err=>{this.notice_warning(err)})
    }
  }
}
</script>

<style lang="scss">
.styles-wrap>.item>.top img {
  border-radius: 4px;
}
</style>
<style lang="scss" scoped>
.styles-wrap {
  display: flex;flex-wrap: wrap;
  margin-top: 10px;
  &>.item {
    margin: 10px 0 0 10px;
    border-radius: 5px;

    &.active {
      .check {
        display: flex !important;
        align-items: center;justify-content: center;
      }
    }
    &>.top {
      width: 180px;height: 100px;
      &>.image {
        width: 180px;height: 100px;
      }
      position: relative;
      &>.check {

        position: absolute;
        right: 0;bottom: 0;
        width: 20px;height: 20px;
        background-color: #409EFF;
        display: none;
        color: white;font-size: 13px;
        border-radius: 5px 0 5px 0 ;
      }
    }
    &>.text {
      margin-top: 5px;
      font-size: 13px;
      text-align: center;
    }
  }
}




$avatarW: 100px;
$avatarH: 100px;

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: $avatarW;
  height: $avatarH;
  line-height: $avatarH;
  text-align: center;
}
.avatar {
  width: $avatarW;
  height: $avatarH;
  display: block;
}

.form {
  margin-top: 30px;
  display: inline-block;
  .tip {
    font-size: 12px;
    line-height: 18px;
    color: #888;
  }

  .item-line {
    display: flex;
    &+.item-line {
      margin-top: 10px;
    }
    .label {
      width: 60px;
      font-size: 12px;
      color: #666;
    }
    &>*+*{
      margin-left: 10px;
    }
    .el-input {
      width: 300px;
    }
  }
}


.switch-desc{
  margin-left: 10px;
  position: relative;top: 2px;
  font-size: 12px;
  color: #409EFF;
}

</style>