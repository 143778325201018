

<template>
  <el-form :model="form"  ref="form" label-position="top" label-width="50px" class="form" >


    <el-form-item label="人员" >
      <el-select v-model="form.person_gid" placeholder="请选择">
        <el-option
            v-for="item in person_groups"
            :key="item.id"
            :label="item.name"
            :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>


    <el-form-item label="选票类型" >
      <el-radio-group v-model="form.ballot_type">
        <el-radio v-for="(v,i) in ballot_types"  :label="v.id" :key="i">{{v.name}}</el-radio>
      </el-radio-group>
    </el-form-item>


    <el-form-item label="窗口位置" v-if="form.ballot_type.length >0 && typeDic[form.ballot_type].key==='window'" >
      <el-radio-group v-model="form.ballot_window_position" size="small">
        <el-radio-button v-for="(v,i) in ballot_window_positions" :label="v.id" :key="i">{{v.name}}</el-radio-button>
      </el-radio-group>
    </el-form-item>

    <div class="section-header">选票池</div>
    <div class="tags-wrap" >
      <el-tag
          :key="i"
          v-for="(v,i) in selectedBallots"
          closable
          :disable-transitions="false"
          @close="handleClose(i)">
        {{v.name}}
      </el-tag>
      <el-select class="input-new-tag" ref="selectInput" v-model="inputValue"  v-if="inputVisible" @change="handleInputConfirm" placeholder="请选择">
        <el-option
            v-for="item in ballots"
            :key="item.id"
            :label="item.name"
            :value="item.id">
        </el-option>
      </el-select>

      <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 增加分组</el-button>
    </div>

    <el-form-item style="text-align: center">
      <el-button type="primary" @click="submitForm()">立即创建</el-button>
      <el-button @click="resetForm()">重置</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import api from "@/utils/api";

export default {
  name: "JudgeAddForm",
  props:["fid","ballot_types","ballot_window_positions","person_groups","ballots"],
  data(){
    return {
      inputValue:'',
      inputVisible:false,
      form:{person_gid:"",ballot_type:"",ballot_window_position:"",ballot_pool:""},
      selectedBallots:[],
    }
  },
  computed:{
    typeDic(){
      let dic = {}
      for (const v of this.ballot_types) {
        dic[v.id] = v
      }
      return dic
    },
    ballotDic(){
      let dic = {}
      for (const v of this.ballots) {
        dic[v.id] = v
      }
      return dic
    },
  },
  methods:{
    submitForm() {
      let ballot_pool = this.selectedBallots.map(item=>{return item.id}).join(',')
      // if(this.typeDic[this.form.ballot_type].key==='fixed'){
      //   this.form.ballot_window_position = ""
      // }
      api.do_vote_judge_add(this.fid,this.form.person_gid,this.form.ballot_type,this.form.ballot_window_position,ballot_pool)
          .then(()=>{
            this.$emit('onSuccess')
            // this.resetForm(); 没必要了，
          })
          .catch(err=>{this.notice_warning(err)})
    },
    resetForm() {
      this.form = {person_gid:"",ballot_type:"",ballot_window_position:"",ballot_pool:""}
      this.selectedBallots = []
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        // this.$refs.selectInput.$refs.input.focus();
        // this.$refs.selectInput.focus();
        this.$refs.selectInput.toggleMenu(true);
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.selectedBallots.push(this.ballotDic[inputValue]);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    handleClose(i) {
      this.selectedBallots.splice(i, 1);
    },
  }
}
</script>

<style lang="scss" scoped>

.form {
  display: block;
  &>.content{
    .divider {
      background-color: lightgrey;
      width: 1px;
      margin: 40px 30px;
    }
  }

}

.tags-wrap {
  margin-bottom: 22px;
  margin-top: -10px;margin-left: -10px;
}
.el-tag  {
  margin-left: 10px;
  margin-top: 10px;
}
.button-new-tag {
  margin-left: 10px;
  margin-top: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.section-header {
  font-size: 14px;font-weight: bold;
  margin-top: 20px;
  padding: 10px 0;
  display: flex;align-items: center;justify-content: space-between;
}



</style>
