<template>

  <div>
    <div class="ly-side">
      <div class="menu" v-if="activity">

        <div class="section-header">
          <span>当前活动</span>
        </div>
        <div class="section">
          <div class="activity">
            <div class="header">{{activity.name}}</div>
            <div>参与上限：{{activity.person_limit.value}}人</div>
            <div class="action">
              <span @click="$router.push('/mine/activitys')"> &leftarrow; 返回活动列表 </span>
            </div>
          </div>
        </div>
        <div class="section-header">
          <span>基础配置</span>
        </div>
        <div class="section">
          <div class="item" :class="{activity:menu=='activity_base'}"  @click="goto('base')" >
            <span>基本信息</span>
          </div>
          <div class="item" :class="{activity:menu=='activity_datasource'}"  @click="goto('datasource')" >
            <span>数据源</span>
          </div>
        </div>
        <div class="section-header">
          <span>活动配置</span>
        </div>
        <div class="section">
          <div class="item" :class="{activity:menu=='activity_screen'}"  @click="goto('screen/base')" >
            <span>活动构成</span>
          </div>

        </div>

      </div>


      <div class="logout" >
        <el-button type="danger" plain @click="clickLogout()" >退出登录</el-button>
      </div>

    </div>
    <router-view/>
  </div>
</template>

<script>
import api from "@/utils/api";
import PubSub from "pubsub-js";
export default {
  name: "ActivityPage",
  data() {
    return {
      aid:null,
      activity:null,
      menu:'',
      tokens:[],
    }
  },
  // watch:{
  //   $route(to) {
  //     // 对路由变化作出响应...
  //     this.menu = to.meta.menu;
  //   }
  // },
  beforeRouteUpdate(to, from, next) {
    this.menu = to.meta.menu
    if (to.fullPath != from.fullPath){
      next()
    }

    // don't forget to call next()
  },
  mounted() {
    this.aid = this.$route.params.aid
    this.menu = this.$route.meta.menu
    this.loadData()

    let token1 = PubSub.subscribe("activity_updated",()=>{
      this.loadData()
    })
    this.tokens = [token1]

  },
  beforeDestroy() {
    for (const token of this.tokens) {
      PubSub.unsubscribe(token)
    }
  },
  methods:{
    clickLogout(){
      api.logout()
    },
    loadData(){
      api.get_activity(this.aid)
          .then(data=>{
            this.activity = data
          })
          .catch(err=>{this.notice_warning(err)})
    },
    goto(uri){
      this.$router.push(`/activity/${this.aid}/${uri}`);
    }

  }
}
</script>

<style lang="scss" scoped>

.ly-section {

  &>.action-wrap {
    display: flex;
    justify-content: space-between;
    &>.group {
      display: flex;
      &>* {
        &+* {
          margin-left: 10px;
        }
      }
    }



  }

  &>.activity-wrap {
    margin-top: 20px;
    &>.activity {
      display: flex;justify-content: space-between;
      padding: 10px;
      border: 1px solid lightgrey;
      border-radius: 5px;

      &+.activity {
        margin-top: 20px;
      }

      .btn-set:hover {
        cursor: pointer;
      }
    }
  }

}

</style>