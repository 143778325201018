<template>
  <div class="ly-container">
    <div class="ly-header">
      <div class="logo">
        <i class="icon"/>
        <span class="title">逸会通</span>
      </div>
      <div class="user">
        <div  class="wrap" v-if="data">

          <div class="title">{{data.company.name}}</div>
          <div class="detail">
            <div class="item">
              <i class="icon user" />
              <span>{{data.nickname}}</span>
            </div >
            <div  class="item">
              <i class="icon balance" />
              <span>{{data.company.balance_str}}</span>
            </div>

          </div>
        </div>

      </div>
    </div>


<!--    <div class="ly-footer">底部菜单保留</div>-->
    <router-view/>


  </div>
</template>

<script>

// import Menu from "@/components/Menu.vue";
import api from "@/utils/api";
import PubSub from "pubsub-js";
export default {
  name: "MainPage",
  // components:{Menu},
  data() {
    return {
      data:undefined,
      tokens:[],
    }
  },
  mounted() {
    this.loadData()

    let token1 = PubSub.subscribe("balance_refresh",()=>{
      this.loadData()
    })
    this.tokens = [token1]
  },
  beforeDestroy() {
    for (const token of this.tokens) {
      PubSub.unsubscribe(token)
    }
  },
  methods:{
    loadData(){
      api.get_info().then(data=>{
        this.data = data
      }).catch(err=>{this.notice_warning(err)})
    }
  }
}
</script>

<style lang="scss">


.user {

  .wrap {
    text-align: right;
    .title {
      font-size: 18px;
    }
    .detail {
      font-size: 16px;
      display: flex;justify-content: space-between;
      &>.item {
        display: flex;align-items: baseline;
        &+.item { margin-left: 20px}
        i.icon {
          position: relative;top: 3px;
          margin-right: 2px;
          &.user {
            background-image: url("~@/assets/img/user_account.png");
            width: 20px;height: 20px;
          }
          &.balance {
            background-image: url("~@/assets/img/user_balance.png");
            width: 20px;height: 20px;
          }
        }
      }
    }
    .tip {
      font-size: 18px;
      color: red;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

$header-height: 56px;
$footer-height: 56px;
$menu-width:192px;

$main-padding-v:15px;
$main-padding-h:20px;

.ly-container {
  &>.ly-header {
     height: $header-height;
    background-color: white;
    position: fixed;
    top: 0;left:0;right: 0;
    box-shadow: 0 0 100px 0 lightgrey;
    display: flex;
    align-items: center;justify-content: space-between;
    padding: 0 30px;
    //border-radius: 0 0 15px 15px ;

    &>.logo {
      display: flex;
      align-items: center;
      &>i.icon {
        width: 70px;height: 70px;
        background-image: url("~@/assets/img/logo.png");
      }
      &>.title {
        font-size: 20px;
        font-style: italic;
        color: #409EFF;
      }
    }
  }

  & .ly-side {
    position: fixed;
    top: $header-height;left: 0;bottom: 0;
    width: $menu-width;
    background-color: white;
    //border-radius: 15px;

    &>.menu {
      margin-top: 20px;
      color: #9da0a3;
      font-size: 14px;
      &>.section-header {
        display: flex;
        padding: 5px 11px;
        color: #777;font-weight: bold;
      }
      &>.section {
        padding: 0 10px;
        &+.section-header{
          margin-top: 20px;
        }
        &>.item {
          &+.item { margin-top: 10px}
          padding: 10px 15px;
          border-radius: 4px;
          display: flex;
          &.active {
            background-color: #66b1ff;
            color: #fff;
          }
          &:hover {
            cursor: pointer;
            background-color: #66b1ff;
            color: #fff;
          }

        }

        &>.activity {
          color: white;
          background-color: #66b1ff;
          border-radius: 4px;
          text-align: left;
          padding: 15px;
          font-weight: bold;font-size: 16px;

          //&>.header {
          //  font-weight: bold;
          //  font-size: 16px;
          //}
          &>.action {
            font-size: 14px;font-weight: normal;
            border-top: dashed 1px lightgrey;
            margin-top: 10px;
            padding-top: 10px;

            &>span:hover {
              cursor: pointer;
            }
          }
        }

      }
    }

    .logout {
      margin-top: 60px;
      padding: 0  10px;
      &>.el-button {
        width: 100%;
      }
    }

  }
  .ly-footer {
    position: fixed;
    left: $menu-width;right: 0;bottom: 0;
    height: $footer-height;
    background-color: white;
  }
  .ly-main {
    padding: calc($header-height + $main-padding-v) calc($main-padding-h) calc($footer-height + $main-padding-v) calc($menu-width + $main-padding-h);

    &>.ly-section {
      &+.ly-section {
        margin-top: 30px;
      }
      background-color: white;
      border-radius: 5px;
      box-shadow: 0 0 50px 0 lightgrey;
      min-height: 100px;
      padding:10px;
      text-align: left;
      &>.header {
        display: flex;align-items: center;justify-content: space-between;
        font-weight: bold;
      }
    }
  }

  $projectW : 240px;
  .projects-wrap {
    position: fixed;
    top: calc($header-height + $main-padding-v);
    bottom: calc($footer-height + $main-padding-v);
    left: calc($menu-width + $main-padding-h);
    width: $projectW;

    background-color: white;
    border-radius: 5px;
    //box-shadow: 0 0 50px 0 lightgrey;
    padding: 20px 0;

    &+ .ly-main {
      padding: calc($header-height + $main-padding-v) calc($main-padding-h) calc($footer-height + $main-padding-v) calc($menu-width + $projectW + 2* $main-padding-h);
    }
  }

}

</style>