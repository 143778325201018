

<template>
  <div class="ly-main">
    <div class="ly-section">

      <el-form   ref="form" label-position="top" label-width="50px" class="form" >
        <el-form-item label="图片">
          <OnePicture v-model="picture" :aid="aid" />

        </el-form-item>
        <el-form-item style="text-align: left">
          <el-button type="primary" @click="save">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>

</template>
<script>
import api from "@/utils/api";
import OnePicture from "@/views/subviews/OnePicture.vue";
export default {
  name: "PicturePage",
  props:["fid","aid"],
  components:{OnePicture},
  data(){
    return{
      picture:'',
    }
  },
  mounted() {
    this.loadData()
  },
  methods:{
    save(){
      api.do_picture_update(this.fid,this.picture).then(()=>{
        this.notice_success("修改成功")
      }).catch(err=>{this.notice_warning(err)})
    },
    loadData(){
      api.get_picture(this.fid).then(data=>{
        this.picture = data.picture
      }).catch(err=>{this.notice_warning(err)})
    },

  }
}
</script>
<style lang="scss" scoped>
$avatarW: 360px;
$avatarH: 200px;

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: $avatarW;
  height: $avatarH;
  line-height: $avatarH;
  text-align: center;
}
.avatar {
  width: $avatarW;
  height: $avatarH;
  display: block;
}

.form {
  display: inline-block;
  .tip {
    font-size: 12px;
    line-height: 18px;
    color: #888;
  }
}

</style>