import { render, staticRenderFns } from "./Picture.vue?vue&type=template&id=2b5a7a45&scoped=true"
import script from "./Picture.vue?vue&type=script&lang=js"
export * from "./Picture.vue?vue&type=script&lang=js"
import style0 from "./Picture.vue?vue&type=style&index=0&id=2b5a7a45&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b5a7a45",
  null
  
)

export default component.exports