

<template>

  <div class="main-wrap">
    <div class="score">{{score}}分</div>

    <div class="bar-wrap">
      <div class="value" :class="index" :style="percent" ></div>
    </div>

    <div class="person"  v-if="person">
      <div class="avatar-bg" :class="index"  >
        <img class="avatar" :src="person.avatar" v-if="person.avatar" alt="">
        <div class="avatar" v-else>{{person.name|last}}</div>
      </div>

      <div class="name">{{person.name}}</div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Bar",
  props:["value","idx","person","score"],
  data(){
    return {

    }
  },
  computed:{
    percent(){
      // console.error(666,this.value)
      if (this.value){
        return `height:${this.value*100}%`
      }
      return `height:0`
    },
    index(){
      return `idx-${this.idx}`
    }
  }
}
</script>


<style scoped lang="scss">

$WH:84px;


.idx-1 {
  background:linear-gradient(rgb(230,77,40), rgb(190,21,39));
}
.idx-2 {
  background:linear-gradient(rgb(252,123,0), rgb(174,60,18));
}
.idx-3 {
  background:linear-gradient(rgb(255,201,100), rgb(254,89,3));
}
.idx-4 {
  background:linear-gradient(rgb(135,248,249), rgb(72,233,153));
}
.idx-5 {
  background:linear-gradient(rgb(224,80,124), rgb(253,67,65));
}
.idx-6 {
  background:linear-gradient(rgb(139,178,254), rgb(124,255,237));
}
.idx-7 {
  background:linear-gradient(rgb(232,151,91), rgb(218,50,44));
}
.idx-8 {
  background:linear-gradient(rgb(168,95,233), rgb(79,56,201));
}
.idx-9 {
  background:linear-gradient(rgb(243,254,33), rgb(255,211,0));
}




.main-wrap {
  //background-color: yellow;
  display: flex;flex-direction: column;
  align-items: center;

  .score {
    font-size: 30px;font-weight: bolder;
    padding-bottom: 25px;
  }



  .bar-wrap {
    position: relative;
    //background-color: white;
    width: 50%;height: 300px;

    &>.value {
      border-radius: 25px 25px 0 0;
      position: absolute;
      left: 0;bottom: 0;right: 0;
      height: 0;
      transition: all 0.5s ease-in-out;


    }
  }




  .person {
    width: $WH;
    display: flex;flex-direction: column;
    position: relative;top: -10px;

    .avatar-bg {
      width: $WH;height: $WH;
      padding: 3px;box-sizing: border-box;
      border-radius: 50%;
      .avatar {
        width: 100%;height: 100%;
        border-radius: 50%;
        object-fit: cover;
        display: flex;align-items: center;justify-content: center;
        font-size: 16px;
        background-color: white;
        color: #555;
      }

      &.idx-1 {
        background: rgb(190,21,39);
      }
      &.idx-2 {
        background: rgb(174,60,18);
      }
      &.idx-3 {
        background: rgb(254,89,3);
      }
      &.idx-4 {
        background: rgb(72,233,153);
      }
      &.idx-5 {
        background: rgb(253,67,65);
      }
      &.idx-6 {
        background: rgb(124,255,237);
      }
      &.idx-7 {
        background: rgb(218,50,44);
      }
      &.idx-8 {
        background: rgb(79,56,201);
      }
      &.idx-9 {
        background: rgb(255,211,0);
      }

    }

    .name {
      text-align: center;
      font-size: 22px;
      margin-top: 5px;
    }

  }

}







</style>