<template>

  <div>
    <div class="header">
      <span>人员数据</span>
    </div>

    <div class="section-header">分组</div>
    <div>
      <el-tag
          :key="group.id"
          v-for="group in groups"
          closable
          :disable-transitions="false"
          @close="clickGroupDelete(group)">
        {{group.name}}
      </el-tag>

      <el-button class="button-new-tag" size="small" @click="groupAddDialogVisible=true">+ 增加分组</el-button>
      <el-dialog title="创建人员分组" :visible.sync="groupAddDialogVisible" left width="500px" :close-on-click-modal="false">
        <PersongroupAddForm :jointypes="jointypes" :aid="aid" @onSuccess="onGroupaddSuccess" />
      </el-dialog>


    </div>


    <div class="section-header">

      <span>人员</span>
      <el-button @click="addDialogVisible = true" >新增</el-button>
      <el-dialog title="新增人员" :visible.sync="addDialogVisible" left :width="addDialogWith" :close-on-click-modal="false">
        <PersonAddForm v-if="addDialogVisible" :aid="aid" :groups="groups"  @onViewCompact="onViewCompact" @onSuccess="onAddSuccess" />
      </el-dialog>
    </div>
    <div>

      <el-table
          border
          ref="filterTable"
          :data="pageData.items"
          @filter-change="onFilterChange"
          style="width: 100%">

        <el-table-column
            column-key="gids"
            label="分组"
            width="100"
            :filters="groupFilters"

            filter-placement="bottom-end">
<!--          <template v-slot="scope"  v-if="Object.keys(group_dict).length>0">-->
<!--            <el-tag disable-transitions>{{group_dict[scope.row.gid].name}}</el-tag>-->
<!--          </template>-->
          <template v-slot="scope" >
            <el-tag disable-transitions>{{scope.row.group.name}}</el-tag>
          </template>
        </el-table-column>

        <el-table-column
            prop="name"
            label="姓名"
            width="100">
        </el-table-column>
        <el-table-column
            prop="phone"
            label="电话"
            width="140">
        </el-table-column>


        <el-table-column
            label="加入类型"
            width="100">
          <template v-slot="scope">
            {{scope.row.group.join_type.name}}
          </template>
        </el-table-column>
        <el-table-column
            label="状态"
            width="160">
          <template v-slot="scope">
            <span>{{scope.row.ready_status_str}}</span>

            <el-button
                v-if="scope.row.ready_status===1 && scope.row.group.join_type.value ===1"
                size="mini"
                style="margin-left: 10px;"
                @click="clickCheckinCancel(scope.row.id)">重签</el-button>

          </template>
        </el-table-column>
        <el-table-column
            prop="ready_time_str"
            label="就绪时间">
        </el-table-column>
        <el-table-column
            width="220"
            label="操作">

          <template v-slot="scope">
            <el-button
                size="mini"
                @click="clickView(scope.row)">查看</el-button>
            <el-button
                size="mini"
                @click="clickUpdate(scope.row)">编辑</el-button>

            <el-button
                size="mini"
                @click="clickDelete(scope.row.id)">删除</el-button>

          </template>

        </el-table-column>

      </el-table>

      <el-pagination
          class="new-line"
          background
          layout="sizes, prev, pager, next"
          :page-sizes="[5,8,10,13,15,18,20]"
          :page-size="pageData.per_page"
          :total="pageData.total"
          :current-page="pageData.page"
          @current-change="clickPageChange"
          @size-change="clickPageSizeChange">
      </el-pagination>

      <el-dialog title="编辑人员" :visible.sync="updateDialogVisible" left :width="addDialogWith" :close-on-click-modal="false">
        <PersonUpdateForm v-if="updateDialogVisible" :person="currentPerson" :groups="groups"  @onViewCompact="onViewCompact" @onSuccess="onUpdateSuccess" />
      </el-dialog>

      <el-dialog title="预览人员" :visible.sync="viewDialogVisible" left :width="addDialogWith" :close-on-click-modal="false">
        <PersonViewForm v-if="viewDialogVisible" :person="currentPerson"  @onViewCompact="onViewCompact" />
      </el-dialog>
    </div>



  </div>

</template>

<script>
import PersonAddForm from "@/views/subviews/PersonAddForm.vue";
import PersonUpdateForm from "@/views/subviews/PersonUpdateForm.vue";
import PersonViewForm from "@/views/subviews/PersonViewForm.vue";
import PersongroupAddForm from "@/views/subviews/PersongroupAddForm.vue";

import api from "@/utils/api";

export default {
  name: "PersonManage",
  components: {PersongroupAddForm, PersonAddForm,PersonUpdateForm,PersonViewForm},
  props:["aid"],
  data() {
    return {
      gids:'',
      groups: [],
      jointypes:[],
      pageData:{items:[],page:1,per_page:5,total:0},
      groupAddDialogVisible:false,
      addDialogVisible:false,
      updateDialogVisible:false,
      viewDialogVisible:false,
      currentPerson:{},
      addDialogWith:'350px',
    };
  },
  mounted() {
    this.loadData()
  },
  computed:{
    groupFilters(){
      return this.groups.map(g=> {
        return {text:g.name,value:g.id}
      })
    }
  },
  methods:{

    loadData(){
      this.loadJointypeData()
      this.loadPersonGroupData()
      this.loadPersonData()
    },

    loadJointypeData(){
      api.get_dict__person_group_join_type().then(data=>{
        this.jointypes = data
      }).catch(err=>{this.notice_warning(err)})
    },
    loadPersonGroupData(){
      api.get_persongroup_list(this.aid).then(data=>{
        this.groups = data
      }).catch(err=>{this.notice_warning(err)})
    },
    loadPersonData(){
      api.get_person_list(this.aid,this.pageData.page,this.pageData.per_page,this.gids).then(data=>{
        this.pageData = data
      }).catch(err=>{this.notice_warning(err)})
    },
    clickPageSizeChange(per_page){
      this.pageData.per_page = per_page
      this.loadPersonData()
    },
    clickPageChange(page) {
      this.pageData.page = page
      this.loadPersonData()
    },
    onFilterChange(data) {
      if (data.gids.length > 0) {
        this.gids = data.gids.join(',')
      }else {
        this.gids = ''
      }
      this.loadPersonData()
    },
    onViewCompact(is){
      this.addDialogWith = is ? '400px' : '750px'
    },
    onGroupaddSuccess(){
      this.groupAddDialogVisible=false;
      this.loadPersonGroupData()
    },
    onAddSuccess(){
      this.addDialogVisible=false;
      this.pageData.page = 1
      this.loadPersonData()
    },
    clickGroupDelete(group) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.do_persongroup_delete(group.id).then(()=>{
          this.notice_success("删除成功")
          this.groups.splice(this.groups.indexOf(group), 1);
        }).catch(err=>{this.notice_warning(err)})
      }).catch(() => {
        this.notice_info("已取消删除")
      });

    },
    clickDelete(id){

      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.do_person_delete(id).then(()=>{
          this.notice_success("删除成功")
          this.loadPersonData()
        }).catch(err=>{this.notice_warning(err)})
      }).catch(() => {
        this.notice_info("已取消删除")
      });


    },
    clickCheckinCancel(id){

      this.$confirm('确定取消吗？此操作只针对签到时输错手机号使用', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.do_person_checkin_cancel(id).then(()=>{
          this.notice_success("取消成功")
          this.loadPersonData()
        }).catch(err=>{this.notice_warning(err)})
      }).catch(() => {
        this.notice_info("已取消操作")
      });


    },
    clickUpdate(person){
      this.currentPerson = person
      this.updateDialogVisible = true
    },
    onUpdateSuccess(){
      this.updateDialogVisible=false;
      this.loadPersonData()
    },
    clickView(person){
      this.currentPerson = person
      this.viewDialogVisible = true
    },
  }
}
</script>

<style scoped>

.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.section-header {
  font-size: 12px;font-weight: bold;
  margin-top: 20px;
  padding: 10px 0;
  display: flex;align-items: center;justify-content: space-between;
}

</style>