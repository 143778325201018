

<template>
<div class="all-round">
  <i class="ico bg"/>
<!--  <div class="left">逸会通</div>-->

  <div class="main-wrap" >

    <div class="login-wrap" v-if="now==='login'" :class="{active:show==='login'}">
      <div class="exchange" @click="clickShow('register')">
        <span>注册</span>
      </div>
      <div class="login-qr-wrap">
        <div class="login-qr">
          <div class="title" @click="loadLoginData()" >扫码登录</div>
          <div class="qrcode-wrap">
            <img class="qrcode" :src="login_data.qrcode" alt="" :class="{expire:login_expired}" >
            <div class="expired-wrap" v-if="login_expired">
              <div class="tip">二维码已失效</div>
              <div class="btn" @click="loadLoginData()">刷新二维码</div>
            </div>
          </div>

          <div class="desc">使用 <span class="main">微信</span> 扫码登录</div>
        </div>
      </div>
      <div class="login-main">
        <div>
          <el-image
              class="logo"
              :src="img"
              fit="fit"></el-image>
        </div>
        <div class="input-wrap">
          <div class="icon-wrap"><i class="icon el-icon-user"></i></div>
          <input type="text" placeholder="请输入账号" v-model="login_account" />
        </div>
        <div class="input-wrap">
          <div class="icon-wrap"><i class="icon el-icon-lock"></i></div>
          <input type="password" placeholder="请输入密码" v-model="login_password" @keydown.enter="clickLogin" />
        </div>

        <el-button class="login-btn" type="primary" @click="clickLogin" :disabled="!login_formEnabled" >登录</el-button>
        <div class="forget-wrap">
          <span @click="$router.push('/forget')" >找回密码</span>
        </div>
      </div>
    </div>
    <div class="login-wrap" v-if="now==='register'" :class="{active:show==='register'}">
      <div class="exchange" @click="clickShow('login')">
        <span>登录</span>
      </div>
      <div class="login-qr-wrap">
        <div class="login-qr">
          <div class="title">扫码注册</div>
          <div class="qrcode-wrap">
            <img class="qrcode" :src="register_data.qrcode" alt="" :class="{expire:register_expired}" >
            <div class="expired-wrap" v-if="register_expired">
              <div class="tip">二维码已失效</div>
              <div class="btn" @click="loadRegisterData()">刷新二维码</div>
            </div>
          </div>

          <div class="desc">使用 <span class="main">微信</span> 扫码注册</div>
        </div>
      </div>
      <div class="login-main">
        <div>
          <el-image
              class="logo"
              :src="img"
              fit="fit"></el-image>
        </div>
        <div class="input-wrap">
          <div class="icon-wrap"><i class="icon el-icon-user"></i></div>
          <input type="text" placeholder="请输入昵称" v-model="nickname" />
        </div>
        <div class="input-wrap">
          <div class="icon-wrap"><i class="icon el-icon-user"></i></div>
          <input type="text" placeholder="请输入公司名称" v-model="company_name" />
        </div>
        <div class="input-wrap">
          <div class="icon-wrap"><i class="icon el-icon-user"></i></div>
          <input type="text" placeholder="请输入账号" v-model="account" />
        </div>
        <div class="input-wrap">
          <div class="icon-wrap"><i class="icon el-icon-lock"></i></div>
          <input type="password" placeholder="请输入密码" v-model="password" @keydown.enter="clickRegister" />
        </div>

        <el-button class="login-btn" type="primary" @click="clickRegister()" :disabled="!register_formEnabled" >注册</el-button>
      </div>
    </div>
  </div>
  <div class="footer-wrap"  >
    <BeianFooter :dark="true" />
  </div>
</div>
</template>

<script>
import img from '@/assets/img/logo_tm.png'
import api from "@/utils/api";
import {base64_encode} from "@/utils/helper";
import BeianFooter from "@/views/subviews/BeianFooter.vue";
export default {
  name: "LoginView",
  components:{BeianFooter},
  data(){
    return {
      img:img,
      company_name:"",
      nickname:"",
      account:"",
      password:"",

      login_account:"",
      login_password:"",
      login_data:{qrcode:"",expire:200},
      register_data:{qrcode:"",expire:200},

      now:"",
      show:"",
      ih:undefined,
      data:undefined,
    }
  },
  computed:{

    login_formEnabled(){
      return this.login_account.trim().length > 0 && this.login_password.trim().length > 0
    },
    register_formEnabled(){
      return this.account.trim().length > 0 && this.password.trim().length > 0 && this.company_name.trim().length > 0 && this.nickname.trim().length > 0
    },
    login_expired(){
      return this.login_data.expire < 20
    },
    register_expired(){
      return this.register_data.expire < 20
    }

  },
  mounted() {
    this.clickShow('login')
  },
  destroyed() {
    if(this.data){
      this.unsbscribe(this.data)
    }
  },
  methods:{
    unsbscribe(data){
      const topic = `${data.type}/${data.session_id}`
      const callbackId = base64_encode(topic)
      api.qr_mqtt.unsubscribe(callbackId)
    },
    subscribe(data){
      if(this.data){
        this.unsbscribe(this.data)
      }
      const topic = `${data.type}/${data.session_id}`
      api.qr_mqtt.subscribe(topic,(data)=>{
        console.error("收到消息",data)
        api.login(data.token)
        this.unsbscribe(data)
        api.qr_mqtt.disconnect()
        this.$router.push('/')
      })
      this.data = data
    },
    clickShow(form){
      let wt = 0
      if(this.show.trim().length >0 ){
        this.show = ""
         wt = 600
      }
      setTimeout(()=>{
        if(this.ih){ clearInterval(this.ih);this.ih = undefined }
        this.now = form
        setTimeout(()=>{
          this.show = form
        },100)
        if (form === 'login'){
          this.loadLoginData()
        }else {
          this.loadRegisterData()
        }
      },wt)

    },
    loadLoginData(){
      api.get_login_qr().then(data=>{
        this.login_data = data
        this.subscribe(data)

        this.ih = setInterval(()=>{
          this.login_data.expire --
          if(this.login_expired){
            clearInterval(this.ih)
            this.ih = undefined
          }
        },1000)

      }).catch(err=>{this.notice_warning(err)})
    },
    loadRegisterData(){
      api.get_register_qr().then(data=>{
        this.register_data = data
        this.subscribe(data)

        this.ih = setInterval(()=>{
          this.register_data.expire --
          if(this.register_expired){
            clearInterval(this.ih)
            this.ih = undefined
          }
        },1000)
      }).catch(err=>{this.notice_warning(err)})
    },
    clickLogin(){
      // console.error("登录",this.account,this.password);
      this.login_loading = true
      api.do_login(this.login_account,this.login_password).then((data)=>{
        console.error(data)
        api.login(data.token)
        this.$router.push('/')

      }).catch( e =>{
        this.notice_warning(e)

      }).finally(()=>{
        this.login_loading = false
      })
    },
    clickRegister(){

      api.do_register(this.company_name,this.nickname,this.account,this.password).then((data)=>{
        console.error(data)
        api.login(data.token)
        this.$router.push('/')

      }).catch( e =>{
        this.notice_warning(e)
      }).finally(()=>{

      })
    }
  },
}
</script>

<style lang="scss" scoped>

i.bg {
  position: fixed;
  left: 0;top: 0;right: 0;bottom: 0;
  //filter: blur(2px);
  background-position: center;
  background-size: cover;
  background-image: url("~@/assets/img/loginbg.jpg");

}

.all-round {
  display: flex;
  &>.left {
    //background-color: yellow;
    //background-color: white;
    position: fixed;
    left: 0;top: 0;bottom: 0;right: 500px;
  }
  &>.main-wrap{
    //background-color: red;
    //background-color: white;
    position: fixed;
    top: 0;bottom: 0;right:0;
    //width:550px;
    display: flex;align-items: center;justify-content: center;


    .login-wrap {
      display: flex;
      background-color: rgba(255,255,255,0.8);
      padding: 30px;border-radius: 5px;
      margin-right: 80px;
      position: relative;

      transition: .5s all;
      left: 1000px;
      &.active { left: 0 }


      &>.exchange {
        width: 70px;height: 70px;
        position: absolute;
        right: 0;top: 0;
        background-image: url("~@/assets/img/login_exchange_bg.png");
        background-size: 100% 100%;
        display: flex;align-items: center;justify-content: flex-end;
        border-radius:0 5px 0 0;

        &:hover { cursor: pointer; }

        &>span {
          position: relative;
          left: -5px;top: -12px;
          color: white;font-size: 14px;
          text-shadow: 0 0 10px rgba(0,0,0,0.5);
        }
      }

      .login-qr-wrap {
        border-right:0.5px solid rgba(0, 0,0,0.3);
        padding-right: 30px;
        display: flex;align-items: center;

        .login-qr {
          .title { font-size: 22px}
          .qrcode-wrap {
            width: 200px;
            height: 200px;
            position: relative;
            margin: 20px 0;
            &>.qrcode {
              width: 100%;height: 100%;
              &.expire {
                filter: blur(3px);
              }
            }
            &>.expired-wrap {
              position: absolute;
              left: 0;top: 0;bottom: 0;right: 0;
              display: flex;flex-direction: column;align-items: center;justify-content: center;
              //padding-top: 40px;
              background-color: rgba(255,255,255,0.8);
              &>.tip {font-size: 17px;color: black}
              &>.btn {
                color: white;
                background-color: #25a8fb;
                padding: 5px 10px;
                margin-top: 15px;
                border-radius: 3px;
                &:hover { cursor: pointer; }
              }
            }
          }
          .desc { font-size: 15px;color: gray}
          .main {color: #2c3e50}
        }
      }

      .login-main{
        width: 300px;
        display: flex;flex-direction: column;
        padding-left: 30px;
        .logo {
          height: 100px;
          margin-bottom: 30px;
        }

        &>.input-wrap {
          &+.input-wrap{ margin-top: 20px}
          height: 50px;
          //background-color: #409EFF;
          border-bottom: 1px solid grey;
          position: relative;
          input {
            width: 100%;
            height: 100%;
            border: none;
            //background-color: rgba(255,0,0,0.2);
            padding-left: 50px;
            box-sizing: border-box;
            background: none;
          }

          .icon-wrap {
            position:absolute;
            display: flex;align-items: center;justify-content: center;
            width: 50px;height: 100%;
            .icon {
              font-size: 25px;
              color: gray;
            }
          }

        }

        &>.login-btn {
          margin-top: 40px;
        }
      }
    }



  }
}

.forget-wrap {
  margin-top: 10px;
  text-align: right;
  color: black;
  &>span {
    &:hover {
      cursor: pointer;
    }
  }
}


</style>