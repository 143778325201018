

<template>
  <div>
    <div v-if="title">{{title}}</div>
    <el-upload
        class="personadd-avatar-uploader"
        :action="`${VUE_APP_BASE_URL}/temp/upload`"
        :headers="tokenHeader"
        :show-file-list="false"
        :on-success="handleAvatarSuccess"
        :on-error="handleAvatarError"
        :before-upload="beforeAvatarUpload">

      <img v-if="value && value.length>0" :src="value" class="avatar" :class="[fit]" :style="{width: width,height: height}"  draggable="false">
      <i v-else class="el-icon-plus avatar-uploader-icon" :style="{width: width,height: height,lineHeight:height}" ></i>
    </el-upload>
    <div class="tip" v-if="tip && data"  >你的大屏设置为 <span>{{data.resolution.name}}</span>；请上传同尺寸的图片。目前支持，jpg、jpeg、png格式。</div>
    <div class="warning" v-if="warning.length>0">{{warning}}</div>
  </div>

</template>
<script>
import api from "@/utils/api";

export default {
  name: "OnePicturePage",
  // props:["value","aid","size"],
  props:{
    fit:{type:String,default:"fill"},
    title:{type:String,default:""},
    warning:{type:String,default:""},
    tip:{type:Boolean,default:true},
    value:{type:String,default:""},
    aid:{type:String,default:""},
    width:{type:String,default:'360px'},
    height:{type:String,default:'200px'},
  },
  data(){
    return {
      data:undefined
    }
  },
  computed:{
    tokenHeader(){
      return api.tokenHeader
    }
  },
  mounted() {
    if (this.tip){
      this.loadData()
    }

  },
  methods:{
    loadData(){
      api.get_screen_base(this.aid).then(data=>{
          this.data = data
      }).catch(err=>{this.notice_warning(err)})
    },
    handleAvatarSuccess(res) {
      this.$emit('input',res.url);
    },
    handleAvatarError(err){
      this.notice_error(JSON.parse(err.message).msg);
    },
    beforeAvatarUpload(file) {

      const isJPG = file.type === 'image/png' || file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 6;

      if (!isJPG) {
        this.$message.error('上传图片只能是 「png,jpg,jpeg」 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 6MB!');
      }
      return isJPG && isLt2M;
    },
  }
}
</script>
<style lang="scss" scoped>
//$avatarW: 360px;
//$avatarH: 200px;




.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  //width: $avatarW;
  //height: $avatarH;
  //line-height: $avatarH;
  text-align: center;


}
.avatar {
  //width: $avatarW;
  //height: $avatarH;
  display: block;

  &.fill {
    object-fit: fill;
  }
  &.cover {
    object-fit: cover;
  }


}

.form {
  display: inline-block;
  .tip {
    font-size: 12px;
    line-height: 18px;
    color: #888;
    span {
      color: #cc2126;
    }
  }


}

.warning {
  color: #cc2126;
}



</style>