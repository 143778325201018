

<template>

  <div class="ly-main">


    <InvoiceHeaderManager/>

    <ConsumptionManager/>

    <InvoiceManager/>

  </div>

</template>

<script>
import api from "@/utils/api";
import InvoiceHeaderManager from "@/views/Mine/subviews/InvoiceHeaderManager.vue";
import ConsumptionManager from "@/views/Mine/subviews/ConsumptionManager.vue";
import InvoiceManager from "@/views/Mine/subviews/InvoiceManager.vue";

export default {
  name: "ConsumptionPage",
  components: {InvoiceHeaderManager,ConsumptionManager,InvoiceManager},
  data() {
    return {

      pageData:{items:[],page:1,per_page:5,total:0},
      form:{},
    };
  },
  mounted() {
    this.loadData()
  },
  computed: {
  },
  methods: {

    loadData(){
      api.get_order_list(this.pageData.page,this.pageData.per_page).then(data=>{
        this.pageData = data
      }).catch(err=>{this.notice_warning(err)})
    },
    clickPageSizeChange(per_page){
      this.pageData.per_page = per_page
      this.loadPrizeData()
    },
    clickPageChange(page) {
      this.pageData.page = page
      this.loadPrizeData()
    },
    onSubmit(){

    }

  }
}
</script>


<style lang="scss" scoped>

.line-wrap {
  display: flex;justify-content: space-between;align-items: center;
}




</style>