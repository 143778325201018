

<template>
  <div>
    <div class="content-wrap">
      <router-view/>
    </div>

    <div class="bottom-wrap" :class="{hidden:bottom_hidden}" >
      <div class="actions-wrap">
        <div class="tip">流 程</div>
        <div class="flow-wrap">
          <div class="flow" v-for="(v,i) in flows" :key="i" >
            <div class="content" :class="{active:v.id === selected}" @click="clickFlow(v)" >
              <img :src="v.sketch.picture" />
              <div class="index">{{page*pageSize+i+1}}</div>
              <div class="title">{{v.name}}</div>
            </div>
            <div class="indicator-wrap">
              <i class="icon indicator" v-if="v.id === selected"></i>
            </div>
          </div>
        </div>
        <div class="page-wrap">
          <div class="btn" :class="{active:preActive}" @click="clickPre()"><i class="el-icon-arrow-left"/></div>
          <div class="btn" :class="{active:nextActive}" @click="clickNext()" ><i class="el-icon-arrow-right"/></div>
        </div>
      </div>
      <div class="tools">
<!--        <div class="item">-->
<!--          <i class="icon question"/>-->
<!--          <div>帮助</div>-->
<!--        </div>-->
        <div class="item">
          <i class="icon wifi"/>
          <div>网络</div>
        </div>
<!--        <div class="item">-->
<!--          <i class="icon controller"/>-->
<!--          <div>控台</div>-->
<!--        </div>-->
<!--        <div class="item">-->
<!--          <i class="icon voice"/>-->
<!--          <div>音效</div>-->
<!--        </div>-->
        <div class="item" @click="clickScreenFull()" >
          <i class="icon window" v-if="isFullscreen"/>
          <i class="icon screen" v-else/>
          <div>全屏</div>
        </div>
      </div>
    </div>

    <div class="hidden-wrap">
      <i class="icon" @click="bottom_hidden=!bottom_hidden" />
    </div>

  </div>

</template>

<script>
import api from "@/utils/api";
import screenfull from 'screenfull';
export default {
  name: "RunningMain",
  props:["aid","fid"],
  data(){
    return {
      data:[],
      page:0,
      selected:"",
      pageSize:5,
      bottom_hidden:false,
      route_dic:{picture:"RunningPicture",video:"RunningVideo",checkin_wall:"RunningCheckinWall",lottery:"RunningLottery",vote:"RunningVote"},
      isFullscreen:false,
    }
  },
  computed:{
    flows(){
      let ret = []
      let size = this.pageSize
      const start = this.page*size
      let end = this.data.length
      end = Math.min(end,start+size)
      for (let i = start; i < end; i++) {
        ret.push(this.data[i])
      }
      return ret
    },
    nextActive(){
      let size = this.pageSize
      return (this.page+1) * size < this.data.length
    },
    preActive(){
      let size = this.pageSize
      return (this.page-1) * size >=0
    }
  },
  watch:{
    $route(to){
      this.init()
    }
  },
  mounted() {

    if (screenfull.isEnabled) {
      screenfull.on('change', () => {
        this.isFullscreen = screenfull.isFullscreen;
      });
      this.isFullscreen = screenfull.isFullscreen;
    }
    this.loadData()
  },methods:{
    clickScreenFull(){
      if (screenfull.isEnabled) {
        screenfull.toggle()
      }else {
        this.notice_warning("你的浏览器不支持全屏")
      }
    },
    clickPre(){
      if (this.preActive){
        this.page--
      }
    },
    clickNext(){
      if (this.nextActive){
        this.page++
      }
    },
    loadData(){
      api.get_flow_list(this.aid).then(data=>{
        this.data = data
        this.init()
      }).catch(err=>{this.notice_warning(err)})

    },
    init(){
      let name = this.$route.name;
      // console.error(999,name)
      // this.type = ""
      if (name === 'RunningMain') {
        this.selected = ""
        if (this.flows.length>0 ){
          const aid = this.aid;
          const fid = this.flows[0].id;
          const name = this.route_dic[this.flows[0].sketch.project_key]
          this.$router.push({name,params:{aid,fid}})
        }
      }else {
        this.selected = this.fid
      }

    },
    clickFlow(f){
      if(f.id === this.fid){
        // alert("本页")
        return
      }
      let name = this.route_dic[f.sketch.project_key]
      let aid = this.aid
      let fid = f.id
      this.$router.push({name,params:{aid,fid}})
    }
  },
}
</script>

<style scoped lang="scss">
.content-wrap {
  position: fixed;
  left: 0;top: 0;right: 0;bottom: 0;
  background-color: #000;
}
.bottom-wrap {
  transition: all 0.5s;
  &.hidden {
    transform: translateY(70px);
  }

  color: white;
  position: fixed;
  bottom: 0;left: 0;right: 0;
  display: flex;justify-content: space-between;align-items: center;

  &>.actions-wrap {

    display: flex;align-items: center;
    &>div+div{margin-left: 20px;}
    &>.tip {
      writing-mode: vertical-lr;
      background:linear-gradient(to right top, rgba(0,0,0,0.2), rgba(255,255,255,0.3));
      padding: 10px 5px;
      font-size: 14px;
      border-radius: 0 5px 5px 0;
      position: relative;top: -7px;
    }
    &>.flow-wrap {
      display: flex;
      &>.flow {
        &+.flow{margin-left: 5px}


        &>.content {
          &:hover {cursor: pointer;}
          width: 90px;height: 50px;
          position: relative;
          &:hover {
            top:-8px;
          }
          border-radius: 4px;
          border: 1px solid white;
          &.active{
            //box-shadow:0 0 5px 3px #409EFF;
            border:1px solid rgba(88, 226, 255, .64);
            box-shadow: 0 0 6px rgba(88, 226, 255, .64);
          }
          &>img {
            //width: 100%;
            width: 90px;height: 50px;
            border-radius: 4px;
          }
          &>.index {
            position: absolute;
            left: 0;top: 0;
            background-color: rgba(0,0,0,0.5);
            padding: 4px 6px;min-width: 10px;
            font-size: 10px;line-height: 10px;
            border-radius: 2px;
          }
          &>.title {
            position: absolute;
            left: 0;right: 0;bottom: 0;
            background-color: rgba(0,0,0,0.4);
            font-size: 10px;line-height: 10px;
            padding: 3px 0;
          }
        }
        &>.indicator-wrap {
          height: 14px;
          display: flex;justify-content: center;align-items: flex-end;
          &>.indicator {
            width: 70px;height: 10px;
            background-image: url("~@/assets/img/indicator_bottom.png");
          }
        }
      }
    }
    &>.page-wrap {
      display: flex;
      position: relative;top: -7px;
      &>.btn {
        &+.btn { margin-left: 10px}
        &.active{
          &:hover{
            cursor: pointer;
          }
          &>i {
            &:hover{
              color: rgba(255,255,255,1);
            }
          }
        }
        padding: 8px 3px;
        background-color: rgba(255,255,255,0.1);
        border-radius: 4px;
        &>i {
          color: rgba(255,255,255,0.5);
          font-size: 20px;
          font-weight: bolder;

        }
      }
    }

  }
  &>.tools {
    display: flex;align-items: center;
    &>.item {
      position: relative;
      &:hover { top: -8px;cursor: pointer;}
      margin-right: 30px;
      font-size: 12px;
      &>i {

        width: 28px;height: 28px;

        &.question {
          background-image: url("~@/assets/img/ico_question.png");
        }
        &.wifi {
          background-image: url("~@/assets/img/ico_wifi_3.png");
        }
        &.controller {
          background-image: url("~@/assets/img/ico_controller.png");
        }
        &.voice {
          background-image: url("~@/assets/img/ico_voice_true.png");
        }
        &.screen {
          background-image: url("~@/assets/img/ico_screen_full.png");
        }
        &.window {
          background-image: url("~@/assets/img/ico_screen_window.png");
        }
      }
    }
  }

}
.hidden-wrap {
  position: fixed;
  bottom: 0;left: 0;right: 0;
  display: flex;justify-content: center;
  color: white;font-size: 30px;font-weight: bolder;

  &>.icon {
    width: 142px;height: 32px;
    background-image: url("~@/assets/img/btn_down.png");
    &:hover { cursor: pointer;}
  }
}

</style>