

<template>
<div class="all-round">
  <i class="ico bg"/>


  <div class="main-wrap" >

    <div class="login-wrap" >

      <div class="login-main">
        <div class="title">
         请完善信息完成注册
        </div>

        <div class="input-wrap">
          <div class="icon-wrap"><i class="icon el-icon-user"></i></div>
          <input type="text" placeholder="请输入公司名称" v-model="company_name" />
        </div>
        <div class="input-wrap">
          <div class="icon-wrap"><i class="icon el-icon-user"></i></div>
          <input type="text" placeholder="请输入账号" v-model="account" />
        </div>
        <div class="input-wrap">
          <div class="icon-wrap"><i class="icon el-icon-lock"></i></div>
          <input type="password" placeholder="请输入密码" v-model="password" @keydown.enter="clickSure" />
        </div>

        <el-button class="login-btn" type="primary" @click="clickSure" :disabled="!formEnabled" >确认完善</el-button>
      </div>
    </div>

  </div>
  <div class="footer-wrap"  >
    <BeianFooter :dark="true" />
  </div>
</div>
</template>

<script>
import img from '@/assets/img/logo_tm.png'
import api from "@/utils/api";
import BeianFooter from "@/views/subviews/BeianFooter.vue";
export default {
  name: "LoginView",
  components: {BeianFooter},
  data(){
    return {
      img:img,
      company_name:"",
      account:"",
      password:"",
    }
  },
  computed:{

    formEnabled(){
      return this.account.trim().length > 0 && this.password.trim().length > 0 && this.company_name.trim().length > 0 
    }

  },
  mounted() {

  },

  methods:{


    clickSure(){
      // console.error("登录",this.account,this.password);

      api.do_improve(this.company_name,this.account,this.password).then((data)=>{
        console.error(data)
        api.login(data.token)
        this.$router.push('/')

      }).catch( e =>{
        this.notice_warning(e)

      }).finally(()=>{

      })
    }


  },
}
</script>

<style lang="scss" scoped>

i.bg {
  position: fixed;
  left: 0;top: 0;right: 0;bottom: 0;
  //filter: blur(2px);
  background-position: center;
  background-size: cover;
  background-image: url("~@/assets/img/loginbg.jpg");

}

.all-round {
  display: flex;
  &>.left {
    //background-color: yellow;
    //background-color: white;
    position: fixed;
    left: 0;top: 0;bottom: 0;right: 500px;
  }
  &>.main-wrap{
    //background-color: red;
    //background-color: white;
    position: fixed;
    top: 0;bottom: 0;right:0;
    //width:550px;
    display: flex;align-items: center;justify-content: center;


    .login-wrap {
      display: flex;
      background-color: rgba(255,255,255,0.8);
      padding: 30px;border-radius: 5px;
      margin-right: 80px;
      position: relative;

      transition: .5s all;





      .login-qr-wrap {
        border-right:0.5px solid rgba(0, 0,0,0.3);
        padding-right: 30px;
        display: flex;align-items: center;

        .login-qr {
          .title { font-size: 22px}
          .qrcode-wrap {
            width: 200px;
            height: 200px;
            position: relative;
            margin: 20px 0;
            &>.qrcode {
              width: 100%;height: 100%;
              &.expire {
                filter: blur(3px);
              }
            }
            &>.expired-wrap {
              position: absolute;
              left: 0;top: 0;bottom: 0;right: 0;
              display: flex;flex-direction: column;align-items: center;justify-content: center;
              //padding-top: 40px;
              background-color: rgba(255,255,255,0.8);
              &>.tip {font-size: 17px;color: black}
              &>.btn {
                color: white;
                background-color: #25a8fb;
                padding: 5px 10px;
                margin-top: 15px;
                border-radius: 3px;
                &:hover { cursor: pointer; }
              }
            }
          }
          .desc { font-size: 15px;color: gray}
          .main {color: #2c3e50}
        }
      }

      .login-main{
        width: 475px;
        display: flex;flex-direction: column;
        .title {
          font-size: 20px;
          font-weight: bold;
          padding-bottom: 20px;
        }

        &>.input-wrap {
          &+.input-wrap{ margin-top: 20px}
          height: 50px;
          //background-color: #409EFF;
          border-bottom: 1px solid grey;
          position: relative;
          input {
            width: 100%;
            height: 100%;
            border: none;
            //background-color: rgba(255,0,0,0.2);
            padding-left: 50px;
            box-sizing: border-box;
            background: none;
          }

          .icon-wrap {
            position:absolute;
            display: flex;align-items: center;justify-content: center;
            width: 50px;height: 100%;
            .icon {
              font-size: 25px;
              color: gray;
            }
          }

        }

        &>.login-btn {
          margin-top: 40px;
        }
      }
    }



  }
}



</style>