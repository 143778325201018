

<template>
  <div v-if="!selected">
    <el-divider v-if="id" class="add" @click="clickSelect" ><i class="el-icon-circle-plus-outline"></i></el-divider>
    <div v-else  class="temp-item add" @click="clickSelect" >
      <div class="content">
        <div>
          <i class="el-icon-plus" />
          <div>添加环节</div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="temp-item" >
    <div class="content">
      右侧区域选择环节
    </div>
    <i  class="arrow el-icon-caret-right"></i>
  </div>
</template>

<script>
export default {
  name: "AddIndicator",
  props:['selected','id'],
  data(){
    return {
    }
  },
  methods:{
    clickSelect(){
      this.$emit("onSelect");
    }
  }
}
</script>

<style scoped lang="scss">

.add:hover {
  cursor: pointer;
}

.temp-item {
  display: flex;align-items: center;
  margin-top: 10px;
  &>.content {
    width: 180px;height: 100px;border-radius: 4px;
    background-color: #f8f9fc;
    border: 1px dashed #09f;
    color: #09f;
    display: flex;align-items: center;justify-content: center;
    font-size: 13px !important;
  }
  &>.arrow {
    margin-left: 3px;color: #409EFF;
  }
}

</style>
<script setup>
</script>