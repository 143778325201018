<template>
  <div>
    <el-form :model="form" ref="form" label-position="top" label-width="100px" class="demo-ruleForm">


      <el-form-item label="手机号" prop="name">
        <el-input v-model="form.phone" placeholder="请输入内容" ></el-input>
      </el-form-item>
      <el-form-item label="验证码" prop="name">
        <el-input v-model="form.code" placeholder="请输入内容" style="width: 180px"></el-input>
        <el-button type="primary" plain   style="margin-left: 20px" @click="dialogVisible=true" :disabled="seconds>0" >
          <span v-if="seconds===0">获取</span>
          <span v-else>{{seconds}} s</span>
        </el-button>
      </el-form-item>



      <el-form-item  style="text-align: center">
        <el-button type="primary" @click="clickSure()">绑定</el-button>
      </el-form-item>
    </el-form>

    <el-dialog title="图像验证" :visible.sync="dialogVisible" center width="330px" append-to-body :close-on-click-modal="false" >
      <CAPTCHAForm v-if="dialogVisible" @onSuccess="onCAPTCHASuceess"  />
    </el-dialog>
  </div>

</template>


<script>
import api from "@/utils/api";
import CAPTCHAForm from "@/views/Mine/subviews/CAPTCHAForm.vue";

export default {
  name: "PhoneBindForm",
  components: {CAPTCHAForm},
  data(){
    return {
      dialogVisible:false,
      form:{phone:"",code:""},
      seconds:0,
    }
  },
  methods:{
    onCAPTCHASuceess(id,code){
      // console.error(id,code)
      api.do_bind_phone_pre(id,code,this.form.phone).then((data) => {
        this.dialogVisible = false
        this.seconds = data.expire
        const fi = setInterval(()=>{
          this.seconds --
          if(this.seconds === 0){
            clearInterval(fi)
          }
        },1000)
      }).catch(err=>{this.notice_warning(err)})
    },
    clickSure(){
      api.do_bind_phone(this.form.phone,this.form.code).then(() => {
        this.$emit('onSuccess')
      }).catch(err=>{this.notice_warning(err)})
    }
  }
}
</script>

<style scoped lang="scss">

.line-wrap {
  display: flex;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
}
.qr_wrap {
  width: 200px;
  height: 200px;
  background-color: lightgray;
  margin-bottom: 40px;
}
.seconds {
  font-size: 16px;
  color: red;
}

</style>