import axios from 'axios'


export const KEY_TOKEN = 'KEY_TOKEN'
export function logout() {
    localStorage.removeItem(KEY_TOKEN)
    location.href="/login"
}


// console.error('process.env.VUE_APP_BASE_URL',process.env.VUE_APP_BASE_URL)

const http = axios.create({
    // baseURL:'http://localhost:81/v1',
    // baseURL:'http://localhost/v1',
    baseURL:process.env.VUE_APP_BASE_URL,
    timeout: 60000,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },

})

http.interceptors.request.use(config => {
    // console.log(config)

    let token = localStorage.getItem(KEY_TOKEN)
    if (token != undefined){
        // const headers_addition = {Authorization: 'Bearer ' + token,}
        // config.headers = {...config.headers,...headers_addition}
        config.headers.setAuthorization('Bearer ' + token)
        // console.error(config.headers)
    }
    return config
},error => Promise.reject(error))

http.interceptors.response.use(res => {

    // debugger;
    if (res.status != 200){//2xx反正不是200
        return Promise.reject(res.data.msg)
    }
    // console.error(res)
    const contentType = res.headers['content-type']
    const supporttContentTypes = ['application/octet-stream','image/jpeg','image/png','application/zip']
    const disposition = res.headers['content-disposition']
    if (disposition == undefined) {
        return Promise.resolve(res.data)
    }
    const dispositions = disposition.split(';')
    if (dispositions[0].trim() != 'attachment'){
        return Promise.resolve(res.data)
    }

    if( supporttContentTypes.indexOf(contentType) != -1){//暂时只支持二进制，后面在看看有excel需要单独支持不？还有图片这种可以不
        const blob = new Blob([res.data], {type: contentType});
        const downloadElement = document.createElement('a');
        const href = window.URL.createObjectURL(blob); //创建下载的链接
        downloadElement.href = href;
        let  filename = ''
        for(const disposition of dispositions){
            if (disposition.indexOf('filename')!=-1){
                filename = disposition.split('=')[1].trim()
                break
            }
        }
        downloadElement.download = filename; //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放掉blob对象
    }
    return Promise.resolve(res.data)
}, async  error => {
    const res = error.response
    if (!res){
        const msg = `<p><span style="color: blue">[${error.message}]</span><br/> <i>${error.config.baseURL}${error.config.url}</i></p>`
        return Promise.reject({html:true,content:msg})
    }
    // console.error(typeof(res.data.msg) == 'object')
    // console.error(res.data)
    // console.error(typeof(res.data),res.data instanceof Blob)


    if (res.data instanceof Blob){
        let reader = new FileReader(); // 创建读取文件对象
        res.data = await reader.readAsTextInPromise(res.data)
    }



    if(res.status == 400 && res.data.error_code == 1000 && typeof(res.data.msg) == 'object' ){
        let msg_obj = res.data.msg
        let errors = []
        for (let key in msg_obj) {
            for (let value of msg_obj[key]) {
                errors.push(`<p><span style="color: blue">[${key}]</span> <i>${value}</i></p>`)
            }
        }
        return Promise.reject({html:true,content:errors.join('')})
        // return Promise.reject({html:true,content:'<strong>这是 <i>HTML</i> 片段</strong>'})
    }
    if(res.status == 401){ // token过期
        logout()
        location.reload()
    }
    console.error(error)
    if(res.status == 0){
        // return Promise.reject('网络出错~')
        return Promise.reject(error)
    }
    // console.log()
    return  Promise.reject(res.data.msg)
})


export default http

