

<template>
  <div class="content">

    <div class="err" v-if="err">{{err}}</div>
    <template v-else>
      <Info v-if="vote"  :fid="fid" :vote="vote"/>
      <Vote v-if="vote_run" :run="vote_run" :fid="fid"/>
    </template>

  </div>
</template>
<script>
import Info from "./Info.vue";
import Vote from "./Vote.vue"
import PubSub from "pubsub-js";
import api from "@/utils/api";
export default {
  name: "BasicContent",
  components:{Info,Vote},
  props:["fid"],
  data(){
    return {
      vote:undefined,
      err:undefined,
      vote_run:undefined,
      tokens:[],
    }
  },
  watch:{
    fid(){
      this.init()
    }
  },
  mounted() {
    this.init()
    let token1 = PubSub.subscribe("vote_update",()=>{
      this.init()
    })
    this.tokens = [token1]
  },
  beforeDestroy() {
    for (const token of this.tokens) {
      PubSub.unsubscribe(token)
    }
  },
  methods:{
    init(){
      // console.error(123,this.fid)
      this.vote = undefined
      this.err = undefined
      this.vote_run = undefined
      this.loadData().catch(err=>{this.notice_warning(err)})
    },
    async loadData(){
      const [flow,turn] = await Promise.all([api.get_flow(this.fid),api.get_vote_turn(this.fid)])
      if(flow.system > turn.current-1){
        if(flow.system === 0){
          this.vote = await api.get_vote(this.fid)
          this.$emit('onSuccess',this.vote.picture)
        }else {
          this.err = `投票还未进行到第${flow.system+1}轮`
        }
        return
      }
      this.vote_run = await api.get_vote_run(this.fid)
      this.$emit('onSuccess',this.vote_run.picture)

    }
  }

}
</script>
<style scoped lang="scss">

.content {
  width: 1000px;
  background-color: rgba(0,0,0,0.4);


  .err {
    font-size: 30px;font-weight: bolder;
    line-height: 100vh;
  }

}


</style>