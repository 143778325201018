<template>

    <el-form :model="form"  ref="form" label-position="top" label-width="100px" class="demo-ruleForm">
      <el-form-item label="名称" prop="name">
        <el-input v-model="form.name" maxlength="10" show-word-limit></el-input>
      </el-form-item>
      <el-form-item >
        <div slot="label">
          <span>加入类型 </span>
          <el-tooltip class="item" effect="dark"  placement="right">
            <div slot="content">
              简要:<br/>
              后台录入：后台录入后即已就绪<br/>
              内定签到：后台录入后需扫描签到码完成就绪<br/>
              签到加入：不需要录入，直接扫描签到码即完成录入又完成就绪<br/><br/>
              用法:<br/>
              后台录入：无需签到环节，一般用于选手等无需签到环节的人员<br/>
              内定签到：即需要签到环节又有所限制(不是任何人都能签到，只有后台录入过的人才能签到)，一般用于内定评委签到；<br/>
              签到加入：需要签到环节且无限制(任何人都能签到加入)，一般用于现场随机观众</div>
            <i class="el-icon-question" style="color: orange"></i>
          </el-tooltip>
        </div>
        <el-radio-group v-model="form.joinType">
          <el-radio :label="v.id" v-for="(v,i) in jointypes" :key="i" >{{v.name}}</el-radio>
        </el-radio-group>


      </el-form-item>

      <el-form-item label="加入上限" v-if="form.joinType===type_checkin.id" >
        <el-input-number v-model="form.limit" :step="10"></el-input-number>
      </el-form-item>


      <el-form-item style="text-align: center">
        <el-button type="primary" @click="submitForm()">立即创建</el-button>
        <el-button @click="resetForm()">重置</el-button>
      </el-form-item>
    </el-form>

</template>



<script>

import api from "@/utils/api";

export default {
  name: "PersongroupAddForm",
  props:['jointypes','aid'],
  data(){
    return {
      form:{name:'',joinType:'',limit:50},
    }
  },
  computed:{
    type_checkin(){
      for (const type of this.jointypes) {
        if(type.key == "CHECKIN_JOIN"){
          return type
        }
      }
      return {}
    }
  },
  methods:{
    submitForm() {
      api.do_persongroup_add(this.aid,this.form.name,this.form.joinType,this.form.limit)
          .then(() => {
            this.$emit('onSuccess')
          }).catch(err=>{this.notice_warning(err)})
    },
    resetForm() {
      this.$refs['form'].resetFields();
      this.form.joinType = ''
      this.form.limit = 50
    },
  }
}
</script>



<style scoped>

</style>