export default {
    bind(el) {
        el.getElementsByClassName('el-dialog__header')[0].style.cursor = 'move';
        el.getElementsByClassName('el-dialog__header')[0].addEventListener('mousedown', function(e) {
            const elDialog = el.getElementsByClassName('el-dialog')[0];
            const initClientX = e.clientX;
            const initClientY = e.clientY;
            const initStyleLeft = elDialog.style.left ? elDialog.style.left.replace('px','') - 0 : 0;
            const initStyleTop = elDialog.style.top ? elDialog.style.top.replace('px','') - 0 : 0;

            document.onmousemove = function(e){
                const moveX = e.clientX - initClientX + initStyleLeft;
                const moveY = e.clientY - initClientY + initStyleTop;
                elDialog.style.left = moveX + "px";
                elDialog.style.top = moveY + "px";
            }

            document.onmouseup = function(e){
                document.onmousemove = null;
                document.onmouseup = null;
            };
            // 不加return false的话可能导致黏连，就是拖到一个地方时div粘在鼠标上不下来，相当于onmouseup失效
            return false;
        }, false)
    },
    update(el, binding, vnode, old) {
        // 实现每次打开 Dialog 都复位到中央
        setTimeout(() => {
            if (el.style.display === 'none') {
                el.getElementsByClassName('el-dialog')[0].style.top = '';
                el.getElementsByClassName('el-dialog')[0].style.left = '';
            }
        }, 333); // Dialog 的关闭动画用时 300 毫秒，因此这里需要延迟执行，而且要多延迟一点点
    },
}