

<template>

  <div class="ly-main">
    <div class="ly-section">

      <div >
        <h3>充值记录</h3>
        <h4>若你充值确认已付款，多次尝试「到账检查」后依然显示等到支付状态，请添加工作人员
          <el-popover
              placement="top-start"
              width="300"
              trigger="hover">
            <el-image
                style="width: 300px; height: 300px"
                :src="fkImg"
                fit="fill"></el-image>
            <span slot="reference">微信</span>
          </el-popover>

          告知情况。</h4>
        <div>

          <el-table
              border
              :data="pageData.items"
              style="width: 730px">

            <el-table-column
                label="金额"
                width="140">
              <template v-slot="scope">
                {{scope.row.amount | balanceShow}}
              </template>
            </el-table-column>
            <el-table-column
                prop="create_time_str"
                label="创建时间"
                width="180">
            </el-table-column>

            <el-table-column
                label="状态"
                >
              <template v-slot="scope">
                {{scope.row.state_str}}
              </template>
            </el-table-column>

            <el-table-column
                prop="name"
                label="完成时间"
                width="180">

              <template v-slot="scope">
                <span v-if="scope.row.over_time">{{scope.row.over_time}}</span>
                <span v-else>--</span>
              </template>

            </el-table-column>

            <el-table-column
                label="操作"
                width="130"
            >
              <template v-slot="scope">
                <el-button type="primary" size="mini" plain @click="clickCheck(scope.row.id)" >到账检查</el-button>
              </template>
            </el-table-column>


          </el-table>

          <el-pagination
              class="new-line"
              background
              layout="sizes, prev, pager, next"
              :page-sizes="[5,8,10,13,15,18,20]"
              :page-size="pageData.per_page"
              :total="pageData.total"
              :current-page="pageData.page"
              @current-change="clickPageChange"
              @size-change="clickPageSizeChange">
          </el-pagination>



        </div>
      </div>

    </div>
  </div>

</template>

<script>
import api from "@/utils/api";
import PubSub from "pubsub-js";

export default {
  name: "TransationsPage",
  data() {
    return {
      pageData:{items:[],page:1,per_page:5,total:0},
      fkImg:require('@/assets/img/kfwx.jpg'),
    };
  },
  mounted() {
    this.loadData()
  },
  computed: {
  },
  methods: {
    clickCheck(id){
      api.order_query(id).then(res=>{
        if (res.trade_state === 'SUCCESS'){
          this.notice_success("已收到充值信息。")
          PubSub.publish("balance_refresh")
          this.loadData()
        }
      }).catch(err=>{ this.notice_warning(err)})

    },
    loadData(){
      api.get_order_list(this.pageData.page,this.pageData.per_page).then(data=>{
        this.pageData = data
      }).catch(err=>{this.notice_warning(err)})
    },
    clickPageSizeChange(per_page){
      this.pageData.per_page = per_page
      this.loadPrizeData()
    },
    clickPageChange(page) {
      this.pageData.page = page
      this.loadPrizeData()
    },

  }
}
</script>


<style lang="scss" scoped>

.filter-wrap {
  display: flex;justify-content: space-between;
  &+.filter-wrap { margin-top: 20px}
  &>.el-form-item {
    margin: 0;
  }
}
.my-label {
  &>div {
    line-height: 16px !important;
  }
  &>.tip {
    font-size: 13px;
    line-height: 13px !important;
  }
}
.el-form {
  border: 1px solid rgb(235,238,245);
  margin-bottom: 20px;
  padding: 10px;
}
.line-wrap {
  display: flex;justify-content: space-between;
  &>span {
    margin: 0 5px;
  }
}

h4 {
  color: rgb(245,108,108);
  span {
    font-size: 1.2rem;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }
}





</style>