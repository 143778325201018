

<template>
  <el-form  ref="form" label-position="left" label-width="50px" class="form" >

    <div class="content" :style="{display:person.has_intro?'flex':'block'}" >
      <div>

        <el-form-item label="头像">
          <template>
            <el-image
                v-if="person.avatar"
                class="avatar"
                :src="person.avatar"
                fit="fit"></el-image>
            <div v-else class="avatar-none">
              <i class="el-icon-picture-outline"></i>
              <div>暂无图片</div>
            </div>
          </template>
        </el-form-item>





        <el-form-item label="分组" >
          <el-tag >{{person.group.name}}</el-tag>
        </el-form-item>

        <el-form-item label="姓名" prop="name">
          <el-input v-model="person.name" disabled></el-input>
        </el-form-item>


        <el-form-item label="电话" prop="phone">
          <el-input v-model="person.phone" disabled></el-input>
        </el-form-item>
        <el-form-item label="简介" >
          <el-switch
              disabled
              v-model="person.has_intro"
              active-color="#13ce66"
              inactive-color="#d7dae2">
          </el-switch>
        </el-form-item>
      </div>
      <div v-if="person.has_intro" class="divider"></div>
      <div v-if="person.has_intro"  style="flex: 1">
        <div style="display: flex;justify-content: space-between;align-items: center">
          <div style="padding: 10px 0">简介列表</div>
        </div>

        <div>
          <ul>
            <li v-for="(v,i) in person.intros" :key="i" >{{v}}</li>
          </ul>
        </div>
      </div>
    </div>

  </el-form>
</template>

<script>

export default {
  name: "PersonViewForm",
  props:["person"],
  data(){
    return {
    }
  },
  mounted() {
    this.viewCompact(!this.person.has_intro)
  },
  methods:{
    viewCompact(is){
      this.$emit('onViewCompact',is)
    }
  }
}
</script>

<style lang="scss" scoped>

.form>.content {

  .divider {
    background-color: lightgrey;
    width: 1px;
    margin: 40px 30px;
  }
}

$avatarWH: 150px;

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: $avatarWH;
  height: $avatarWH;
  line-height: $avatarWH;
  text-align: center;
}
.avatar {
  width: $avatarWH;
  height: $avatarWH;
  display: block;
}

.avatar-none {
  width: $avatarWH;
  height: $avatarWH;
  display: flex;flex-flow: column;
  align-items: center;justify-content: center;
  background-color: #f5f7fa;
  &>i {
    font-size: 30px;
  }
}

.delete {
  cursor: pointer;
  &:hover {
    color: red;
  }
}

</style>

<style>

.personadd-avatar-uploader  {

  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:hover {
      border-color: #409EFF;
    }
  }
}
</style>