

<template>
  <el-form :model="form" ref="form" label-position="top" label-width="50px" class="form" >


    <el-form-item label="名称" prop="name">
      <el-input v-model="form.name" maxlength="10" show-word-limit></el-input>
    </el-form-item>


    <el-form-item label="分值" prop="score" >
      <el-input-number v-model="form.score" :step="1"></el-input-number>
    </el-form-item>





    <el-form-item style="text-align: center">
      <el-button type="primary" @click="submitForm('form')">立即创建</el-button>
      <el-button @click="resetForm('form')">重置</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import api from "@/utils/api";
export default {
  name: "BallotAddForm",
  props:["fid"],
  data(){
    return {
      form:{name:'',score:0},
    }
  },
  methods:{
    submitForm() {
      api.do_vote_ballot_add(this.fid,this.form.name,this.form.score)
          .then(()=>{
            this.$emit('onSuccess')
            // this.resetForm(); 没必要了，
          })
          .catch(err=>{this.notice_warning(err)})
    },
    resetForm() {
      this.$refs['form'].resetFields();
    },

  }
}
</script>

<style lang="scss" scoped>

.form {
  display: block;
  &>.content{
    .divider {
      background-color: lightgrey;
      width: 1px;
      margin: 40px 30px;
    }
  }

}

$avatarWH: 120px;

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: $avatarWH;
  height: $avatarWH;
  line-height: $avatarWH;
  text-align: center;
}
.avatar {
  width: $avatarWH;
  height: $avatarWH;
  display: block;
}

</style>

<style>

.personadd-avatar-uploader  {

  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:hover {
      border-color: #409EFF;
    }
  }
}
</style>