

<template>
  <div class="ly-main">
    <div class="ly-section">

      <el-form :model="form"  ref="form" label-position="top" label-width="50px" class="form" >
<!--        <el-form-item label="名称" prop="name">-->
<!--          <el-input v-model="form.name" maxlength="11" show-word-limit></el-input>-->
<!--        </el-form-item>-->

        <el-form-item label="分辨率">
          <el-select v-model="form.resolution_id" placeholder="请选择" style="width:350px">
            <el-option
                v-for="item in resolutions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>


        <div style="display: flex;">
          <el-form-item label="Logo">
            <el-upload
                class="personadd-avatar-uploader"
                :action="`${VUE_APP_BASE_URL}/temp/upload`"
                :headers="tokenHeader"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :on-error="handleAvatarError"
                :before-upload="beforeAvatarUpload">
              <img v-if="form.logo" :src="form.logo" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div class="tip">200*200px（1:1）</div>
            <div class="tip">logo目前使用场景：签到墙</div>
          </el-form-item>
          <OnePicture v-model="form.mobile_picture" :aid="aid" :tip="false" title="手机端主页背景" style="margin-left: 80px" fit="cover" width="92px" height="200px" warning="请使用深色背景" />
        </div>




        <el-form-item label="大屏操作地址" >
          <div class="item-line">
            <div class="label">大屏地址:</div>
            <el-input v-model="form.url" maxlength="30" disabled ></el-input>
            <el-button size="mini" @click="clickCopyUrl()">复制</el-button>
            <el-button size="mini" @click="clickOpen()">打开</el-button>
          </div>
<!--          <div class="item-line">-->
<!--            <div class="label">登录码:</div>-->
<!--            <el-input v-model="form.code" maxlength="30" disabled ></el-input>-->
<!--            <el-button size="mini" @click="clickCopyCode()" >复制</el-button>-->
<!--          </div>-->
        </el-form-item>

        <el-form-item style="text-align: left">
          <el-button type="primary" @click="save">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>

</template>
<script>
import api from "@/utils/api";
import OnePicture from "@/views/subviews/OnePicture.vue";
export default {
  name: "BasePage",
  components: {OnePicture},
  props:["aid"],
  data(){
    return{
      imageUrl:'',
      form:{resolution_id:"",logo:"",url:`${this.VUE_APP_HOME_URL}/running/`,code:'415703',mobile_picture:""},
      resolutions:[],
      data:undefined,
    }
  },
  computed:{
    tokenHeader(){
      return api.tokenHeader
    }
  },
  mounted() {
    this.form.url = `${this.VUE_APP_HOME_URL}/running/${this.aid}`

    this.loadResolutions()
    this.loadData()
  },
  methods:{

    loadResolutions(){
      api.get_dict__screen_resolution().then(data=>{
        this.resolutions = data
      }).catch(err=>{
        this.notice_warning(err)
      })
    },
    loadData(){
      api.get_screen_base(this.aid).then(data=>{
        this.data = data
        this.form.resolution_id = this.data.resolution.id
        this.form.logo = this.data.logo
        this.form.mobile_picture = this.data.mobile_picture
      }).catch(err=>{
        this.notice_warning(err)
      })
    },
    save(){

      api.do_screen_base(this.aid,this.form.logo,this.form.resolution_id,this.form.mobile_picture).then(()=>{
        this.notice_success("修改成功")
        this.loadData()
      }).catch(err=>{this.notice_warning(err)})


    },
    handleAvatarSuccess(res) {
      this.form.logo = res.url
    },
    handleAvatarError(err){
      this.notice_error(JSON.parse(err.message).msg);
    },
    beforeAvatarUpload(file) {
      // console.error(6666,file.type)
      const isJPG = file.type === 'image/png' || file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 6;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 「png,jpg,jpeg」 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 6MB!');
      }
      return isJPG && isLt2M;
    },
    clickCopyUrl(){
      this.$copyText(this.form.url).then(() => {
        this.notice_success("拷贝成功")
      }, () => {
        this.notice_error("拷贝失败")
      });
    },
    clickOpen(){
      window.open(this.form.url)
    },
    clickCopyCode(){
      this.$copyText(this.form.code).then(() => {
        this.notice_success("拷贝成功")
      }, () => {
        this.notice_error("拷贝失败")
      });
    }
  }
}
</script>
<style lang="scss" scoped>
$avatarW: 100px;
$avatarH: 100px;

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: $avatarW;
  height: $avatarH;
  line-height: $avatarH;
  text-align: center;
}
.avatar {
  width: $avatarW;
  height: $avatarH;
  display: block;
}

.form {
  display: inline-block;
  .tip {
    font-size: 12px;
    line-height: 18px;
    color: #888;
  }

  .item-line {
    display: flex;
    &+.item-line {
      margin-top: 10px;
    }
    .label {
      width: 60px;
      font-size: 12px;
      color: #666;
    }
    &>*+*{
      margin-left: 10px;
    }
    .el-input {
      width: 300px;
    }
  }
}

</style>