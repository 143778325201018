

<template>
  <div class="ly-main">


    <div class="ly-section">
      <div class="header">
        <span>基本信息设置</span>
      </div>

      <el-form :model="form"  ref="form" label-position="top" label-width="100px" class="form">
        <el-form-item label="活动名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>

        <el-form-item label="活动起止时间" prop="times" >
          <el-date-picker
              v-model="form.times"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>

        </el-form-item>

        <el-form-item label="预估人数" prop="person_limit">
          <el-select v-model="form.person_limit_id" placeholder="请选择预估人数" >
            <el-option :label="item.name" :value="item.id" v-for="(item,i) in person_limits" :key="i" ></el-option>
          </el-select>
          <span v-if="cost_delta&& cost_delta>0" class="cost">升级所需：￥{{cost_delta|balanceShow}}</span>
        </el-form-item>

        <el-form-item label="活动地点" >
          <el-input v-model="form.place"></el-input>
        </el-form-item>

        <el-form-item label="承办方" >
          <el-input v-model="form.organizer"></el-input>
        </el-form-item>

        <el-form-item label="参与方" >
          <el-input v-model="form.participant"></el-input>
        </el-form-item>

        <el-form-item style="text-align: center">
          <el-button type="primary" @click="submitForm()">保存</el-button>
        </el-form-item>
      </el-form>


    </div>
  </div>

</template>
<script>
import api from "@/utils/api";
import PubSub from "pubsub-js";

export default {
  name: "BasePage",
  props:['aid'],
  data(){

    return {
      name:'',
      dialogFormVisible:false,
      formLabelWidth: '120px',
      person_limits:[],
      data:undefined,
      form:{
        name:'',
        times:[],
        person_limit_id:'',
        place:'',
        organizer:'',
        participant:'',
      },

    }
  },
  mounted() {
    this.loadData().catch(err=>{this.notice_warning(err)})
  },
  computed:{
    cost_delta(){
      if (this.data === undefined || this.cost === undefined){
        return 0
      }
      return this.cost - this.data.person_limit.cost
    },
    cost(){
      if(this.person_limits.length===0 || this.form.person_limit_id.length ===0){
        return undefined
      }

      for (const person_limit of this.person_limits) {
        if (this.form.person_limit_id===person_limit.id){
          return person_limit.cost
        }
      }
      return  undefined
    }
  },
  methods: {
    async loadData(){

      // console.error("11111111")
      let [person_limits,activity] = await Promise.all([api.get_dict__activity_person_limit(),api.get_activity(this.aid)])
      // console.error("6666666666666",person_limits,activity)
      this.person_limits = person_limits
      this.setFormData(activity)

      // console.error("5555555555")
      // console.error(this.form.person_limit,this.person_limits)

    },
    // loadData(){
    //
    //   api.get_activity(this.aid)
    //       .then(data=>{
    //         this.activity = data
    //       })
    //       .catch(err=>{this.notice_warning(err)})
    // },
    submitForm(){
      if(this.cost_delta>0){
        const costShow = this.util.balanceShow(this.cost_delta)
        this.$confirm(`此操作将从您的余额扣除<span style="color:red">￥${costShow}</span>，活动有效时间为<span style="color:red">20天</span>， 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString:true,
          type: 'warning'
        }).then(() => {
          this.doSubmitForm()
        })
      }else {
        this.doSubmitForm()
      }
    },
    doSubmitForm() {
      // alert('submit!');
      // console.error(this.form.person_limit_id,this.form.person_limit.id,this.form.person_limit_id===this.form.person_limit.id)
      let id = this.aid
      let name = this.form.name
      console.error(this.form.times,this.form.times[0])
      let start_date = Math.floor(this.form.times[0].getTime()/1000)
      let end_date = Math.floor(this.form.times[1].getTime()/1000)
      let person_limit = this.form.person_limit_id
      let place = this.form.place
      let organizer = this.form.organizer
      let participant = this.form.participant

      api.do_activity_update(id,name,start_date,end_date,person_limit,place,organizer,participant)
          .then((data)=>{
            this.setFormData(data);
            this.notice_success("更新成功")
            PubSub.publish("activity_updated")
            PubSub.publish("balance_refresh")
          })
          .catch(err=>{this.notice_warning(err)})
    },
    setFormData(data){
      this.data = data
      this.form.name = data.name;
      this.form.times = [new Date(data.start_date*1000), new Date(data.end_date*1000)]
      this.form.person_limit_id = data.person_limit.id
      this.form.place = data.place
      this.form.organizer = data.organizer
      this.form.participant = data.participant

    },

  }
}
</script>
<style scoped>
.cost {
  margin-left: 20px;
  font-size: 18px;
  color: red;
}
 .form {
   display: inline-block;
 }

</style>